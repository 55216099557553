<div id="SettlementConfirmation" class="glb_boxedPadding glb_formBGColor text-white glb_Font col-12" style="height:100%; width:100%">

  <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow justify-content-end">
    <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
      <button id="SettlementConfirmation_Close" tabindex="30"
        class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
        (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)" [disabled]="IsGenerateInProgress">
        <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
      </button>
    </div>
  </div>
  <div class="glb_Font header-padding glb_customFlexRow">
    <div class="glb_customFlexRow col-12 glb_paddingZeroOffset">
      <div *ngIf="!IsSettlementSuccessful" class="col-8 glb_paddingZeroOffset">
        <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
          Settlement Confirmation
        </div>
      </div>
      <div *ngIf="IsSettlementSuccessful" class="col-8 glb_paddingZeroOffset glb_customFlexRow glb_paddingBottomLG">
        <div
          class="glb_TitleLG glb_TitleLGMobile glb_customFlexRow text-start glb_breakLongWords glb_paddingZeroOffset align-items-center glb_successText glb_roundedBorderSM">
          Settlement Successful!
          <span class="glb_standardButton glb_positionRelative glb_paddingLeft">
            <fa-icon [icon]="['fal', 'hand-holding-dollar']" class="successSettlementCheck fa-fw glb_fixedSizeIcons"></fa-icon>
          </span>
        </div>
      </div>

      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-8">
        <div class="col-4">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_Font glb_customFlexRow">Account ID:</div>
          </div>
        </div>
        <div class="col-4">
          <div class="glb_paddingBottomSM">
            <div id="SettlementConfirmationAccountID" class="text-white glb_Font glb_customFlexRow col3">{{PendingSettlement.AccountID}}</div>
          </div>
        </div>
      </div>

      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-8">
        <div class="col-4">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_Font glb_customFlexRow">LenderReference ID:</div>
          </div>
        </div>
        <div class="col-4">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_Font glb_customFlexRow col3">{{PendingSettlement.LenderReference}}</div>
          </div>
        </div>
      </div>

      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-8">
        <div class="col-4">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_Font glb_customFlexRow">Principal Borrower Name:</div>
          </div>
        </div>
        <div class="col-4">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_Font glb_customFlexRow col3">{{PendingSettlement.PrincipalBorrower}}</div>
          </div>
        </div>
      </div>

      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-8">
        <div class="col-4">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_Font glb_customFlexRow">Approved Amount:</div>
          </div>
        </div>
        <div class="col-4">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_Font glb_customFlexRow col3">{{PendingSettlement.ApprovedLimit_FMT}}</div>
          </div>
        </div>
      </div>

      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
        <div class="glb_paddingBottomSM">
          <div class="text-white glb_inputFieldLabel glb_customFlexRow">Settlement Date</div>

          <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-left">
            <div class="glb_paddingZeroOffset col-6">
              <input id="SettlementDateID" [matDatepicker]="settlementDatePicker" tabindex="1" [disabled]="IsSettlementSuccessful"
                [ngClass]="{ 'glb_inactiveInputBox': IsSettlementSuccessful }" class="glb_formInputBox glb_skinLightOutline text-black glb_Font"
                type="text" (dateChange)="SettlementDate_Validate()" placeholder="Settlement Date" [(ngModel)]="SettlementDate">
            </div>
            <div class="glb_paddingZeroOffset col-2 text-white">
              <mat-datepicker-toggle class="mat-datepicker-dark-custom" matSuffix [for]="settlementDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #settlementDatePicker [disabled]="IsSettlementSuccessful"></mat-datepicker>
            </div>
          </div>
        </div>
      </div>

      <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
        <div class="glb_paddingBottomSM">
          <div class="text-white glb_inputFieldLabel glb_customFlexRow">Settlement Amount</div>

          <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-left">
            <div class="glb_paddingZeroOffset col-6">
              <input id="SettlementAmountID" tabindex="2" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text"
                [disabled]="IsSettlementSuccessful" [ngClass]="{ 'glb_inactiveInputBox': IsSettlementSuccessful }" placeholder="Settlement Amount"
                (focus)="Input_Focused(true)" (blur)="Input_Focused(false)"
                (keyup)="InputNumeric_Validate($event, 'currency', PendingSettlement.LoanApprovalLimit)"
                (keydown)="InputNumeric_Validate($event, 'currency',PendingSettlement.LoanApprovalLimit)" [(ngModel)]="SettlementAmountDisplay">
            </div>
          </div>
        </div>
      </div>



      <br>
      <br>
      <br>
      <br>
      <br>

      <!-- Settle button -->
      <div *ngIf="!IsSettlementSuccessful" class="text-white glb_customFlexRow row-cols-1 justify-content-left col-12">
        <div>
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
              <button id="GenerateStatement" tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="AccountID_Settle()">
                <span class="glb_Font glb_smallerText">Settle Account</span></button>
            </div>
            <div *ngIf="IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Settle Account</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="IsSettlementSuccessful" class="col-8 glb_paddingZeroOffset">
        <div class="glb_TitleMD glb_TitleMDMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
          Settlement Successful!!!
        </div>
      </div> -->
      <div *ngIf="IsSettlementSuccessful" class="text-white glb_customFlexRow justify-content-between glb_paddingBottomLG col-12">
        <!--Navigate to the loan-->
        <div>
          <div class="glb_customFlexRow justify-content-center align-items-center">
            <div *ngIf="!IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
              <button id="VisitLoanIndexID" tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="LoanIndex_Visit()">
                <span class="glb_Font glb_smallerText">Visit Account: {{PendingSettlement.AccountID}}</span></button>
            </div>
          </div>
        </div>

        <div>
          <div class="glb_customFlexRow justify-content-center align-items-center">
            <div *ngIf="!IsGenerateInProgress" class="glb_paddingBottomSM d-grid">
              <button id="ReturnPendingSettlementID" tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
                <span class="glb_Font glb_smallerText">Return</span></button>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>

</div>