import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { StaticDataControllerMethods } from '@app/Global/EnumManager';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap } from 'rxjs/operators';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { LenderConfiguration } from '@app/Components/User/LenderConfigurations/LenderConfiguration/LenderConfiguration';
import { TemplateID } from '@app/Global/Models/ClientModels';

@Component({
  selector: 'LenderConfigurations',
  templateUrl: './LenderConfigurations.html',
  styleUrls: ['./LenderConfigurations.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class LenderConfigurations implements OnInit, OnDestroy {

  //Constructor
  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog) {
  }

  //Create lender config section. used for collapsing the section after the a new entry has been created
  @ViewChild('LenderConfig_CreateSection') LenderConfig_CreateSection: ElementRef;

  //Create lender config form, used in combination with above for collapsing the section after the a new entry has been created
  @ViewChild('LenderConfig_CreateForm') LenderConfig_CreateForm: ElementRef;

  //Create lender config form, this is the first and only input. Allows us to focus on it when its parent is clicked
  @ViewChild('LenderConfig_FocusCreateLenderName') LenderConfig_FocusCreateLenderName;

  //The list of lender configs from the server
  public lenderConfigList = [];

  //Entity types needed for the control data
  public entityTypes = "";

  //Entity types needed for the entity property filter, this one won't have the All option
  public entityTypesForFilter = "";

  //Claim names types needed for the control data
  public claimNames = "";

  //Client view model. contains all the entity propertes (or column names)
  public clientViewModel = "";

  //This is used to track the last AutoComplete Request that was sent to the server, and checked when processing the response, to ensure that we are processing only the latest one sent
  public lastAutoCompleteRequestGUID: string;

  //The initial page load spinner
  public showSpinner = false;

  //Track state of the create lender config request, so that we can show the spinner, if necessary.
  public isCreatingLenderConfig = false;

  //The actual create lender config data, this needs to default like the control data, or we get null errors on the first run of autocomplete
  public createLenderConfiguration_Lender = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

  //Used by paginator for the lender config on the this page
  public paging = {
    maxSize: 10,
    previousLabel: "",
    nextLabel: ""
  }

  public currentPage: { currentPage: number } = { currentPage: 0 };

  //The currently chosen lender
  public chosenLenderConfig;

  //Used for the client lender config filter/search box
  public filterSearchValue = "";
  public filterSearchValueKeyUp = new Subject<any>();

  //Subscription for the search filter
  public SearchFilterSubscription: Subscription;

  //Store the autocomplete data for the client to be inserted for the new lender config. This is needed to bind a default to the element on the form, otherwise you get null errors. This is Client lookup specific, hence the Client prefix in the property names
  public clientInsert_AutoComplete = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
  };

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];

  //Angular startup method
  ngOnInit() {

    //Grab all lender config on init, so that the template can loop through and show them.
    this.LenderConfig_GetAll();

    //Fill entity types, claim names, client view model, etc from the client store
    this.entityTypes = this.clientDataStore.EntityTypes;
    this.entityTypesForFilter = this.clientDataStore.EntityTypes.filter(x => x.FriendlyName !== 'All');
    this.claimNames = this.clientDataStore.ClaimNames;
    this.clientViewModel = this.clientDataStore.ClientViewModelData;

    //This creates a subscription to apply the search filter as per the search box.
    this.SearchFilterSubscription = this.filterSearchValueKeyUp.pipe(
      map((event) => (<HTMLInputElement>event.target).value),
      debounceTime(200),
      distinctUntilChanged(),
      mergeMap(search => of(search).pipe(delay(100)))
    ).subscribe(value => {

      //Lets show the spinner when we start filtering
      if (!this.globalFunctions.isEmpty(value)) {
        //TODO
        //this.Entity_ToggleSpinner(this.EntityName, 0);
      }
      else {
        //Call it directly with a blank string
        this.Lender_SearchFilter("");
      }

      //Small delay so that the spinner has a chance to show.
      //This is useful for the user experience, as they get a visual indication that the filtering has completed.
      this.globalFunctions.delay(300).then(() => {
        //Run after a small delay.
        if (!this.globalFunctions.isEmpty(value)) {
          this.Lender_SearchFilter(value);
        }
      });
    });
  }

  ngOnDestroy() {
    this.SearchFilterSubscription.unsubscribe();
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  public TemplateID_Toggle(identifierID: string): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers)
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //On init method, this gets the Lender Config data from the server for all lenders
  public LenderConfig_GetAll() {
    this.showSpinner = true;
    const apiRequest = {};
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.GetLenderConfigurations], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.showSpinner = false;
          return;
        }
        else {
          //Deserialize
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Store it in the client side array
          this.lenderConfigList = response.ClientLenderParentConfigs;

          //Loop through and unescape and relevant columns
          this.lenderConfigList.forEach(e => {
            e.Name = this.globalFunctions.HTMLUnescape(e.LenderName);
          });

          this.showSpinner = false;
        }
      });
  }

  //Wrapper of isempty to allow local html template to call it. this is needed to stop the template from calling it when it is empty (before data is populated from server), or we get null errors.
  public LenderConfig_IsListEmpty() {
    if (this.globalFunctions.isEmpty(this.lenderConfigList)) {
      return false;
    }
    return true;
  }

  //Create a new lender config on the server
  public LenderConfig_Create() {
    //Validate it first. make sure a lender is chosen
    if (this.globalFunctions.isEmpty(this.createLenderConfiguration_Lender)) {
      this.notifyService.Error_Show("Please Enter a valid Lender", "Lender Missing");
      return;
    }

    //Validate it first. make sure a lender is chosen
    if (this.globalFunctions.isEmpty(this.createLenderConfiguration_Lender.ControlGUID)) {
      this.notifyService.Error_Show("Please Enter a valid Lender", "Lender Missing");
      return;
    }

    this.isCreatingLenderConfig = true;

    //Client side validation passed. now let's construct the request and send it to the server
    const createLenderConfigRequest = { LenderGUID: this.createLenderConfiguration_Lender.ControlGUID };
    const apiRequest = createLenderConfigRequest;

    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.CreateLenderConfiguration], apiRequest)
      .subscribe(apiResponse => {

        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isCreatingLenderConfig = false;
          return;
        }
        else {

          //Get the response
          const createLenderConfigResponse = JSON.parse(JSON.stringify(apiResponse));

          //Get the first entry in the returned LenderConfigList array
          const newRecord = createLenderConfigResponse.LenderConfigList[0];

          //Unescape
          newRecord.LenderName = this.globalFunctions.HTMLUnescape(newRecord.LenderName);
          newRecord.DefaultAssigneeName = this.globalFunctions.HTMLUnescape(newRecord.DefaultAssigneeName);

          //Unshift this into the client side array (at the start)
          this.lenderConfigList.unshift(newRecord);

          //Clear the UI data
          this.createLenderConfiguration_Lender = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

          //A notification would be nice
          this.notifyService.Success_Show("Lender Config Inserted", "Success");
          this.isCreatingLenderConfig = false;

          //This collapses the input text box form
          this.TemplateID_Toggle("LenderConfig_CreateSection");

          //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
          this.Paginator_OnPageChange(0, this.currentPage, false);

          //Clear search role
          this.Lender_ClearSearchFilter();
        }
      });

  }

  //Set cursor in the lender input text box
  public LenderConfig_CreateSection_Expand() {
    this.globalFunctions.delay(1).then(() => { this.LenderConfig_FocusCreateLenderName.nativeElement.focus(); });
  }

  //Used by paginator to handle page updates
  public Paginator_OnPageChange(page: number, currentPage, doScrollToBottom = true) {
    currentPage.currentPage = page;

    //Dont scroll to top, bad UI experience. scroll to bottom instead
    if (doScrollToBottom) {
      this.globalFunctions.delay(50).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });
    }
  }

  //This applies a filter based on the search term to the input entity.
  public Lender_SearchFilter(searchTerm: string) {
    //Unselected any data units (they might get hidden by the filter)
    //this.DataUnit_RemoveSelected();

    //Try to catch null or undefined, replace with blank string
    if (this.globalFunctions.isEmpty(searchTerm)) {
      //Force it to an empty value (if null or undefined)
      searchTerm = "";
    }

    //console.log("running search filter for: ", searchTerm);
    //Make sure search term is not null, and greater than 2 characters. also uppercase it
    searchTerm = searchTerm.toUpperCase();
    //console.log("EntitySearchFilter firing for: ", EntityName, " - ", searchTerm);

    if (searchTerm != null && searchTerm.length > 2) {

      //Loop through each row in the userList
      for (const key in this.lenderConfigList) {
        const value = this.lenderConfigList[key];

        //We want to loop through valid properties here.
        //console.log("user: ", value);
        if (value['LenderName'].toUpperCase().includes(searchTerm) == true) {
          value.isHidden = false;
        }
        else {
          value.isHidden = true;
        }

      }

      //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
      this.Paginator_OnPageChange(0, this.currentPage);
    }
    else if (searchTerm == "") {
      //Remove all the hide filters
      for (const key in this.lenderConfigList) {
        const value = this.lenderConfigList[key];

        if (!this.globalFunctions.isEmpty(value)) {
          value.isHidden = false;
        }
      }
    }

    //Turn the spinner off after we have finished with filtering. maybe not needed as this is client side, and should be pretty fast
    //this.EntityDict[EntityName].Spinner = 1;

  }

  //Clears data from the filter search box
  public Lender_ClearSearchFilter(): void {
    this.filterSearchValue = "";

    //Call it directly with a blank string to reset the search filter
    this.Lender_SearchFilter("");
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue). This is currently specific to Client type Autocomplete. Will look to make it more generic, where possible (TODO).
  public AutoComplete_Client_ApplyFilter(autocompleteRecord, requestType: string, controlValue: string, fieldType: string, seed = false) {
    //console.log('AutoComplete_Client_ApplyFilter.controlValue', controlValue);

    //Check if we are seeding. only when the control value is blank! update the last search value if we are so that we trigger at least once. also only trigger if the Client_AutoCompleteLastSearchedValue is NOT equal to {seed}. This prevents us from seeding multiple times when there is no need (e.g. someone clicking on the field many times or pressing backspace repeatedly when there are no characters left)
    if (this.globalFunctions.isEmpty(controlValue) && seed && autocompleteRecord.Client_AutoCompleteLastSearchedValue !== "{seed}") {
      //Set the last value to something different so that we allow the seed request to proceed
      autocompleteRecord.Client_AutoCompleteLastSearchedValue = "{preseed}";
      controlValue = "{seed}";
    }

    if (!this.globalFunctions.isEmpty(controlValue) && !this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteLastSearchedValue)) {
      if ((autocompleteRecord.Client_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {

        //Request new data and update the current value so we don't request it again.
        autocompleteRecord.Client_AutoCompleteLastSearchedValue = controlValue;

        //We want this to have a slight delay on key press. so that we don't trigger a server request too quickly as keys are being pressed.
        this.globalFunctions.delay(350).then(() => {

          //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
          this.lastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();
          //Make a copy of it for sending to the update request
          const copyOfGUID = JSON.parse(JSON.stringify(this.lastAutoCompleteRequestGUID));

          //Request a new set of values from the server
          this.AutoComplete_RequestData(requestType, autocompleteRecord.Client_AutoCompleteControlTypeName, controlValue, autocompleteRecord.Client_AutoCompleteControlData, fieldType, copyOfGUID);
        });
      }
    }
    //Don't place an else here, or seeding will always ask server for new data.
    //The only minor issue is that you need to press or click twice when backspacing to a blank string from the last character. not sure why it behaves this way. If you don't, it won't trigger the seed

    //Check if the control data is non null
    if (!this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteControlData)) {

      //Just return the array, even though its not synchronous. the update will come later and update it.
      return autocompleteRecord.Client_AutoCompleteControlData;
    }
  }

  //For Material to access global functions, we need to use a get and arrow syntax to bring context along.
  public get AutoComplete_GetPrettyName() {
    return (value) => this.globalFunctions.AutoComplete_GetPrettyName(value);
  }

  //Saves the autocomplete value locally on a click, for later use. provide a local class property to bind to, via html
  public AutoComplete_SaveSelectedControlRecord(value, localBind) {

    //We are mutating the passed in object by reference. Disabling the eslint warning for unused variables on this one
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    localBind = this.globalFunctions.AutoComplete_SaveSelectedControlRecord(value, localBind);
  }

  //Launch modal of chosen lender config
  public LenderConfig_ViewSpecificLender(chosenLenderConfig) {

    this.clientDataStore.SetShowFullscreenLoading(true);

    const apiRequest = { GUID: chosenLenderConfig.GUID };
    this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, StaticDataControllerMethods[StaticDataControllerMethods.GetLenderConfiguration], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Store it client side
          this.chosenLenderConfig = response.ClientLenderConfig;

          //HTML Unescaping
          this.globalFunctions.LenderConfig_Unescape(this.chosenLenderConfig);

          this.clientDataStore.SetShowFullscreenLoading(false);

          //Get the component of this modal, and set a property on it. looks like it is stored in statementModalRef.componentInstance
          const lenderConfigurationModalRef = this.globalFunctions.FeatureModal_Launch(LenderConfiguration, this.globalFunctions.GetFeatureModalConfig('90%', false, true), this.dialog, "Lender Config", 0, true, false);
          lenderConfigurationModalRef.DialogRef.componentInstance.parentLenderConfigs = this;
          lenderConfigurationModalRef.DialogRef.componentInstance.LenderRoles = response.LenderRoles;
          lenderConfigurationModalRef.DialogRef.componentInstance.Page_Init();
        }
      });
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray, fieldType: string, autoCompleteRequestGUID: string): void {

    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = this.globalFunctions.AutoComplete_PrepareAPIRequest(requestType, controlType, requestValue, fieldType);

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, apiRequest.AutoCompleteEndpoint, apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {

            //This helps with flow, so we only resolve the last request.
            if (autoCompleteRequestGUID === this.lastAutoCompleteRequestGUID) {

              //console.log('processing response from server');
              this.AutoComplete_ProcessResponse(apiResponse, controlArray);
            }
          }
        });
    }
  }

  //Processes the autocomplete data returned by the server and pushes it into the supplied array
  private AutoComplete_ProcessResponse(apiResponse, controlArray): void {
    this.globalFunctions.AutoComplete_ProcessResponse(apiResponse, controlArray);
  }
}

