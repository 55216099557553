<header class="container-fluid rootPaddingMobile rootPaddingRegular">
	<SearchBar #SearchBar></SearchBar>
</header>

<main class="container-fluid rootPaddingMobile rootPaddingRegular">
	<router-outlet (activate)="ChildComponent_OnLoad($event)"></router-outlet>
</main>

<!-- we dont have a footer....yet -->
<!-- <footer class="app-footer">
	<app-footer></app-footer>
</footer> -->

<!-- this is the fullscreen loading section, that also blocks! -->
<div class="fullscreenLoading d-flex justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-center
	 align-items-center col-12 glb_customFlexRow" *ngIf="showFullscreenLoading">
	<div class="glb_Font text-white col-xs-8 col-sm-6 col-md-4 col-lg-2 glb_customFlexRow justify-content-center fullscreenLoadingSpinnerStyle">
		<div class="glb_customFlexRow justify-content-center col-12">
			<fa-icon class="fastSpin fa-2x fullscreenLoadingSpinnerColor" [icon]="['fal', 'compact-disc']" matTooltip="Please wait">
			</fa-icon>
		</div>
		<div class="glb_customFlexRow justify-content-center col-12">
			Loading...please wait
		</div>
	</div>
</div>

<!-- Floating div for minimized dialogs -->
<div *ngIf="globalFunctions.ShowMinimizedDialogMenu && !showFullscreenLoading"
	class="container-fluid rootPaddingMobile rootPaddingRegular floatingMinimizedMenu">
	<button (click)="overlayPanel_Dialogs.toggle($event)" matTooltip="Click to show the minimized screens"
		class="glb_roundedBorderSM floatingMinimizedMenuButton">
		<fa-icon [icon]="['fal', 'layer-group']" class="fa-fw glb_rightPaddingNarrow glb_iconColorLight">
		</fa-icon>Screens
	</button>

	<!-- This is the overlay panel for the minimized dialogs -->
	<p-overlayPanel #overlayPanel_Dialogs id="LBL_OPMinimizedDialogs" styleClass="glb_primeng_overlayPanel_lg glb_primeng_overlayPanel_default">
		<div class="glb_customFlexRow">
			<div class="col-12 glb_customFlexRow minimizedMenuContent">
				<div *ngFor="let dialog of globalFunctions.MinimizedDialogs_Get()" class="col-12 glb_boxedPaddingXS glb_customFlexRow">
					<div class="col-10 glb_customFlexRow">
						<button (click)="globalFunctions.MinimizedWindowsSwitch_Toggle(dialog)"
							class="glb_standardButton glb_skinLightOutline glb_standardButtonTight glb_BGColorSkin text-white glb_paddingRightXS justify-content-start col-10 glb_textAlignStart">{{dialog.Name}}
						</button>
						<button id="BTN_CloseDialog"
							class="glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline glb_PaddingVerticalZeroOffset justify-content-end col-2 glb_closeIconLight"
							type="button" (click)="globalFunctions.FeatureModal_Close(dialog.GUID)">
							<fa-icon [icon]="['fal', 'times']" class="fa-md"></fa-icon>
						</button>
					</div>
					<div class="col-2">
						<fa-icon *ngIf="dialog.IsVisible" [icon]="['fal', 'chevrons-right']"
							class="fa-fw glb_rightPaddingNarrow glb_iconColorDark col-2">
						</fa-icon>
					</div>
				</div>
			</div>
		</div>
	</p-overlayPanel>
</div>