import { Component, Input, ViewChild } from '@angular/core';
import { CsvDataService, GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { SLAReporting } from '../SLAReporting';
import { UsersControllerMethods } from '@app/Global/EnumManager';
import { SLAReportingDrillthrough } from '@app/Components/SLAReporting/SLAReportingDrillthrough/SLAReportingDrillthrough';
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'SLAReportingSummary',
  templateUrl: './SLAReportingSummary.html',
  styleUrls: ['./SLAReportingSummary.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class SLAReportingSummary {

  //Constructor
  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private csvDataService: CsvDataService = null) {
  }

  @Input() SLAReporting: SLAReporting;
  @Input() AggregateData;
  @Input() TabName: string;
  @Input() SLAColors;
  @Input() OverrideDueDateFlag;
  @Input() TaskTypeLengthLimit: number;
  @Input() IsActiveNavTab: boolean;

  //The html chart canvas
  @ViewChild('ChartID') ChartJS;

  public ShowSpinner = false;
  public AggregateDataExists = false;
  public ShowNavTab = false;
  private AggregateDataColumnCount;
  private TotalRowData;
  private SLADrillthroughData;
  private SLADrillthroughDataCSV;

  //Chart Data variables  
  private PieChartLabels = [];
  private ChartLabels = [];
  private PieChartTotals;
  private ChartType;
  private ChartData;
  private PlugIns;
  private ChartOptions;
  private IsStacked;
  private PieChartLabelColor = [];
  private ChartJSConstructed;

  //I want to store the sort direction for each header. let's use a client side array to help us remember them
  public SortingKeysArray: any[] = [];

  //Pie Chart Data set up
  public PieChartData = {
    labels: this.PieChartLabels,
    datasets: [
      {
        label: [],
        backgroundColor: [],
        borderColor: '#1E88E5',
        data: []
      }
    ]
  }

  //Limit the text display on the aggregate view
  public TextDisplay_Limit(input, itemIndex: number) {

    //First item is TaskType
    if (itemIndex === 0) {
      return this.globalFunctions.LimitTextSize(input, this.TaskTypeLengthLimit);
    }
    return input;
  }

  //Get Tooltip for the display
  public ToolTipText_Get(input, itemIndex: number): string {

    //Only TaskType is truncated
    if (itemIndex === 0) {
      return input;
    }
    return "";
  }

  //Initialise and refresh component variables
  public Display_Refresh(): void {

    //We are resetting the default state of the variables
    this.AggregateDataExists = false;
    this.ShowNavTab = false;

    //Ignore checks if no data
    if (this.AggregateData != null) {

      //Check if the inputs are being modified
      if (!this.SLAReporting.AreInputsActive) {

        const aggregateDataKeys = Object.keys(this.AggregateData);
        //Check if the data exists
        if (aggregateDataKeys.length > 0) {
          this.AggregateDataExists = true;

          //Lets get the total row
          this.TotalRowData = this.AggregateData["{Total}"];

          //Count the columns for display
          const totalRowDataKeys = Object.keys(this.TotalRowData);
          this.AggregateDataColumnCount = totalRowDataKeys.length;

          //Loop through the aggregate data and replace empty task type
          aggregateDataKeys.forEach(key => {
            const taskKeyData = this.AggregateData[key];
            if (this.globalFunctions.isEmpty(taskKeyData["Task Type"])) {
              taskKeyData["Task Type"] = this.globalFunctions.getServerNullString();
            }
          });

          //Initialise the ChartLabels and PieChartTotals
          this.ChartLabels = [];
          this.PieChartLabels = [];
          this.PieChartTotals = [];
          this.PieChartLabelColor = [];

          //Get the columns from the total row for the pie chart
          this.ChartLabels = totalRowDataKeys;

          //Remove the columns not required for pie chart
          const TaskTypeIndex = this.ChartLabels.findIndex(x => x == "Task Type");
          if (TaskTypeIndex != -1) {
            this.ChartLabels.splice(TaskTypeIndex, 1);
          }

          const TotalIndex = this.ChartLabels.findIndex(x => x == "Total");
          if (TotalIndex != -1) {
            this.ChartLabels.splice(TotalIndex, 1);
          }

          //Set the data (total and label color) for pie chart
          this.ChartLabels.forEach(label => {
            const columnTotal = Number(this.TotalRowData[label]);

            //Only push the data for pie chart dataset if the value is greater than 0
            if (columnTotal > 0) {
              this.PieChartLabels.push(label);
              this.PieChartTotals.push(Number(this.TotalRowData[label]));
              this.PieChartLabelColor.push(this.SLAColors.get(label));
            }
          });
        }

        //Check the current nav tab to display
        if (this.SLAReporting.CurrentNavItem === this.TabName) {
          this.ShowNavTab = true;
        }

        //Bit of delay prior to rendering the chart
        this.globalFunctions.delay(50).then(() => {
          this.Charts_Display();
        });
      }
    }

    //Check if the nav tab needs to be displayed
    this.CurrentNavItem_Check();
  }

  //Check the current nav item after the click on nav bar items
  public CurrentNavItem_Check(): void {
    //Check the current nav tab to display
    if (this.SLAReporting.CurrentNavItem === this.TabName) {
      this.ShowNavTab = true;
    }
  }

  //This preserves the order of key values coming in from server json dictionaries, used in the pipe on the html template. actually no. it just gets the value of the object, instead of the key (because its a dictionary)
  public Order_Keep = (a,) => {
    return a;
  }

  //Align the columns for the aggregate table based on how much data is coming in.
  public CssClass_Get(): string {

    //Set the class based on the AggregateDataColumnCount done on the Parent
    let classText = 'glb_customFlexRow col-12 ';

    //Set row cols based on the number of columns
    classText += 'row-cols-' + this.AggregateDataColumnCount;

    return classText;
  }

  //Align the columns for the aggregate table based on how much data is coming in.
  public CssClassSubItem_Get(index: number): string {

    let classText = '';
    if (index > 0) {

      //When zoomed in, the texts were aligned left. Force the text to right by adding text-end
      classText = ' justify-content-end text-end'
    }
    return classText;
  }

  //Get CSS to add/hide the hyperlinks styles
  public CssHyperlink_Get(subItemDetail, subItemIndex): string {
    let cssClass = "nonHyperlinkID";

    if (subItemIndex > 0 && subItemDetail.value > 0) {
      cssClass = "hyperlinkID glb_hoverHand";
    }

    //If the TaskType is empty, add a empty style
    if (subItemIndex == 0 && subItemDetail.value === this.globalFunctions.getServerNullString()) {
      return cssClass + " taskType-emptyStyle";
    }
    return cssClass;
  }

  //Method when a grid item is clicked
  public Grid_Click(item, subItem, subItemIndex): void {

    //Launch a drillthrough report
    this.Drillthrough_Launch(item.key, subItem.key, subItem, subItemIndex);
  }

  //When a data point is clicked, will call the server for drill through data.
  public PieChart_Click(event): void {
    const clickedElement = this.ChartJSConstructed.getElementAtEvent(event)[0];

    //If we didn't find an element, don't continue
    if (this.globalFunctions.isEmpty(clickedElement)) {
      return;
    }

    //Get the SLA category based on the click in the pie chart
    const chartLabel = this.PieChartLabels[[clickedElement._datasetIndex, clickedElement._index][1]];

    //Call drillthrough method to get the details
    this.Drillthrough_Launch("{Total}", chartLabel, null, 1);
  }

  //Downloads the aggregate view data into a csv file
  public Tasks_DownloadCSV(): void {

    const csvArray = [];

    //This is a dictionary, get the keys
    const aggregateDataKeys = Object.keys(this.AggregateData);

    //Loop through the aggregate data keys
    aggregateDataKeys.forEach(element => {

      //Lookup the values from the original dictionary
      const elementData = this.AggregateData[element];

      //Push into the array to be used for download
      csvArray.push(elementData);
    });

    //Call the service to produce and download the csv file
    this.csvDataService.exportToCsv("SLAAggregate", csvArray);
  }

  //Method to call the api to get the drillthrough data
  private Drillthrough_Launch(taskTypeGUID: string, slaCategory: string, gridItem, gridItemIndex: number): void {

    //Will ignore this check for Pie Chart
    if (!this.globalFunctions.isEmpty(gridItem)) {
      //Validation: Return if user clicks the aggregate value with 0 or on the task type. No action required.
      if (gridItem.value === '0' || gridItemIndex === 0) {
        return;
      }
    }

    //Set full screen loader
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Initialize empty array on each click
    this.SLADrillthroughData = new Array<string>();

    //Construct API Request
    const apiRequest = { LenderGUIDs: this.SLAReporting.ChosenLenderGUIDs, AssigneeGUIDs: this.SLAReporting.AssigneeClientGUIDs, Source: this.SLAReporting.Source, FromDate: this.SLAReporting.DTPFromDate.ISODate, ToDate: this.SLAReporting.DTPToDate.ISODate, IsOverrideDueDateFlag: this.OverrideDueDateFlag, TaskTypeGUID: taskTypeGUID, StatusCategory: slaCategory, IsActive: this.IsActiveNavTab };

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetSLAReportingDrillthrough], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          this.SLADrillthroughData = response;

          //Separate list for CSV download later
          this.SLADrillthroughDataCSV = JSON.parse(JSON.stringify(this.SLADrillthroughData))

          //I want to get all headers, and fill the sortingKeysArray with it.
          let headerRowIndex = 0;

          //Loop through the results and Unescape the TaskNote elements, if they exist.
          this.SLADrillthroughData.forEach(element => {

            if (headerRowIndex == 0) {
              //Increment so that we only do this once
              headerRowIndex = headerRowIndex + 1;

              const headerKeys = Object.keys(element);

              //Check if the data exists
              if (headerKeys.length > 0) {

                //And fill the headers array for this
                headerKeys.forEach(element => {

                  //When filling the header array, use the primary key of the section. as we may sort multiple sections at the same time!
                  this.SortingKeysArray.push({ HeaderName: element, HeaderSortDirection: 1 })
                });

              }
            }

            if (!this.globalFunctions.isEmpty(element.TaskNote)) {
              element.TaskNote = this.globalFunctions.HTMLUnescape(element.TaskNote);
            }

            //Convert date to DD MMM YYYY HH:mm tt
            element.DueDate_FMT = this.globalFunctions.getCustomDateFormat(element.DueDate, "longdate", "custom", "YYYY-MM-DD HH:mm:ss");
            element.OverrideDueDate_FMT = this.globalFunctions.getCustomDateFormat(element.OverrideDueDate, "longdate", "custom", "YYYY-MM-DD HH:mm:ss");
            element.CompletedDate_FMT = this.globalFunctions.getCustomDateFormat(element.CompletedDate, "longdate", "custom", "YYYY-MM-DD HH:mm:ss");
          });

          //Add SLACalcDate SLADrillthroughDataCSV for exporting into CSV
          this.SLADrillthroughDataCSV.forEach(element => {
            element.SLACalcDate = this.SLAReporting.SLACalcDateDisplay;
          });

          //We need to keep a ref to this modal, so that we can close it later
          const slaReportingDrillThroughModal = this.globalFunctions.FeatureModal_Launch(SLAReportingDrillthrough, this.globalFunctions.GetFeatureModalConfig("98%"), this.dialog, "SLA Drillthrough", 0, true, false);

          //Pass a copy of the data required to the child component
          slaReportingDrillThroughModal.DialogRef.componentInstance.SLADrillthroughData = this.SLADrillthroughData;
          slaReportingDrillThroughModal.DialogRef.componentInstance.SLACategory = slaCategory;
          slaReportingDrillThroughModal.DialogRef.componentInstance.SLADrillthroughDataCSV = this.SLADrillthroughDataCSV;
          slaReportingDrillThroughModal.DialogRef.componentInstance.TabName = this.TabName;
          slaReportingDrillThroughModal.DialogRef.componentInstance.SortingKeysArray = this.SortingKeysArray;
        }

        //Turn the spinners off
        this.clientDataStore.SetShowFullscreenLoading(false);
      });
  }

  //Construct a chart
  private Charts_Display(): void {

    //Construct chart using data from the parent dashboard array
    this.Chart_Create();

    //Build and display the chartjs locally
    this.ChartJS_Build();
  }

  //Generic method that creates a chart based on passed in options. can add more params if we like too, e.g. the options object - font styles, color, chart type (pie vs bar) etc.
  private Chart_Create(): void {

    //Set the chart datasets
    this.ChartType = "pie";
    this.PieChartData.datasets[0].data = this.PieChartTotals;
    this.PieChartData.datasets[0].label = this.PieChartLabels;
    this.PieChartData.datasets[0].backgroundColor = this.PieChartLabelColor;

    this.ChartData = {

      //Inject data for the x axis      
      labels: this.PieChartLabels,

      //Add all the datasets that we need
      datasets: this.PieChartData.datasets
    };

    this.PlugIns = [
      ChartDataLabels,
      {
        beforeInit: function (chart) {
          chart.legend.afterFit = function () {
            this.height = this.height + 20;
          }
        }
      }];

    this.ChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      legend: {
        position: 'top',
        align: 'end',
        //This makes the icon change to a pointer when the legend is hovered on by the mouse pointer
        onHover: function (e) {
          e.target.style.cursor = 'pointer';
        },
        labels: {
          usePointStyle: true,
          fontColor: '#8E8E93',
        }
      },
      tooltips: {
        callbacks: {
          label: (item, data) => {
            //Tried injecting html to rendering (e.g. a bold tag) but it didnt work.
            return data.datasets[0].label[item.index] + ': ' + data.datasets[0].data[item.index];
          },
        }
      },
      //This makes the icon change to a pointer when the chart content is hovered on by the mouse pointer
      hover: {
        onHover: function (e) {
          const point = this.getElementAtEvent(e);
          //Supressing ESLint for this line, I can't figure out how to make it work.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (point.length) {
            e.target.style.cursor = 'pointer';
          }
          else {
            e.target.style.cursor = 'default';
          }
        }
      },
      scales: {
        yAxes: [{
          stacked: this.IsStacked,
          padding: 10,
          display: false,
          scaleLabel: {
            show: true,
            labelString: 'Value'
          },
          gridLines: {
            color: '#D1D0D0',
            zeroLineColor: '#fff',
          },
          ticks: {
            fontColor: "#8E8E93",
            beginAtZero: true,
            padding: 2,
            display: false,
          }
        }],
        xAxes: [{
          stacked: this.IsStacked,
          padding: 10,
          display: false
          , gridLines: {
            display: false,
          }
          , ticks: {
            fontColor: "#8E8E93",
          }
        }]
      },
      plugins: {
        datalabels: {
          color: 'white',
          font: {},
          anchor: 'center',
          display: 'auto',
          align: 'center',
          formatter: (value) => {
            return value;
          }
        }
      }
    }

    //Can't build the chart here as the angular view is still not ready. Rely on ngAfterViewInit instead for first initialization
  }

  //Build Charts using native Chartjs
  private ChartJS_Build(): void {

    //If it doesn't exist, the chart might be empty. Don't try to construct it
    if (this.globalFunctions.isEmpty(this.ChartJS)) {
      //console.log('BuildChartJS empty!');
      return;
    }

    if (!this.globalFunctions.isEmpty(this.ChartJSConstructed)) {
      this.ChartJSConstructed.destroy();
    }

    //Make a chartjs version
    const chartItem = this.ChartJS.nativeElement;

    this.ChartJSConstructed = new Chart(chartItem, {
      type: this.ChartType
      , data: this.ChartData
      , options: this.ChartOptions
      //Might need to give this plugins as well
      , plugins: this.PlugIns
    });
  }

}