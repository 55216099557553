import { Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, delay, distinctUntilChanged, map, mergeMap, of, Subject, Subscription } from 'rxjs';
import { SettlementConfirmation } from '@app/Components/Settlements/SettlementConfirmation/SettlementConfirmation';

@Component({
  selector: 'Settlements',
  templateUrl: './Settlements.html',
  styleUrls: ['./Settlements.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class Settlements implements OnInit, OnDestroy {

  //Constructor
  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    public dialog: MatDialog) {
  }

  //To store the loans in pending settlement status
  public PendingSettlements;

  //Used by paginator to control what page is currently clicked
  public CurrentPage: number;

  //Used by paginator for config, size of the pages, etc.
  public Paging = {
    maxSize: 10,
    previousLabel: "",
    nextLabel: ""
  }

  //Text display limit
  public TextDisplayLimit = 30;
  public TextDisplayLimitLenderRef = 25;

  //Used for the filter search.
  public FilterSearchValue = "";
  public FilterSearchValueKeyUp = new Subject<any>();

  //Subscription for the filter
  public Subscription: Subscription;

  //For tracking if a server update request is in progress
  public IsGenerateInProgress = false;

  //For tracking if all accounts are being validated
  public IsValidationInProgress = false;

  //To allow user to view the process settlement button
  public AllowSettlementProcessing = false;

  //Angular startup method
  ngOnInit() {

    //Check for Pending Settlements Edit claim
    if (!this.globalFunctions.isEmpty(this.clientDataStore.ClientClaims)) {

      if (this.clientDataStore.ClientClaims.filter(x => x.Name == "PendingSettlements" && x.Edit).length > 0) {
        this.AllowSettlementProcessing = true;
      }
    }

    //We still need a short delay even this method is added on ngAfterViewInit
    this.globalFunctions.delay(10).then(() => {
      this.PendingSettlements_Get();
    });

    //This creates a subscription to apply the search filter as per the search box. 
    this.Subscription = this.FilterSearchValueKeyUp.pipe(
      map((event) => {
        return (<HTMLInputElement>event.target).value;
      }),
      debounceTime(10),
      distinctUntilChanged(),
      mergeMap(search => of(search).pipe(delay(10)))
    ).subscribe(value => {

      //Call it directly with a blank string if the value is empty
      if (this.globalFunctions.isEmpty(value)) {
        this.SettlementsFilter_Search("");
      }
      else {
        //Filter using the value entered
        this.SettlementsFilter_Search(value);
      }
    });
  }

  //Destroy the subscription
  ngOnDestroy() {
    if (!this.globalFunctions.isEmpty(this.Subscription)) {
      this.Subscription.unsubscribe();
    }
  }

  //To change the page for the paginator
  public Page_OnChange(page: number, doScroll = true) {
    this.CurrentPage = page;

    //Dont scroll to top, bad UI experience. scroll to bottom instead
    if (doScroll) {
      this.globalFunctions.delay(10).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });

    }
  }

  //Refresh pending settlements
  public PendingSettlements_Refresh() {

    //Clear search item if there is any
    this.FilterSearchValue = "";

    //Refresh the pending settlement screen
    this.PendingSettlements_Get();

    //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after refreshing.
    this.Page_OnChange(0, false);
  }

  //Send the request to get the list of pending settlements
  public PendingSettlements_Get() {

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct the request and send it to the server
    const apiRequest = {};

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GetPendingSettlements], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Get the response
          const response = JSON.parse(JSON.stringify(apiResponse));

          //List of loans in pending settlement status
          this.PendingSettlements = response.PendingSettlementsResult;

          if (!this.globalFunctions.isEmpty(this.PendingSettlements)) {
            //Loop through each item in the array and do the client side formatting
            this.PendingSettlements.forEach(element => {

              //Convert date to DD-MM-YYYY
              element.ApprovedDate_FMT = this.globalFunctions.getCustomDateFormat(element.ApprovedDate, "shortdate", "custom", "YYYY-MM-DD");

              //Convert the amounts to currency format
              element.ApprovedLimit_FMT = this.globalFunctions.customDataTypeParser(element.LoanApprovalLimit, "currency.2", "aus");

              //Unescaping the HTML characters
              element.PrincipalBorrower = this.globalFunctions.HTMLUnescape(element.PrincipalBorrower);

              //For showing the tool tip for principal borrower
              element.DisablePBToolTip = false;
              if (element.PrincipalBorrower.length < this.TextDisplayLimit) {
                element.DisablePBToolTip = true;
              }

              //For showing the tool tip for product
              element.DisableProductToolTip = false;
              if (element.Product.length < this.TextDisplayLimit) {
                element.DisableProductToolTip = true;
              }

              //For showing the tool tip for lender reference
              element.DisableLenderRefToolTip = false;
              if (element.LenderReference.length < this.TextDisplayLimitLenderRef) {
                element.DisableLenderRefToolTip = true;
              }

              //For validation purpose
              element.Validation = "";
              element.IsValidated = false;
              element.IsValidating = false;
              element.isHidden = false;
              element.IsValidationPassed = false;
            });
          }

          this.notifyService.Success_Show("Accounts retrieved.", "Pending Settlements");

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;
          return;
        }
      });
  }

  //Validate all filtered pending settlements
  public PendingSettlement_ValidateAll(): void {

    if (this.globalFunctions.isEmpty(this.PendingSettlements)) {
      this.notifyService.Warning_Show("No pending settlement accounts to validate.", "Validation Error");
      return;
    }

    //Get list of filtered pending settlements
    const pendingSettlementAccounts = this.PendingSettlements.filter(x => x.isHidden == false);

    //For the spinner
    this.IsValidationInProgress = true;

    //Validate all filtered pending settlements
    this.PendingSettlement_Validate(pendingSettlementAccounts);
  }

  //Validate a selected pending settlement
  public PendingSettlement_ValidateAccount(pendingSettlement): void {

    //Converted the selected pending settlement in an array
    const pendingSettlementAccounts = this.PendingSettlements.filter(x => x.AccountID == pendingSettlement.AccountID);

    //Validate the pending settlement
    this.PendingSettlement_Validate(pendingSettlementAccounts);
  }

  //API call to validate the pending settlements in the list
  public PendingSettlement_Validate(pendingSettlements): void {

    //Validate the pending settlements list
    if (this.globalFunctions.isEmpty(pendingSettlements)) {
      this.notifyService.Error_Show("Please select a valid account", "Account Selection");
      return;
    }

    //Array to store the Account IDs to make API request
    const accountIDList: number[] = [];
    pendingSettlements.forEach(element => {

      //Track state of each pending settlement request, so that we can show the spinner, if necessary.
      element.IsValidating = true;
      accountIDList.push(element.AccountID);

      //Reset validation flags to allow revalidation
      element.Validation = "";
      element.IsValidated = false;
      element.isHidden = false;
      element.IsValidationPassed = false;
    });

    //Construct the request and send it to the server
    const apiRequest = { AccountIDList: accountIDList };

    //API request to validate the accounts
    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.ValidatePendingSettlement], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error.

          //Turn off the spinner for all accounts validation
          if (this.IsValidationInProgress == true) {
            this.IsValidationInProgress = false;
          }

          //Loop through each item in the array and turn off the validation spinner for each individual account
          pendingSettlements.forEach(element => {

            //Set the spinner flag to false
            element.IsValidating = false;
          });

          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Get the response
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Validation results
          const validationResults = response.ValidatePendingSettlementsResult;

          if (!this.globalFunctions.isEmpty(validationResults)) {

            //Loop through each item in the array and do the client side formatting
            validationResults.forEach(element => {

              //Look for matching Account ID in pending settlements
              const matchedPendingSettlement = this.PendingSettlements.filter(x => x.AccountID === element.AccountID)[0];

              if (!this.globalFunctions.isEmpty(matchedPendingSettlement)) {

                //Unescaping the HTML characters
                matchedPendingSettlement.Validation = this.globalFunctions.HTMLUnescape(element.ValidationMessage);
                matchedPendingSettlement.IsValidated = true;

                //Used for showing the green tick box
                if (!this.globalFunctions.isEmpty(element.ValidationMessage) && element.ValidationMessage.toUpperCase() === "CLEAN DATA") {
                  matchedPendingSettlement.IsValidationPassed = true;
                }

                //Validation process has completed
                matchedPendingSettlement.IsValidating = false;
              }
            });
          }

          //Turn off the spinner
          if (this.IsValidationInProgress == true) {
            this.IsValidationInProgress = false;
          }

          this.notifyService.Success_Show("Accounts Validated.", "Pending Settlements");
          return;
        }
      });
  }

  //Remove the settled loan from the list in pending settlement screen
  public SettledAccount_Remove(accountID: number): void {
    //Find the matched loan on the pending settlement list
    const matchedLoan = this.PendingSettlements.findIndex(x => x.AccountID == accountID);

    //And use splice to remove it
    this.PendingSettlements.splice(matchedLoan, 1);
  }

  //Limit text size on the column
  public TextSize_Limit(input, length: number) {
    return this.globalFunctions.LimitTextSize(input, length);
  }

  //Check if we need to display the tool tip
  public ToolTipDisplay_Validate(value: string, limit: number): boolean {

    //True means disable tool tip
    if (this.globalFunctions.isEmpty(value))
      return true;

    if (value.length >= limit)
      return true;

    return false;
  }

  //Wrapper of isempty to allow local html template to call it. this is needed to stop the template from calling it when it is empty (before user data is populated from server), or we get null errors.
  public Item_IsEmpty() {
    if (this.globalFunctions.isEmpty(this.PendingSettlements)) {
      return false;
    }
    return true;
  }

  //Clears data from the filter search box
  public SettlementsFilter_Clear(): void {
    this.FilterSearchValue = "";

    //Call it directly with a blank string to reset the search filter
    this.SettlementsFilter_Search("");
  }

  //Launch the Settlement Confirmation component
  public SettlementConfirmationModal_Launch(pendingSettlementAccount): void {

    //Get the component of this modal, and set a property on it. looks like it is stored in statementModalRef.componentInstance
    const settlementConfirmationModalRef = this.globalFunctions.FeatureModal_Launch(SettlementConfirmation, this.globalFunctions.GetFeatureModalConfig("70%"), this.dialog, "Settlement Confirmation", this.PendingSettlements.AccountID);

    settlementConfirmationModalRef.DialogRef.componentInstance.PendingSettlement = pendingSettlementAccount;
    settlementConfirmationModalRef.DialogRef.componentInstance.Settlements = this;
  }

  //This applies a filter based on the search term to the input entity.
  private SettlementsFilter_Search(searchTerm: string) {

    //Try to catch null or undefined, replace with blank string
    if (this.globalFunctions.isEmpty(searchTerm)) {
      //Force it to an empty value (if null or undefined)
      searchTerm = "";
    }

    //Make sure search term is not null, and greater than 2 characters. also uppercase it
    searchTerm = searchTerm.toUpperCase();
    if (searchTerm != null && searchTerm.length > 2) {

      //Loop through each row in the pending settlements list
      for (const key in this.PendingSettlements) {
        const value = this.PendingSettlements[key];

        //We want to loop through valid properties here.
        if (value['AccountID'].toString().toUpperCase().includes(searchTerm) === true || value['LenderReference'].toUpperCase().includes(searchTerm) === true || value['PrincipalBorrower'].toUpperCase().includes(searchTerm) === true || value['Product'].toUpperCase().includes(searchTerm) === true || value['LenderName'].toUpperCase().includes(searchTerm) === true || value['Validation'].toUpperCase().includes(searchTerm) === true || (!this.globalFunctions.isEmpty(value['ApprovedDate_FMT']) && value['ApprovedDate_FMT'].toString().toUpperCase().includes(searchTerm) === true)) {
          value.isHidden = false;
        }
        else {
          value.isHidden = true;
        }
      }

      //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
      this.Page_OnChange(0, false);
    }
    else if (searchTerm == "") {
      //Remove all the hide filters
      for (const key in this.PendingSettlements) {
        const value = this.PendingSettlements[key];

        if (!this.globalFunctions.isEmpty(value)) {
          value.isHidden = false;
        }
      }
    }
  }
}