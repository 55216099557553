import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';

@Component({
  selector: 'ConfirmModal',
  templateUrl: './ConfirmModal.html',
  styleUrls: ['./ConfirmModal.scss']
})
export class ConfirmModal implements OnDestroy {

  //this is the html content inside the modal
  htmlContent = "";
  ShowNoButton = true;
  ShowYesButton = true;
  YesButtonText = "Yes";
  public ModalIdentifier;

  constructor(
    public dialogRef: MatDialogRef<ConfirmModal>,
    @Inject(MAT_DIALOG_DATA) public message: string,
    public globalFunctions: GlobalFunctions) { }

  onNoClick(): void {

    //Remove the modal from the feature modal
    this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);
  }

  ngOnDestroy(): void {

    //Remove the modal from the feature modal
    this.globalFunctions.FeatureModal_Close(this.ModalIdentifier, null, true);
  }
}