import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CsvDataService, GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InputDataUnit } from '@app/Global/Models/ClientModels';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { environment } from '@env/environment';
import { AccountsControllerMethods } from '@app/Global/EnumManager';
import Chart from 'chart.js';

@Component({
  selector: 'ResidentialRepaymentCalculator',
  templateUrl: './ResidentialRepaymentCalculator.html',
  styleUrls: ['./ResidentialRepaymentCalculator.scss'],
  animations: [
    trigger('fadeInSection', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1.0s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class ResidentialRepaymentCalculator implements OnInit {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private clientDataStore: ClientDataStore,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ResidentialRepaymentCalculator>,
    private csvDataService: CsvDataService = null) {
  }

  //Loan index
  @Input() LoanIndex: LoanIndex;

  //For tracking if a server update request is in progress
  public IsGenerateInProgress = false;

  //Repayment calculator input data points
  @Input() EffectiveDate;
  @Input() SettlementDate;
  @Input() MaturityDate;
  @Input() OriginalMaturityDate;
  @Input() CurrentRepaymentAmount;
  @Input() Balance;
  @Input() BalanceType;
  @Input() ProductName;
  @Input() OriginalEffectiveRate;
  @Input() EffectiveRate;
  @Input() OriginalPaymentType;
  @Input() TermInMonths;
  @Input() CurrentRemainingTermsInMonths;
  @Input() CurrentRemainingTermsInMonthsDisplay;
  @Input() TargetAccountID
  @Input() AmortData;

  //Variables to ouput the calculated values
  @Input() RepaymentAmountMonthly;
  @Input() RepaymentAmountFortnightly;
  @Input() RepaymentAmountWeekly;

  //For displaying the total values
  @Input() TotalInterestChargedDisplay;
  @Input() TotalRepaymentsDisplay;

  //Monthly Repayment Value unformatted
  @Input() RepaymentAmountMonthlyUnformatted;

  //Unique identifier for the launched modal
  @Input() ModalIdentifier;

  //New monthly repayment value with extra
  public RepaymentAmountPlusExtraMonthly;

  //Amortisation data with monthly extra repayments
  public AmortDataExtraRepayment;

  //Flag to track whether to enable the inputs or not
  public InputsEnabled = false;

  //Flag to track whether to enable the extra repayment input
  public ExtraRepaymentInputEnabled = true;

  //Extra repayments value
  public ExtraMonthlyRepayment = 0;

  //New ModelData that captures it inside an array, which lets us use a generic function to retrieve and update
  public ModelData: InputDataUnit[] = [];

  //DateTime max date
  public DTPMaxDate = new Date(2080, 1, 1);

  //Today's date
  public DTPDate = new Date();

  //DateTime min date, ignoring the timestamp
  public DTPMinDate = new Date(this.DTPDate.getFullYear(), this.DTPDate.getMonth(), this.DTPDate.getDate());

  //Get the environment name
  public EnvironmentName = environment.environmentName;

  //Is P&I payment type
  public IsIOPaymentType = false;

  //Input Payment Type
  public PaymentType;

  //Current balance for display
  public CurrentBalance;

  //Remaining Term in Months based on effective and maturity dates
  public RemainingTermInMonths;
  public RemainingTermInMonthsDisplay;
  public RemainingTermInMonthsWithExtraRepaymentDisplay;
  public LoanTermReductionWithExtraRepaymentDisplay;

  //Show/hide the amortisation chart and summary
  public ShowChart = true;

  //Whether the inputs are validated or not
  public InputsValidated;
  public InputValidationMessage;

  //Chart identifier to support multiple chart element in the DOM
  public ChartIdentifier;

  //Show/hide Save Repayment Journal Note button
  public ShowSaveNote = false;

  //The html chart canvas for displaying amortisation schedule
  @ViewChild('ChartID') ChartJS;

  //Chart Data variables  
  private LineChartLabels = [];
  private LineChartData = [];
  private LineChartWithExtraRepaymentData = [];
  private ChartType;
  private ChartData;
  private PlugIns;
  private ChartOptions;
  private ChartJSConstructed;

  //To keep track of previously saved note request
  private LastRepaymentCalcSaveNoteRequest;

  //With Extra Repayments
  public TotalInterestChargedWithExtraRepaymentDisplay;
  public TotalRepaymentsWithExtraRepaymentDisplay;

  //Savings with Extra Repayments
  public TotalInterestSavingsWithExtraRepaymentDisplay;
  public TotalRepaymentSavingsWithExtraRepaymentDisplay;

  //Payment Types
  public PaymentTypes = [{ ControlGUID: "Interest Only", ControlValue: "Interest Only" }, { ControlGUID: "Principal and Interest", ControlValue: "Principal and Interest" }];

  //Angular startup method
  ngOnInit() {

    //Add unique identifier to the chart element
    this.ChartIdentifier = "ChartID_" + this.ModalIdentifier;

    //Initialise the input data unit values
    this.PaymentType = this.OriginalPaymentType;
    this.RemainingTermInMonths = this.CurrentRemainingTermsInMonths;
    this.RemainingTermInMonthsDisplay = this.CurrentRemainingTermsInMonthsDisplay;
    this.CurrentBalance = this.globalFunctions.customDataTypeParser(this.Balance, 'currency.2');

    //Fill static data for the input data model here, base it on the input names. Demonstrating the use of the partial constructor here, only mapping the properties we want to use
    this.ModelData.push(

      new InputDataUnit({ Name: "INP_MaturityDate", DisplayName: "Maturity Date", Placeholder: "Maturity Date", HTMLInputType: "date", Type: "shortdate", ValueDate: this.MaturityDate, Value: this.MaturityDate, MinDate: this.DTPMinDate })
      , new InputDataUnit({ Name: "INP_EffectiveDate", DisplayName: "Effective Date", Placeholder: "Effective Date", HTMLInputType: "date", Type: "shortdate", ValueDate: this.EffectiveDate, Value: this.EffectiveDate, MinDate: this.DTPMinDate })
      , new InputDataUnit({ Name: "INP_EffectiveRate", DisplayName: "Effective Rate", Placeholder: "Effective Rate", HTMLInputType: "textarea", Type: "percent", Value: this.EffectiveRate, ValueDisplay: this.globalFunctions.customDataTypeParser(this.EffectiveRate, "percent", "aus"), MinValue: this.globalFunctions.TextAreaValidMinNumericGreaterThanZero, MaxValue: this.globalFunctions.TextAreaValidMaxPercentage })
      , new InputDataUnit({ Name: "INP_Balance", DisplayName: "Balance", Placeholder: "Balance", HTMLInputType: "textarea", Type: "currency", Value: this.Balance, ValueDisplay: this.globalFunctions.customDataTypeParser(this.Balance, "currency.2", "aus"), MinValue: this.globalFunctions.TextAreaValidMinNumericGreaterThanZero, MaxValue: this.globalFunctions.TextAreaValidMaxNumeric })
      , new InputDataUnit({ Name: "INP_PaymentType", DisplayName: "Payment Type", Placeholder: "Choose Payment type", HTMLInputType: "dropdown", Type: "string", Value: this.PaymentType })
      , new InputDataUnit({ Name: "INP_RemainingTermInMonths", DisplayName: "Remaining Term In Months", Placeholder: "Remaining Term In Months", HTMLInputType: "textarea", Type: "integer", Value: this.RemainingTermInMonths, ValueDisplay: this.RemainingTermInMonths, MinValue: this.globalFunctions.TextAreaValidMinNumeric, MaxValue: this.globalFunctions.TextAreaValidMaxNumeric })
      , new InputDataUnit({ Name: "INP_ExtraMonthlyRepayment", DisplayName: "Extra Monthly Repayments", Placeholder: "Extra Monthly Repayments", HTMLInputType: "textarea", Type: "currency", Value: this.globalFunctions.customDataTypeParser(this.ExtraMonthlyRepayment, 'string'), ValueDisplay: this.globalFunctions.customDataTypeParser(this.ExtraMonthlyRepayment, "currency.2", "aus"), MinValue: this.globalFunctions.TextAreaValidMinNumeric, MaxValue: this.globalFunctions.TextAreaValidMaxNumericMD })
    );

    if (this.globalFunctions.Claim_VerifyPermission(this.clientDataStore, "ResidentialRepaymentCalculator", "Insert") === true) {
      this.ShowSaveNote = true;
    }

    //Check if the monthly repayment is less than or equal to zero
    if (this.globalFunctions.isEmpty(this.RepaymentAmountMonthlyUnformatted) || this.RepaymentAmountMonthlyUnformatted <= 0) {

      //Enable the inputs
      this.InputsEnabled_Toggle(true, true);
    }
    else {

      //Sync the form data unit inputs
      this.DataUnitInputsDisabled_Sync();

      if (this.ShowChart) {

        if (!this.globalFunctions.isEmpty(this.AmortData)) {

          //Sync the amort data for chart display
          this.ChartData_Sync();

          //Bit of delay prior to rendering the chart
          this.globalFunctions.delay(50).then(() => {

            //Invoke create chart method
            this.Charts_Display();
          });
        }
      }
    }
  }

  //Sync amortisation data
  private ChartData_Sync(): void {

    //Reset the x and y axes values
    this.LineChartLabels = [];
    this.LineChartData = [];
    this.LineChartWithExtraRepaymentData = [];

    //Loop through amort data and set the chart axes labels and calculate total interest charged
    this.AmortData.forEach(element => {

      //X-axis
      this.LineChartLabels.push(element.PeriodTerm);

      //Y-axis
      this.LineChartData.push(element.PrincipalBalance);

      //Sync the extra repayment data from the server
      if (this.ExtraMonthlyRepayment > 0 && !this.globalFunctions.isEmpty(this.AmortDataExtraRepayment) && this.AmortDataExtraRepayment.length > 0) {

        //Get the amort data with extra repayment for the target term
        const amortDataExtra = this.AmortDataExtraRepayment.filter(x => x.PeriodTerm === element.PeriodTerm)[0];

        if (!this.globalFunctions.isEmpty(amortDataExtra)) {
          this.LineChartWithExtraRepaymentData.push(amortDataExtra.PrincipalBalance);
        }
      }
    });
  }

  //Sync the display of the UI input elements
  public InputView_Sync(): void {

    //This is a generic method being called from FormDataUnit when an input is modified
    //This is required on the parent method if the change in the form data unit triggers any change in the flow in the parent component

    //Here we are going to enable/disable input based on payment type
    if (this.InputsEnabled) {

      //Check the payment type
      const paymentTypeDU = this.ModelDataUnit_Get({ id: "INP_PaymentType" });
      if (!this.globalFunctions.isEmpty(paymentTypeDU) && !this.globalFunctions.isEmpty(paymentTypeDU.Value)) {

        //Get the effective date DU
        const effectiveDateDU = this.ModelDataUnit_Get({ id: "INP_EffectiveDate" });

        if (!this.globalFunctions.isEmpty(effectiveDateDU)) {

          //Enable by default
          effectiveDateDU.Disabled = false;
        }

        //Get the maturity date DU
        const maturityDateDU = this.ModelDataUnit_Get({ id: "INP_MaturityDate" });

        if (!this.globalFunctions.isEmpty(maturityDateDU)) {

          //Enable by default
          maturityDateDU.Disabled = false;
        }

        //Now check if the payment type selected in Interest Only.
        if (paymentTypeDU.Value === "Interest Only") {

          //For IO repayment calculation, we always calculate for the current period based on the current balance
          if (!this.globalFunctions.isEmpty(effectiveDateDU)) {

            //Disable effective date
            effectiveDateDU.Disabled = true;
          }

          if (!this.globalFunctions.isEmpty(maturityDateDU)) {

            //Disable maturity date
            maturityDateDU.Disabled = true;
          }
        }
      }
    }
  }

  //Initialise repayment amounts
  public RepaymentAmount_Init(): void {
    this.RepaymentAmountMonthly = this.globalFunctions.customDataTypeParser(0, "currency.2", "aus");
    this.RepaymentAmountFortnightly = this.globalFunctions.customDataTypeParser(0, "currency.2", "aus");
    this.RepaymentAmountWeekly = this.globalFunctions.customDataTypeParser(0, "currency.2", "aus");
  }

  //Get the matching model data input, used in InputView_Sync to determine when new content needs to be rendered
  public ModelDataUnit_Get(input): InputDataUnit {
    const modelDataItem = this.ModelData.filter(x => x.Name === input.id)[0];

    if (!this.globalFunctions.isEmpty(modelDataItem)) {
      return modelDataItem;
    }
  }

  //For toggling the extra monthly repayment input (i.e. enable/disable and syncing the value/chart)
  public ExtraRepaymentInputEnabledToggle_Sync(enableInput = false, syncChartData = false): void {

    //Toggle the ExtraRepaymentInputEnabled flag
    this.ExtraRepaymentInputEnabled = enableInput;

    //Initialise the extra repayment amount if we are enabling the input DU
    if (enableInput) {

      //Get the ExtraMonthlyRepayment DU
      const extraRepaymentDU = this.ModelDataUnit_Get({ id: "INP_ExtraMonthlyRepayment" });

      //Reset the extra monthly repayment value
      this.ExtraMonthlyRepayment = 0;

      //Set the DU value
      extraRepaymentDU.Value = this.globalFunctions.customDataTypeParser(this.ExtraMonthlyRepayment, 'string');
      extraRepaymentDU.ValueDisplay = this.globalFunctions.customDataTypeParser(this.ExtraMonthlyRepayment, "currency.2", "aus");
    }

    //Remove extra repayment chart while modifying the monthly extra repayment value. This happens when user clicks modify button on the monthly extra repayment input button
    if (syncChartData) {

      //Sync the amortised data for chart display
      if (!this.globalFunctions.isEmpty(this.AmortData)) {

        //Sync the chart data
        this.ChartData_Sync();
      }

      if (this.ShowChart) {

        //Bit of delay prior to rendering the chart
        this.globalFunctions.delay(50).then(() => {

          //Invoke create chart method
          this.Charts_Display();
        });
      }
    }

    //Enable/Disable the extra monthly repayment input
    this.DUInputDisabled_Sync("INP_ExtraMonthlyRepayment", enableInput);
  }

  //Check conditions to enable/disable the action buttons
  public InputsEnabled_Toggle(enableInputs = false, initRepaymentAmount = true): void {

    if (initRepaymentAmount) {

      //Reset the instalment amount
      this.RepaymentAmount_Init();
    }

    //Enable the inputs
    this.InputsEnabled = enableInputs;

    //Reset ExtraRepaymentInputEnabled flag to true
    this.ExtraRepaymentInputEnabled = true;
    this.DUInputDisabled_Sync("INP_ExtraMonthlyRepayment", true);

    //Enable/Disable all repayment calc form data unit inputs
    this.DataUnitInputsDisabled_Sync();
  }

  //Sync the repayment calc form data unit inputs
  public DataUnitInputsDisabled_Sync() {

    //Loop through all the model data units (except INP_ExtraMonthlyRepayment which needs to be enabled all the time) and set the disabled flag based on whether to enable or disable inputs
    this.ModelData.filter(x => x.Name != "INP_ExtraMonthlyRepayment").forEach(du => {
      const modelDataUnit = this.ModelDataUnit_Get({ id: du.Name });
      if (!this.globalFunctions.isEmpty(modelDataUnit)) {
        if (modelDataUnit.Name === "INP_RemainingTermInMonths") {
          //This is calculated value, lets disable it all the time
          modelDataUnit.Disabled = true;
        }
        else {
          modelDataUnit.Disabled = !this.InputsEnabled;
        }
      }
    });

    //Sync Payment Type flag
    this.PaymentTypeFlag_Sync();

    //Call input sync view
    this.InputView_Sync();
  }

  //Sync payment type flag
  public PaymentTypeFlag_Sync(): void {
    this.IsIOPaymentType = false;

    const paymentTypeDataUnit = this.ModelDataUnit_Get({ id: "INP_PaymentType" });
    if (!this.globalFunctions.isEmpty(paymentTypeDataUnit) && !this.globalFunctions.isEmpty(paymentTypeDataUnit.Value)) {
      if (paymentTypeDataUnit.Value.toUpperCase() === "INTEREST ONLY") {

        //The payment type selected is IO type
        this.IsIOPaymentType = true;
      }
    }

    //Sync the show chart flag
    this.ShowChart_Sync();
  }

  //Sync the show flag 
  public ShowChart_Sync(): void {

    //Show chart for P&I only
    this.ShowChart = true;

    if (this.InputsEnabled || this.IsIOPaymentType) {
      this.ShowChart = false;
    }
  }

  //CSS for a underline if extra repayment is included
  public UnderlineCSS_Get(): string {
    let css = "";
    if (this.ExtraMonthlyRepayment > 0) {
      css = "glb_labelBottomBorderDark";
    }

    return css;
  }

  //Send the request to generate the payout calculation on the server
  public RepaymentWithExtra_Calculate(): void {

    //Get the target DU
    const extraMonthlyRepaymentDU = this.ModelDataUnit_Get({ id: "INP_ExtraMonthlyRepayment" });

    //Check if we have target DU and its Value
    if (this.globalFunctions.isEmpty(extraMonthlyRepaymentDU) || this.globalFunctions.isEmpty(extraMonthlyRepaymentDU.Value) || Number(extraMonthlyRepaymentDU.Value) <= 0) {

      //Validation failed, notify user
      this.notifyService.Warning_Show("Please enter the extra monthly repayment amount", "Warning");

      return;
    }

    //Set the extra monthly repayment class property
    this.ExtraMonthlyRepayment = Number(extraMonthlyRepaymentDU.Value);

    //Invoke the api to calculate the repayments with extra monthly repayment amount
    this.Repayment_Calculate(Number(extraMonthlyRepaymentDU.Value));
  }

  //Send the request to save repayment calculation on the server
  public Repayment_SaveNote() {

    //Let's calculate and save the repayment calc as a journal note
    this.Repayment_Calculate(this.ExtraMonthlyRepayment, true);
  }

  //Send the request to generate the repayment calculation on the server
  public Repayment_Calculate(monthlyExtraRepayment = 0, saveNoteFlag = false) {

    //Validate the inputs first
    this.DUInputs_Validated();

    if (this.InputsValidated === false) {

      //Validation failed, notify user
      this.notifyService.Warning_Show(this.InputValidationMessage, "Warning");

      return;
    }

    //Get the input data units
    const balanceDU = this.ModelDataUnit_Get({ id: "INP_Balance" });
    const effectiveRateDU = this.ModelDataUnit_Get({ id: "INP_EffectiveRate" });
    const maturityDateDU = this.ModelDataUnit_Get({ id: "INP_MaturityDate" });
    const effectiveDateDU = this.ModelDataUnit_Get({ id: "INP_EffectiveDate" });
    const remainingTermInMonthsDU = this.ModelDataUnit_Get({ id: "INP_RemainingTermInMonths" });
    const paymentTypeDU = this.ModelDataUnit_Get({ id: "INP_PaymentType" });

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID, Balance: Number(balanceDU.Value), EffectiveRate: Number(effectiveRateDU.Value), EffectiveDate: effectiveDateDU.Value, MaturityDate: maturityDateDU.Value, PaymentType: paymentTypeDU.Value, MonthlyExtraRepayment: monthlyExtraRepayment, SaveNoteFlag: saveNoteFlag };

    //LastRepaymentCalcSaveNoteRequest will be null on initial run, ignore the comparison, otherwise compare the whole request with the last saved one
    if (saveNoteFlag && this.LastRepaymentCalcSaveNoteRequest != null && this.LastRepaymentCalcSaveNoteRequest === JSON.stringify(apiRequest)) {

      //User has clicked the button again for the same repayment calc journal note request
      this.notifyService.Error_Show("Repayment Calculation Journal Note has already been saved.", "Repayment Calculator");
      return;
    }

    //Clone the apirequest into LastRepaymentCalcSaveNoteRequest
    this.LastRepaymentCalcSaveNoteRequest = JSON.stringify(apiRequest);

    //Turn on the fullscreen loading
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Server update request in progress
    this.IsGenerateInProgress = true;

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.CalculateResiRepaymentCalc], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //There was no response, or an error.

          //Enable the inputs
          this.InputsEnabled_Toggle(true, true);

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);

          //Turn off the spinner for Server update request in progress
          this.IsGenerateInProgress = false;

          //Reset the last saved repayment calculation note request to allow user to click the Save Repayment button again
          this.LastRepaymentCalcSaveNoteRequest = null;

          return;
        }
        else {
          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Set the indicative repayment values from the response and format as currency
          this.RepaymentAmountMonthlyUnformatted = response.MonthlyRepayment;
          this.RepaymentAmountMonthly = this.globalFunctions.customDataTypeParser(response.MonthlyRepayment, "currency.2", "aus");
          this.RepaymentAmountFortnightly = this.globalFunctions.customDataTypeParser(response.FortnightlyRepayment, "currency.2", "aus");
          this.RepaymentAmountWeekly = this.globalFunctions.customDataTypeParser(response.WeeklyRepayment, "currency.2", "aus");

          //Update Remaining Term in Months
          this.RemainingTermInMonths = response.RemainingTermInMonths;
          this.RemainingTermInMonthsDisplay = response.RemainingTermDisplay;
          remainingTermInMonthsDU.Value = this.globalFunctions.customDataTypeParser(response.RemainingTermInMonths, "integer", "aus");
          remainingTermInMonthsDU.ValueDisplay = response.RemainingTermInMonths;

          //Get updated chart data
          this.AmortData = response.ChartAmortDataPerYear;
          this.AmortDataExtraRepayment = response.ChartAmortDataPerYearWithExtraRepayment;

          //Totals
          this.TotalInterestChargedDisplay = this.globalFunctions.customDataTypeParser(response.TotalInterestCharged, "currency.2", "aus");
          this.TotalRepaymentsDisplay = this.globalFunctions.customDataTypeParser(response.TotalRepayment, "currency.2", "aus");

          //Check if it is a response with extra repayment
          if (this.globalFunctions.isEmpty(monthlyExtraRepayment) || monthlyExtraRepayment === 0 || this.globalFunctions.isEmpty(this.AmortDataExtraRepayment) || this.AmortDataExtraRepayment.length === 0) {

            //Enable the extra repayment input and button
            this.ExtraRepaymentInputEnabledToggle_Sync(true);
          }
          else {

            //Disable the extra repayment input and button
            this.ExtraRepaymentInputEnabledToggle_Sync(false);

            //Totals
            this.TotalInterestChargedWithExtraRepaymentDisplay = this.globalFunctions.customDataTypeParser(response.TotalInterestChargedWithExtra, "currency.2", "aus");
            this.TotalRepaymentsWithExtraRepaymentDisplay = this.globalFunctions.customDataTypeParser(response.TotalRepaymentWithExtra, "currency.2", "aus");
            this.RemainingTermInMonthsWithExtraRepaymentDisplay = response.RemainingTermWithExtraDisplay;

            //Savings
            this.TotalInterestSavingsWithExtraRepaymentDisplay = this.globalFunctions.customDataTypeParser(response.TotalInterestSavings, "currency.2", "aus");
            this.TotalRepaymentSavingsWithExtraRepaymentDisplay = this.globalFunctions.customDataTypeParser(response.TotalRepaymentSavings, "currency.2", "aus");
            this.LoanTermReductionWithExtraRepaymentDisplay = response.LoanTermReductionInMonthsWithExtraDisplay;

            //New Repayment Amount: Min Repayment + Extra
            this.RepaymentAmountPlusExtraMonthly = this.globalFunctions.customDataTypeParser(this.RepaymentAmountMonthlyUnformatted + monthlyExtraRepayment, "currency.2", "aus");
          }

          //Sync the amortised data for chart display
          if (!this.globalFunctions.isEmpty(this.AmortData)) {

            //Sync the chart data
            this.ChartData_Sync();
          }

          if (this.ShowChart) {

            //Bit of delay prior to rendering the chart
            this.globalFunctions.delay(50).then(() => {

              //Invoke create chart method
              this.Charts_Display();
            });
          }

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);

          //Server update request completed
          this.IsGenerateInProgress = false;

          //Success message
          this.notifyService.Success_Show(response.ResponseMessage, "Repayment Calculator")

          return;
        }
      });

    //Disable the inputs
    this.InputsEnabled_Toggle(false, false);
  }

  //Download amortisation
  public Amortisation_DownloadCSV(): void {

    const csvArray = [];

    //Loop through the Amortisation Data and perform formatting on data
    this.AmortData.forEach(element => {

      //Remove the raw values
      delete element.PrincipalBalanceRaw;
      delete element.InterestRaw;
      delete element.PrincipalRaw;
      delete element.MonthlyPayment;

      //Remove T from the timestamps on download so that excel can read it a datetime
      if (!this.globalFunctions.isEmpty(element.AmortDate)) {
        element.AmortDate = element.AmortDate.replace("T", " ").replace("+10:00", "").replace("+11:00", "");
      }

      //Format the balances
      if (!this.globalFunctions.isEmpty(element.PrincipalBalance)) {
        element.PrincipalBalance = this.globalFunctions.customDataTypeParser(element.PrincipalBalance, "currency.2", "aus");
      }

      if (!this.globalFunctions.isEmpty(element.Interest)) {
        element.Interest = this.globalFunctions.customDataTypeParser(element.Interest, "currency.2", "aus");
      }

      if (!this.globalFunctions.isEmpty(element.Principal)) {
        element.Principal = this.globalFunctions.customDataTypeParser(element.Principal, "currency.2", "aus");
      }

      //Push into the array to be used for download
      csvArray.push(element);
    });

    //Call the service to produce and download the csv file
    this.csvDataService.exportToCsv("Amortisation Schedule", csvArray);
  }

  //Construct a chart
  private Charts_Display(): void {

    //Construct chart using data from the server
    this.Chart_Create();

    //Build and display the chartjs locally
    this.ChartJS_Build();
  }

  //Generic method that creates a chart based on passed in options. can add more params if we like too, e.g. the options object - font styles, color, chart type (pie vs bar) etc.
  private Chart_Create(): void {

    //Set the chart datasets
    this.ChartType = "line";

    //Construct datasets for y axis
    const datasets = [];

    //Push the data set if we are generating chart with extra repayment
    if (!this.globalFunctions.isEmpty(this.LineChartWithExtraRepaymentData) && this.LineChartWithExtraRepaymentData.length > 0) {
      const dataSetWithExtra =
      {
        label: "Principal with Extra Repayment",
        data: this.LineChartWithExtraRepaymentData,
        backgroundColor: '#0EB2D4',
      }
      datasets.push(dataSetWithExtra);
    }

    //Now push the regular amortised data
    const dataSetRegular =
    {
      label: "Principal",
      data: this.LineChartData,
      backgroundColor: '#87cde7',
    }
    datasets.push(dataSetRegular);

    this.ChartData = {

      //Inject data for the x axis      
      labels: this.LineChartLabels,

      //Add all the datasets that we need
      datasets: datasets
    };

    this.PlugIns = [];

    this.ChartOptions = {
      maintainAspectRatio: false,
      responsive: true,
      tooltips: {

        //Disable the on-canvas tooltip
        enabled: false,

        //Custom tooltip to display the message in HTML format (chartjs line chart documentation)
        //() => [Lexical scoping] is important to allow a function to access the parent properties i.e. globalfunctions
        custom: (tooltipModel) => {

          //Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          //Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          //Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = "0";
            return;
          }

          //Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          //Set Text
          if (tooltipModel.body) {

            //This is the amort data to be used to fetch the interest portion
            let tooltipAmortData = this.AmortData;

            //To set the interest charged portion on that period
            let interestCharged = 0;

            //Identify the amort data point in the chart
            const chartLabelPoint = tooltipModel.dataPoints[0].xLabel;
            if (!this.globalFunctions.isEmpty(chartLabelPoint)) {

              //Check if we have multiple datasets (see if we have amortisation with extra repayments)
              if (this.ChartData.datasets.length > 1) {

                //This will indicate on which chart dataset the mouse is hovered
                const chartDataSetIndex = tooltipModel.dataPoints[0].datasetIndex;

                //0 means the first dataset, which is the amort data with extra repayments
                if (chartDataSetIndex === 0) {

                  //Set the tooltip amort data with the target amort data
                  tooltipAmortData = this.AmortDataExtraRepayment;
                }
              }

              //Get the amort data for the target data point
              const amortChartDataPoint = tooltipAmortData.filter(x => x.PeriodTerm == chartLabelPoint)[0];
              if (!this.globalFunctions.isEmpty(amortChartDataPoint)) {

                //Set the interest charged from the amort data
                interestCharged = this.globalFunctions.customDataTypeParser(amortChartDataPoint.Interest, "currency.2", "aus");
              }
            }

            //Set principal owing from the dataset
            const principalOwing = this.globalFunctions.customDataTypeParser(tooltipModel.dataPoints[0].value, "currency.2", "aus");

            //Tooltip message in HTML format
            const innerHtml = `
                        <div class="col-6 glb_customFlexRow" style="border-collapse: separate; overflow: hidden; border-radius: 10px; box-shadow: 0 6px 12px rgba(0,0,0,.175);">

                            <div style="background-color: #ECEFF1; padding-top: 5px; padding-bottom: 6px; padding-left: 7px; color: #000; font-family: 'Poppins'; border-bottom: solid 1px #DDD" class="justify-content-center glb_customFlexRow glb_Font glb_TitleSmall col-12">
                               Year ${tooltipModel.dataPoints[0].label}
                            </div>
                            <div class="col-12 glb_customFlexRow" style=" background-color: white">
                                 <div class="col-12 glb_customFlexRow glb_Font">
                                 <div class="col-12 glb_customFlexRow glb_PaddingVerticalSM glb_PaddingHorizontalXXS">
                                      <div class="col-6 justify-content-start glb_PaddingHorizontalXXS">Principal</div> 
                                      <div class="col-6 glb_customFlexRow justify-content-end glb_PaddingHorizontalXXS" style="font-weight: 400">${principalOwing}</div>
                                    </div>
                                    <div class="col-12 glb_customFlexRow glb_PaddingVerticalSM glb_PaddingHorizontalXXS">
                                      <div class="col-6 justify-content-start glb_PaddingHorizontalXXS">Interest</div>
                                      <div class="col-6 glb_customFlexRow justify-content-end glb_PaddingHorizontalXXS" style="font-weight: 400">${interestCharged}</span></div>
                                    </div>
                                </div>
                            </div>
                         </div>
                    `;

            const tableRoot = tooltipEl.querySelector('table');
            tableRoot.innerHTML = innerHtml;
          }

          //Get the tooltip position using canvas element
          const position = document.getElementById(this.ChartIdentifier).getBoundingClientRect();

          //Display, position, and set styles for font
          tooltipEl.style.opacity = "1";
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        }
      },
      scales: {
        yAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: "white",
            callback: function (value) {
              return '$' + value / 1000 + 'k';
            }
          },
        }],
        xAxes: [{
          gridLines: {
            display: false
          },
          ticks: {
            fontColor: "white",
            autoSkip: true,
          },
          scaleLabel: {
            display: true,
            labelString: "Year",
            fontColor: "white",
          }
        }]
      },
      legend: {
        position: 'top',
        align: 'end',
        //This makes the icon change to a pointer when the legend is hovered on by the mouse pointer
        onHover: function (e) {
          e.target.style.cursor = 'pointer';
        },
        labels: {
          usePointStyle: true,
          fontColor: 'white',
        }
      }
    }
  }

  //Build Charts using native Chartjs
  private ChartJS_Build(): void {

    //If it doesn't exist, the chart might be empty. Don't try to construct it
    if (this.globalFunctions.isEmpty(this.ChartJS)) {
      //console.log('BuildChartJS empty!');
      return;
    }

    if (!this.globalFunctions.isEmpty(this.ChartJSConstructed)) {
      this.ChartJSConstructed.destroy();
    }

    //Make a chartjs version
    const chartItem = this.ChartJS.nativeElement;

    this.ChartJSConstructed = new Chart(chartItem, {
      type: this.ChartType
      , data: this.ChartData
      , options: this.ChartOptions

      //Might need to give this plugins as well
      , plugins: this.PlugIns
    });
  }

  //Check if input data units are validated
  private DUInputs_Validated(): void {

    //Initialise the flag with true value
    this.InputsValidated = true;

    //Get the input data units
    this.DUInput_Validate("INP_Balance");
    this.DUInput_Validate("INP_PaymentType");
    this.DUInput_Validate("INP_EffectiveRate");
    this.DUInput_Validate("INP_MaturityDate");
    this.DUInput_Validate("INP_EffectiveDate");
  }

  //Validate Data Unit
  private DUInput_Validate(duInputName: string): void {

    if (this.InputsValidated === false) {
      return;
    }

    this.InputValidationMessage = "";

    //Get the target DU
    const targetDU = this.ModelDataUnit_Get({ id: duInputName });

    //Check if we have target DU and its Value
    if (this.globalFunctions.isEmpty(targetDU) || this.globalFunctions.isEmpty(targetDU.Value) || targetDU.Value === "Invalid date") {

      //DU not found or value is empty
      this.InputsValidated = false;

      //Set the message
      this.InputValidationMessage = targetDU.DisplayName + " has invalid value";
    }
  }

  //Enable/Disable Data Unit
  private DUInputDisabled_Sync(duInputName: string, enableDU: boolean): void {

    //Get the target DU
    const targetDU = this.ModelDataUnit_Get({ id: duInputName });

    //Check if we have target DU and its Value
    if (!this.globalFunctions.isEmpty(targetDU)) {

      //Toggle the DU
      targetDU.Disabled = !enableDU;
    }
  }
}