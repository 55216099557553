<div class="glb_paddingTopLG glb_paddingBottomLG glb_formBGColor text-white glb_Font">
  <div class="glb_paddingOffset col-md-12 col-lg-12">
    <p class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">Role Management</p>
    <!-- <div class="glb_paddingBottomMD">
      <button (click)="Error_Throw()">Throw Error!</button>
    </div> -->
    <div
      class="glb_forceMarginAuto glb_customFlexRow row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-2 justify-content-center align-items-center glb_paddingBottomMD">
      <div>
        <!-- this tries to mimic how a mat-form-field-label looks on the autocomplete or control types -->
        <input class="glb_formInputBoxDark glb_skinLightOutline glb_Font text-white" (keyup)='filterSearchValueKeyUp.next($event)'
          [(ngModel)]='filterSearchValue' cdkFocusInitial tabindex="1" type="name" placeholder="Filter Role">
      </div>
      <div class="glb_customFlexRow justify-content-lg-end justify-content-md-end justify-content-sm-end justify-content-xs-start align-items-end">
        <span style="padding-left:0.375em;">
          <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
            (click)="SearchFilter_Clear()">
            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'eraser']" matTooltip="Clear search filter">
            </fa-icon>
          </button>
        </span>
        <!-- <span style="padding-left:0.375em;">
          <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
            (click)="SearchFilter_Clear()">
            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'user-plus']"  matTooltip="Add new user">
            </fa-icon>
          </button>
        </span> -->
      </div>
    </div>

    <!-- sample code to add a chevron with sliding row. this is the CREATE section -->
    <div class="glb_customFlexRow justify-content-end" (click)="TemplateID_Toggle('createRole')">
      <a #createRoleFocus class="glb_chevronToggle justify-content-end glb_customFlexRow col-2 glb_hoverHand">
        <fa-icon [icon]="['fal', 'plus']" [ngClass]="TemplateID_GetCSS('createRole',true)" style="color: white;" (click)="CreateRoleModal_Expand()"
          matTooltip="Expand">
        </fa-icon>
        <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('createRole',false)" style="color: white;" matTooltip="Hide">
        </fa-icon>
      </a>
    </div>

    <div id="createRole" [ngClass]="TemplateID_GetCSS('createRole')" #createRoleForm style="padding-bottom: 15px;">
      <!-- sliding content - to create a new claim -->
      <!-- Use headers to define columns -->

      <div class="card card-body-lesspadding card-body">
        <div class="row-cols-2 glb_customFlexRow align-items-center">
          <div class="glb_customFlexRow justify-content-left align-items-left text-dark">
            <mat-form-field class="glb_Font" appearance="outline">
              <input class="glb_Font" type="text" #focusNewRoleName [(ngModel)]="createRole_Name" matInput placeholder="Enter Role Name">
            </mat-form-field>
          </div>


          <!-- blank is the last column -->
          <div class="glb_customFlexRow justify-content-end align-items-center">
            <!-- save button -->
            <div class="row glb_customFlexRow justify-content-center align-items-center" style="padding-right: 15px;">
              <span style="padding-left:0.375em;">
                <button *ngIf="!isCreatingClaimRequest"
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                  (click)="Role_Create()">
                  <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Insert a New Role">
                  </fa-icon>
                </button>
              </span>
              <!-- lets stick a little spinner here, and switch it out when the button is pressed -->
              <span style="padding-left:0.375em;">
                <button *ngIf="isCreatingClaimRequest"
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                  (click)="SearchFilter_Clear()">
                  <fa-icon class="fa-fw glb_fixedSizeIcons glb_fastSpin" [icon]="['fal', 'compact-disc']" matTooltip="Please wait">
                  </fa-icon>
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- this is the loading spinner, lets show it while the entity is loading -->
    <div class="row" *ngIf="showSpinner">
      <div class="col">
        <div class="glb_customFlexRow justify-content-center">
          <div class="spinner-border" style="width: 3rem; height: 3rem;"></div>
        </div>
        <div class="glb_customFlexRow justify-content-center">Loading...</div>
      </div>
    </div>

    <!-- role results are displayed here -->
    <div *ngIf="UserList_IsEmpty() && !showSpinner">
      <div class="itemFirstBorder glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
        <div class="itemBorders" *ngFor="let item of roleList | searchfilter | paginate: {id: 'search', itemsPerPage: 9, currentPage:
        currentPage_search.currentPage}" @fadeIn style="padding: 0px; padding-top: 15px; padding-bottom: 10px;">
          <a (click)="RoleClaims_View(ViewRoleTemplate, item)" style="cursor: pointer;">
            <div class="glb_customFlexRow col-lg-12">
              <div class="col-12 col-lg-10 text-white">
                <div class="itemTitle glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">{{item.Name}}
                </div>
                <div class="glb_customFlexRow">
                  <div class="itemEmail justify-content-left text-start col-2 col-xs-2 col-sm-4 col-md-4 col-lg-2">
                    Created By: {{item.CreaterName}}
                  </div>
                  <!-- <div class="itemField justify-content-xs-left justify-content-right text-end text-sm-end text-md-end text-lg-end col-10
                  col-xs-10 col-sm-8 col-md-8 col-lg-6">Locked Status: {{ item.IsLocked }}
                </div> -->
                </div>

                <!-- <div class="flex row"> -->
                <!-- <div class="itemField justify-content-left text-start col-12 col-xs-12 col-sm-6 col-md-4 col-lg-4">Another Field
                  Balance: item.anotherfield
                </div> -->
                <!-- <div class="itemField justify-content-xs-left justify-content-right text-start text-sm-end
                  text-md-end
                  text-lg-end col-12 col-xs-12 col-sm-6 col-md-8 col-lg-4">Locked Status: {{ item.IsLocked }}
                </div> -->
                <!-- </div> -->
              </div>

              <div class="rightChevron col-0 col-lg-2 glb_customFlexRow justify-content-end align-items-center">
                <!-- show a chevron, only on lg screens -->
                <div class="d-none d-lg-block">
                  <fa-icon [icon]="['fal', 'chevron-right']" size="lg" class="btn align-self-right" style="color:grey;" matTooltip="Click to Launch">
                  </fa-icon>
                </div>
              </div>
            </div>
          </a>
        </div>

        <div class="glb_customFlexRow">
          <div class="col">
            <div class="glb_Font glb_customFlexRow justify-content-center">
              <pagination-controls id='search' class="glb_paginatorDark" (pageChange)="Page_OnChange($event, currentPage_search)"
                [maxSize]="paging.maxSize" [responsive]="true" [previousLabel]="paging.previousLabel" [nextLabel]="paging.nextLabel">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<ng-template #ViewUserList>
  <div class="glb_boxedPadding glb_formBGColor glb_Font">
    <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_Font glb_customFlexRow justify-content-end glb_paddingBottomLG">
      <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
        <!--[disabled]="isUpdatingUser"-->
        <button tabindex="30" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.FeatureModal_Close(UserAssignedModalIdentifier)">
          <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
        </button>
      </div>
    </div>
    <div class="glb_formBGColor glb_Font">
      <div class="glb_TitleLG glb_TitleLGMobile glb_paddingBottomMD glb_fontColorSkin">List of Users assigned to: {{chosenRole.Name}}</div>
    </div>
    <div class="itemBorders" *ngFor="let item of chosenRoleAssignedUsers | paginate: {id: 'roleAssignedUserList', itemsPerPage: 9, currentPage:
    currentPage_roleAssignedUserList.currentPage}">
      <div class="glb_Font glb_customFlexRow col-lg-12 glb_paddingZeroOffset">
        <div class="col-12 col-lg-10 glb_paddingZeroOffset">
          <div class="itemTitle glb_paddingZeroOffset glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">{{item.UserNumber}}
            ({{item.EmailAddress}})
          </div>
          <div class="itemEmail glb_paddingZeroOffset glb_customFlexRow justify-content-left text-start col-2 col-xs-2 col-sm-6 col-md-6 col-lg-6"
            *ngIf="item.CreaterName !== ''">Created
            By: {{item.CreaterName}}
          </div>
        </div>
      </div>
    </div>

    <div class="glb_customFlexRow">
      <div class="col">
        <div class="glb_Font glb_customFlexRow justify-content-center">
          <pagination-controls id='roleAssignedUserList' class="glb_paginatorDark"
            (pageChange)="Page_OnChange($event, currentPage_roleAssignedUserList, false)" [maxSize]="paging_roleAssignedUsers.maxSize"
            [responsive]="true" [previousLabel]="paging_roleAssignedUsers.previousLabel" [nextLabel]="paging_roleAssignedUsers.nextLabel">
          </pagination-controls>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ViewRoleTemplate>
  <div class="glb_boxedPadding glb_formBGColor glb_Font">
    <!-- close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_customFlexRow justify-content-end">
      <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
        <button [disabled]="roleSaveEnabled" tabindex="30"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="ClaimModal_Close()">
          <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
        </button>
      </div>
    </div>
    <div class="glb_Font header-padding glb_customFlexRow">
      <div class="header-padding glb_customFlexRow col-12 glb_paddingZeroOffset">
        <div class="col-8 glb_paddingZeroOffset">
          <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset">{{chosenRole.Name}}
          </div>
        </div>
        <div class="glb_customFlexRow col-4 justify-content-end">
          <div *ngIf="!roleSaveEnabled" class="buttonPadding">
            <span style="padding-left:0.375em;">
              <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="RoleNameEdit_Enable()">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to Edit this Role">
                </fa-icon>
              </button>
            </span>
          </div>
          <div *ngIf="roleSaveEnabled" class="buttonPadding">
            <span style="padding-left:0.375em;">
              <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="RoleName_Update()">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Save your changes">
                </fa-icon>
              </button>
            </span>
          </div>
          <!--a delete button -->
          <div class="buttonPadding">
            <span style="padding-left:0.375em;">
              <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="Role_Delete(chosenRole)">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete this Role">
                </fa-icon>
              </button>
            </span>
          </div>
          <!--a clone button -->
          <div class="buttonPadding">
            <span style="padding-left:0.375em;">
              <button class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                (click)="Role_Clone(chosenRole)">
                <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'clone']" matTooltip="Click to Clone this Role">
                </fa-icon>
              </button>
            </span>
          </div>
        </div>
        <div class="text-white glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomSM col-12">
          <div class="glb_paddingBottomSM">
            <div class="text-white glb_inputFieldLabel glb_customFlexRow">Role Name</div>
            <input #editRoleName tabindex="11" class="glb_formInputBox glb_skinLightOutline text-black glb_Font" type="text" placeholder="Role Name"
              [(ngModel)]="currentChosenRole.Name" [disabled]="!roleSaveEnabled" [ngClass]="{ 'glb_inactiveInputBox': !roleSaveEnabled }">
          </div>
        </div>
      </div>
    </div>

    <!-- This div is for buttons only-->
    <div class="glb_customFlexRow col-12 justify-content-start d-block">
      <div *ngIf="roleSaveEnabled">
        <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
          <div *ngIf="!isUpdatingRole" class="glb_paddingBottomSM  d-grid">
            <button tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="RoleName_Update()">
              <span class="glb_Font glb_smallerText">Save Changes</span></button>
          </div>
          <div *ngIf="isUpdatingRole" class="glb_paddingBottomSM d-grid">
            <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
              <span class="glb_Font glb_hiddenText glb_smallerText">Save Changes</span>
              <div class="spinner-border glb_floatingSpinner"></div>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="roleSaveEnabled">
        <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_paddingBottomSD">
          <div class="glb_paddingBottomSM d-grid">
            <button tabindex="19" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="RoleNameEdit_Discard()">
              <span class="glb_Font glb_smallerText">Discard Changes</span></button>
          </div>
        </div>
      </div>
    </div>

    <div class="glb_customFlexRow col-12 justify-content-start d-block glb_Font">
      <div *ngIf="this.chosenRoleAssignedUsersNumber > 0">
        <a class="assignedUser" (click)="RoleAssignedUsers_Launch(ViewUserList, chosenRole)" style="cursor: pointer;">
          This role is assigned to <b>{{this.chosenRoleAssignedUsersNumber}}</b> users (Click here to view all users).
        </a>
      </div>
      <div *ngIf="this.chosenRoleAssignedUsersNumber === 0" class="text-white">
        This role is not assigned any users yet.
      </div>
    </div>

    <div class="modal-body">
      <!-- claims section -->
      <div class="glb_sectionTitle1 glb_paddingTopLG glb_fontColorSkin">Role Claims</div>

      <!-- sample code to add a chevron with sliding row. this is the CREATE html elements -->
      <div class="glb_customFlexRow justify-content-end" (click)="TemplateID_Toggle('createClaim')">
        <a #createClaimFocus class="justify-content-end glb_customFlexRow col-2 glb_hoverHand">
          <fa-icon [icon]="['fal', 'plus']" [ngClass]="TemplateID_GetCSS('createClaim',true)" style="color:white" (click)="CreateClaimModal_Expand()"
            matTooltip="Expand"></fa-icon>
          <fa-icon [icon]="['fal', 'chevron-up']" [ngClass]="TemplateID_GetCSS('createClaim',false)" style="color:white" matTooltip="Hide">
          </fa-icon>
        </a>
      </div>

      <div id="createClaim" #createClaimForm [ngClass]="TemplateID_GetCSS('createClaim')" style="padding-bottom: 15px;">
        <!-- sliding content - to create a new claim -->
        <!-- Use headers to define columns -->
        <div [ngClass]="{'row row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : ColumnsInTab === 1
          ,'row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2' : ColumnsInTab === 2 }">
          <div class="card">
            <div class="glb_Font glb_customFlexRow glb_boxedPaddingNarrow glb_customFlexRow align-items-center"
              [ngClass]="CssClass_Get('DataPair', ColumnsInBody, WrapOddRows)">
              <div *ngFor="let tableheaderitem of TableHeaders; odd as isOdd; even as isEven; index as itemIndex" [ngClass]="{'glb_customFlexRow
                justify-content-center align-items-center' : tableheaderitem === ' ' }">
                <!-- || tableheaderitem === 'Permissions' -->
                <div class="text-style">
                  <div [ngClass]="CssClass_Get('TableHeaderValues', ColumnsInBody, WrapOddRows, isOdd, isEven, CombineLabelAndData, itemIndex,
                    TableHeaders.length)">
                    <div *ngIf="tableheaderitem === 'Claim Type'">
                      <!-- for control type fields (dropdown boxes). The input box or selectors should be in a row, and span one column. needs a slight padding so that it aligns/sits properly -->
                      <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1  justify-content-left align-items-left">
                        <div>
                          <mat-form-field appearance="outline" class="glb_Font mat-form-field-light-role">
                            <mat-select #matCreateClaim_Name class="glb_Font" [(value)]="createClaim_Name" placeholder="Claim Type"
                              (blur)="globalFunctions.MatSelectOverlay_Close(matCreateClaim_Name)">
                              <div #claimTypeFocus *ngFor="let control of claimNames">
                                <mat-option class="glb_Font" value={{control.Name}}>
                                  {{control.FriendlyName}}
                                </mat-option>
                              </div>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>

                    </div>

                    <div *ngIf="tableheaderitem === 'Targets'">
                      <!-- association style claims -->
                      <div *ngIf="createClaim_Name === 'Associations'">
                        <!-- needs a slight padding so that it aligns/sits properly -->
                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-light-custom mat-autocomplete-custom"
                              appearance="outline">
                              <input #createClaim_AssocationLinkType type="text" matInput [matAutocomplete]="createClaim_autoCompleteLinkType"
                                placeholder="Link Type">
                              <mat-autocomplete #createClaim_autoCompleteLinkType="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                                (optionSelected)="AutoComplete_SaveSelectedLinkType_ForCreate($event.option)" [panelWidth]="500">
                                <div *ngFor="let control of AutoComplete_LinkType_ApplyFilter(associationClaim, 'Search',
                                  createClaim_AssocationLinkType.value, 'LinkType')">
                                  <mat-option class="glb_Font" [value]=control>
                                    {{control.ControlValue}}
                                  </mat-option>
                                </div>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>

                        <!-- needs a slight padding so that it aligns/sits properly -->
                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-light-custom mat-autocomplete-custom"
                              appearance="outline">
                              <input #createClaim_AssocationClient type="text" matInput [matAutocomplete]="createClaim_autoCompleteClient"
                                placeholder="Client" [(ngModel)]="createClaim_ClientBind">
                              <mat-autocomplete #createClaim_autoCompleteClient="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                                (optionSelected)="AutoComplete_SaveSelectedClient_ForCreate($event.option)" [panelWidth]="900">
                                <div *ngFor="let control of AutoComplete_Client_ApplyFilter(associationClaim, 'Search',
                                  createClaim_AssocationClient.value, 'Client')">
                                  <mat-option class="glb_Font" [value]=control>
                                    {{control.ControlValue}}
                                  </mat-option>
                                </div>
                              </mat-autocomplete>
                            </mat-form-field>
                          </div>
                        </div>

                      </div>

                      <!-- entity type claims -->
                      <div *ngIf="createClaim_Name === 'Entities'">
                        <!-- for control type fields (dropdown boxes). only show this for the entities type -->
                        <!-- The input box or selectors should be in a row, and span one column -->
                        <!-- needs a slight padding so that it aligns/sits properly -->
                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            <mat-form-field appearance="outline" class="glb_Font mat-form-field-light-role">
                              <mat-select #matEntitySelect [(value)]="createClaim_Entity" (selectionChange)="EntityClaim_ForCreate($event)"
                                placeholder="Entity" (blur)="globalFunctions.MatSelectOverlay_Close(matEntitySelect)">
                                <div *ngFor="let control of entityTypes">
                                  <mat-option class="glb_Font" value={{control.FriendlyName}}>
                                    {{control.FriendlyName}}
                                  </mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>

                      <!-- entity property type claims -->
                      <div *ngIf="createClaim_Name === 'EntityProperty'">
                        <!-- needs a slight padding so that it aligns/sits properly -->
                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            <mat-form-field appearance="outline" class="glb_Font mat-form-field-light-role">
                              <mat-select #matSelect [(value)]="createClaim_Entity" (selectionChange)="EntityClaim_ForCreate($event)"
                                placeholder="Entity">
                                <div *ngFor="let control of entityTypesForFilter" (blur)="globalFunctions.MatSelectOverlay_Close(matSelect)">
                                  <mat-option class="glb_Font" value={{control.FriendlyName}}>
                                    {{control.FriendlyName}}
                                  </mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <!-- needs a slight padding so that it aligns/sits properly -->
                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            <mat-form-field appearance="outline" class="glb_Font mat-form-field-light-role">
                              <mat-select #matSelect [(value)]="createClaim_EntityProperty" (selectionChange)="EntityClaimProperty_ForCreate($event)"
                                placeholder="Entity Property" (blur)="globalFunctions.MatSelectOverlay_Close(matSelect)">
                                <div *ngFor="let control of entityPropertyArray">
                                  <mat-option class="glb_Font" value={{control.PropertyName}}>
                                    {{control.PropertyName}}
                                  </mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>

                      <!-- entity property filter type claims -->
                      <div *ngIf="createClaim_Name === 'EntityPropertyFilter'">
                        <!-- needs a slight padding so that it aligns/sits properly -->
                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            <mat-form-field appearance="outline" class="glb_Font mat-form-field-light-role">
                              <mat-select #matSelect [(value)]="createClaim_Entity" (selectionChange)="EntityClaim_ForCreate($event)"
                                placeholder="Entity" (blur)="globalFunctions.MatSelectOverlay_Close(matSelect)">
                                <div *ngFor="let control of entityTypesForFilter">
                                  <mat-option class="glb_Font" value={{control.FriendlyName}}>
                                    {{control.FriendlyName}}
                                  </mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <!-- needs a slight padding so that it aligns/sits properly -->
                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            <mat-form-field appearance="outline" class="glb_Font mat-form-field-light-role">
                              <mat-select #matSelect [(value)]="createClaim_EntityProperty" (selectionChange)="EntityClaimProperty_ForCreate($event)"
                                placeholder="Entity Property" (blur)="globalFunctions.MatSelectOverlay_Close(matSelect)">
                                <div *ngFor="let control of entityPropertyArray">
                                  <mat-option class="glb_Font" value={{control.PropertyName}}>
                                    {{control.PropertyName}}
                                  </mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            Permissive Filters
                            <br>
                            <textarea class="glb_Font inputEntityPropertyFilter" type="text" placeholder="separate multiple items with a comma"
                              [(ngModel)]="createClaim_PermissiveFilters">
                          </textarea>
                          </div>
                        </div>

                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            Restrictive Filters
                            <br>
                            <textarea class="glb_Font inputEntityPropertyFilter" type="text" placeholder="separate multiple items with a comma"
                              [(ngModel)]="createClaim_RestrictiveFilters">
                        </textarea>
                          </div>
                        </div>

                        <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                          <div>
                            Edit Whitelist Filters
                            <br>
                            <textarea class="glb_Font inputEntityPropertyFilter" type="text" placeholder="separate multiple items with a comma"
                              [(ngModel)]="createClaim_EditWhitelistFilters">
                            </textarea>
                          </div>
                        </div>

                      </div>

                    </div>

                    <!-- now for the permissions -->
                    <ng-container *ngIf="tableheaderitem === 'Permissions'">
                      <div class="glb_customFlexRow justify-content-start align-items-center">
                        <table>
                          <tr>
                            <th class="glb_Font">Read</th>
                            <th class="glb_Font">Edit</th>
                            <th class="glb_Font">Insert</th>
                            <th class="glb_Font">Delete</th>
                          </tr>
                          <tr>
                            <td class="centerAlign align-middle">
                              <mat-checkbox class="mat-checkbox-light-custom" #createClaim_ReadPermission [disabled]="false"
                                [(ngModel)]="createClaim_Read" [checked]="createClaim_Read">
                              </mat-checkbox>
                            </td>
                            <td class="centerAlign align-middle">
                              <mat-checkbox class="mat-checkbox-light-custom" #createClaim_EditPermission [disabled]="false"
                                [(ngModel)]="createClaim_Edit" [checked]="createClaim_Edit">
                              </mat-checkbox>
                            </td>
                            <td class="centerAlign align-middle">
                              <mat-checkbox class="mat-checkbox-light-custom" #createClaim_InsertPermission [disabled]="false"
                                [(ngModel)]="createClaim_Insert" [checked]="createClaim_Insert"></mat-checkbox>
                            </td>
                            <td class="centerAlign align-middle">
                              <mat-checkbox class="mat-checkbox-light-custom" #createClaim_DeletePermission [disabled]="false"
                                [(ngModel)]="createClaim_Delete" [checked]="createClaim_Delete"></mat-checkbox>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </ng-container>

                    <!-- blank is the last column -->
                    <div *ngIf="tableheaderitem === ' '" class="glb_customFlexRow justify-content-end justify-content-sm-center align-items-center">
                      <!-- save button -->
                      <div class="buttonPadding glb_customFlexRow justify-content-center align-items-center">
                        <span style="padding-left:0.375em;">
                          <button *ngIf="!isCreatingClaimRequest"
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                            (click)="EntityClaim_Create()">
                            <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to Insert this New Claim">
                            </fa-icon>
                          </button>
                          <button *ngIf="isCreatingClaimRequest"
                            class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                            (click)="EntityClaim_Create()">
                            <fa-icon class="glb_fastSpin fa-fw glb_fixedSizeIcons" [icon]="['fal', 'compact-disc']" matTooltip="Please wait">
                            </fa-icon>
                          </button>
                        </span>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>

      <!-- we need a header row -->
      <div [ngClass]="{'glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : ColumnsInTab === 1
        ,'row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2' : ColumnsInTab === 2 }">
        <div class="card card-body-bg card-body-bg-grey d-none d-md-block d-lg-block">
          <div class="glb_customFlexRow glb_boxedPaddingNarrow " [ngClass]="CssClass_Get('DataPair', ColumnsInBody, WrapOddRows)">

            <div *ngFor="let tableheaderitem of TableHeaders; odd as isOdd; even as isEven; index as itemIndex">
              <div class="glb_Font text-style">
                <div [ngClass]="CssClass_Get('TableHeaderValues', ColumnsInBody, WrapOddRows, isOdd, isEven, CombineLabelAndData, itemIndex,
                  TableHeaders.length)">
                  {{tableheaderitem}}
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <!-- and here are the claims -->
      <div [ngClass]="{'glb_Font glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1' : ColumnsInTab === 1
        ,'glb_Font glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-2' : ColumnsInTab === 2 }">
        <div *ngFor="let item of chosenRoleClaims; odd as isOdd; even as isEven; index as itemIndex" class="card">
          <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow" [ngClass]="CssClass_Get('DataPair', ColumnsInBody, WrapOddRows)">
            <div class="glb_customFlexRow justify-content-left align-items-center">
              {{item.NameFriendly}}
              <!-- {{item.NameString}} -->
            </div>

            <!-- This is the claim value-->
            <div class="col-12 glb_customFlexRow justify-content-left align-items-center">
              <div class="col-10 glb_customFlexRow">
                <textarea class="glb_textAreaHeightRegular" [(ngModel)]='item.Value'>{{item.Value}}</textarea>
              </div>
              <div class="col-2 glb_customFlexRow">
                <button
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                  (click)="ClaimValue_Save(item)">
                  <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to save the claim value">
                  </fa-icon>
                </button>
              </div>
            </div>

            <div class="glb_Font glb_customFlexRow justify-content-left align-items-center">
              <!-- show N/A if the target says so -->

              <div *ngIf="item.ClaimTargetType === 'Custom'">
                <div class="glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left" [innerHtml]="item.ClaimTarget">
                </div>
              </div>

              <!-- Entity Property Filter Type claims -->
              <div *ngIf="item.Name === 'EntityPropertyFilter'" class="col-12 glb_customFlexRow">

                <div class="col-8">
                  <!-- Dispay EntityName and the target property-->
                  <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                    <div>
                      {{item.EntityPropertyFilterClaim.EntityFriendlyName}}: {{item.EntityPropertyFilterClaim.EntityPropertyName}}
                      <br>
                    </div>
                  </div>

                  <!-- Display the entity property filters -->
                  <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                    <div>
                      Permissive Filters
                      <br>
                      <textarea class="glb_Font inputEntityPropertyFilter" type="text" placeholder="separate multiple items with a comma"
                        [(ngModel)]="item.EntityPropertyFilterClaim.EntityPermissiveFilter" [disabled]="!item.EditEnabled">
                    </textarea>
                    </div>
                  </div>

                  <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                    <div>
                      Restrictive Filters
                      <br>
                      <textarea class="glb_Font inputEntityPropertyFilter" type="text" placeholder="separate multiple items with a comma"
                        [(ngModel)]="item.EntityPropertyFilterClaim.EntityRestrictiveFilter" [disabled]="!item.EditEnabled">
                    </textarea>
                    </div>
                  </div>

                  <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                    <div>
                      Edit Whitelist Filters
                      <br>
                      <textarea class="glb_Font inputEntityPropertyFilter" type="text" placeholder="separate multiple items with a comma"
                        [(ngModel)]="item.EntityPropertyFilterClaim.EntityEditWhitelistFilter" [disabled]="!item.EditEnabled">
                    </textarea>
                    </div>
                  </div>
                </div>

                <!-- Add edit/cancel/save buttons on entity property filter claim type-->
                <div class="col-4 buttonPadding glb_customFlexRow justify-content-end align-items-center">
                  <div *ngIf="!item.EditEnabled && !ClaimEditEnabled">
                    <button
                      class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                      (click)="EntityPropertyFilterEdit_Click(item)">
                      <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'edit']" matTooltip="Click to edit the claim value">
                      </fa-icon>
                    </button>
                  </div>
                  <div *ngIf="item.EditEnabled && ClaimEditEnabled" class="glb_customFlexRow">
                    <div class="buttonPadding">
                      <button
                        class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                        (click)="EntityPropertyFilterCancel_Click(item)">
                        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'cancel']" matTooltip="Click to cancel the claim value">
                        </fa-icon>
                      </button>
                    </div>
                    <div class="buttonPadding">
                      <button
                        class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                        (click)="EntityPropertyFilterSave_Click(item)">
                        <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'save']" matTooltip="Click to save the claim value">
                        </fa-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!-- entity type claims -->
              <div *ngIf="item.Name === 'Entities'" class="col-12">
                <!-- for control type fields (dropdown boxes). only show this for the entities type -->
                <!-- The input box or selectors should be in a row, and span one column -->
                <!-- needs a slight padding so that it aligns/sits properly -->
                <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                  <div>
                    <mat-form-field appearance="outline" class="glb_Font mat-form-field-light-role">
                      <mat-select #matEntitySelect [(value)]="item.EntityClaim.EntityFriendlyName" (selectionChange)="EntityClaim_Update(item, $event,
                        matEntitySelect)" placeholder="Entity" (blur)="globalFunctions.MatSelectOverlay_Close(matEntitySelect)">
                        <div *ngFor="let control of entityTypes">
                          <!-- <mat-option *ngIf="control.Name != ''All''" value={{control.FriendlyName}}> -->
                          <mat-option class="glb_Font" value={{control.FriendlyName}}>
                            {{control.FriendlyName}}
                          </mat-option>
                        </div>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- association style claims -->
              <div *ngIf="item.Name === 'Associations'" style="width: 100%">
                <!-- needs a slight padding so that it aligns/sits properly -->
                <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                  <div>
                    <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-light-custom mat-autocomplete-custom" appearance="outline">
                      <input #inputVal type="text" matInput [matAutocomplete]="autoCompleteLinkType" placeholder="Link Type"
                        [(value)]="item.AssociationClaim.LinkType_AutoCompleteControlDisplayValue">
                      <mat-autocomplete #autoCompleteLinkType="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                        (optionSelected)='AutoComplete_SaveSelectedLinkType(item, $event.option, inputVal)' [panelWidth]="500">
                        <div *ngFor="let control of AutoComplete_LinkType_ApplyFilter(item.AssociationClaim, 'Search', inputVal.value, 'LinkType')">
                          <mat-option class="glb_Font" [value]=control>
                            {{control.ControlValue}}
                          </mat-option>
                        </div>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>

                <!-- needs a slight padding so that it aligns/sits properly -->
                <div class="glb_Font glb_boxedPaddingNarrow glb_customFlexRow row-cols-1 justify-content-left align-items-left">
                  <div>
                    <mat-form-field class="glb_Font glb_paddingZeroOffset mat-autocomplete-light-custom mat-autocomplete-custom" appearance="outline">
                      <input #clientInputVal type="text" matInput [matAutocomplete]="autoCompleteClient" placeholder="Client"
                        [(value)]="item.AssociationClaim.Client_AutoCompleteControlDisplayValue">
                      <mat-autocomplete #autoCompleteClient="matAutocomplete" [displayWith]="AutoComplete_GetPrettyName"
                        (optionSelected)='AutoComplete_SaveSelectedClient(item, $event.option, clientInputVal)' [panelWidth]="900">
                        <div *ngFor="let control of AutoComplete_Client_ApplyFilter(item.AssociationClaim, 'Search', clientInputVal.value, 'Client')">
                          <mat-option class="glb_Font" [value]=control>
                            {{control.ControlValue}}
                          </mat-option>
                        </div>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>

              </div>

            </div>

            <!-- can we put these inside a column? to preserve columns for the outer table? -->
            <div class="glb_customFlexRow align-items-center">
              <table>
                <tr>
                  <th class="glb_Font">Read</th>
                  <th class="glb_Font">Edit</th>
                  <th class="glb_Font">Insert</th>
                  <th class="glb_Font">Delete</th>
                </tr>
                <tr>
                  <td class="centerAlign align-middle">
                    <mat-checkbox class="mat-checkbox-light-custom" #ReadPermission [disabled]="false" [checked]="item.Read" (change)="ClaimCheckBox_Clicked(item, 'Read',
                      $event.checked, ReadPermission)"></mat-checkbox>
                  </td>
                  <td class="centerAlign align-middle">
                    <mat-checkbox class="mat-checkbox-light-custom" #EditPermission [disabled]="false" [checked]="item.Edit" (change)="ClaimCheckBox_Clicked(item, 'Edit',
                      $event.checked, EditPermission)"></mat-checkbox>
                  </td>
                  <td class="centerAlign align-middle">
                    <mat-checkbox class="mat-checkbox-light-custom" #InsertPermission [disabled]="false" [checked]="item.Insert" (change)="ClaimCheckBox_Clicked(item, 'Insert',
                      $event.checked, InsertPermission)"></mat-checkbox>
                  </td>
                  <td class="centerAlign align-middle">
                    <mat-checkbox class="mat-checkbox-light-custom" #DeletePermission [disabled]="false" [checked]="item.Delete" (change)="ClaimCheckBox_Clicked(item, 'Delete',
                      $event.checked, DeletePermission)"></mat-checkbox>
                  </td>
                </tr>
              </table>
            </div>

            <!-- Add a delete button -->
            <div class="buttonPadding glb_customFlexRow justify-content-end justify-content-sm-center align-items-center">
              <span style="padding-left:0.375em;">
                <button
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD btn-secondary align-self-right"
                  (click)="UserClaim_Delete(item.ClaimGUID)">
                  <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'trash']" matTooltip="Click to Delete this Claim">
                  </fa-icon>
                </button>
              </span>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>