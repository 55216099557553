<div class="glb_formBGColor glb_boxedPadding glb_Font text-white glb_Font">
	<!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
	<div class="glb_customFlexRow justify-content-end">
		<div class="justify-content-start align-self-bottom">
			<button tabindex="15" class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
				(click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
				<fa-icon [icon]="['fal', 'times']" class="fa-md glb_modalClose"></fa-icon>
			</button>
		</div>
	</div>

	<!-- Title -->
	<div class="glb_TitleMD glb_TitleMDMobile text-white labelPaddingBottom">
		<p>{{ChartData.labels[ClickedElementData[1]]}}</p>
		<p>{{ChartLabel}}</p>
	</div>

	<!-- Spinner -->
	<div *ngIf="ShowSpinner">
		<div class="col">
			<div class="text-white glb_customFlexRow justify-content-center">
				<div class="spinner-border glb_spinnerMD"></div>
			</div>
		</div>
	</div>

	<!-- Here we show the account details, similar to the Search Results screen -->
	<div *ngIf="!ShowSpinner">
		<div class="searchOuterPadding">
			<div class="searchResultFirstBorder glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1">
				<div class="searchResultBorders searchInnerPadding"
					*ngFor="let loan of AccountResultData | paginate: {id: 'search', itemsPerPage: 9, currentPage: PaginatorCurrentPage}" @fadeIn>
					<a routerLinkActive="active" (click)="SelectAccount(loan)">
						<div class="glb_customFlexRow col-lg-12 rowOverride glb_hoverHand">
							<div class="col-12 col-lg-10 paddingOverride">
								<div class="glb_customFlexRow">
									<div class="searchResultTitle glb_customFlexRow row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1"
										[innerHTML]="loan.PrincipalBorrower + ' (Principal Borrower)' | sanitizeHtml"> </div>
								</div>
								<div class="glb_customFlexRow">
									<div class="searchResultAccountID justify-content-left text-start col-12 col-xs-2 col-sm-6 col-md-4 col-lg-6"
										[innerHTML]="loan.AccountID + ' (' + loan.ForeignIdentifier + loan.LoanCustomFields + ')' | sanitizeHtml">
									</div>
									<div class="searchResultProduct text-start text-sm-end
											text-md-end text-lg-end col-12 col-xs-12 col-sm-6 col-md-8 col-lg-6" [innerHTML]="loan.ProductName">
									</div>
									<div class="searchResultProduct justify-content-left justify-content-xs-left justify-content-right text-start text-sm-start
												text-md-start text-lg-start col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6">
										Principal Balance: {{loan.PrincipalBalance | currency}}</div>
									<div class="searchResultProduct justify-content-xs-left justify-content-right text-start text-sm-end
												text-md-end text-lg-end col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6">Status: {{loan.LoanStatus}} </div>
									<div class="searchResultProduct justify-content-left justify-content-xs-left justify-content-right text-start text-sm-start
												text-md-start text-lg-start col-12 col-xs-12 col-sm-6 col-md-6 col-lg-6">
										Total Arrears: {{loan.TotalArrearsBalance | currency}}</div>
								</div>
							</div>

							<div class="rightChevron col-0 col-lg-2 glb_customFlexRow justify-content-end align-items-center">
								<!-- Show a chevron, only on lg screens -->
								<div class="d-none d-lg-block">
									<fa-icon [icon]="['fal', 'chevron-right']" size="lg" type="button"
										class="btn align-self-right glb_navToSearchResultChevronColor" matTooltip="Click to Launch Loan">
									</fa-icon>
								</div>
							</div>
						</div>
					</a>
				</div>

				<div class="glb_customFlexRow">
					<div class="col">
						<div class="glb_Font glb_customFlexRow justify-content-center">
							<pagination-controls class="glb_paginatorDark" id='search' (pageChange)="OnPageChange($event)"
								[maxSize]="PaginatorPaging.maxSize" [responsive]="true" [previousLabel]="PaginatorPaging.previousLabel"
								[nextLabel]="PaginatorPaging.nextLabel">
							</pagination-controls>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>