import { Component } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { WorkflowDelegator } from '../WorkflowDelegator';

@Component({
  selector: 'ReassignTask',
  templateUrl: './ReassignTask.html',
  styleUrls: ['./ReassignTask.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1' }),
        animate('0.1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ]
})

export class ReassignTask {

  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private router: Router,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ReassignTask>) {
  }

  //Parent workflow delegator
  public WorkflowDelegator: WorkflowDelegator;

  //Unique Modal Identifer
  public ModalIdentifier;

  //Store the autocomplete data for the assoc claim to be inserted (link type and client)
  public ReassignTask = {
    Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Employee', Client_AutoCompleteControlData: []
  };

  //Bound to the input element that contains the autocomplete, and needs to conform to the specific ControlData return type.
  public ReassignTaskClientBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

  //Use this to store the last value sent to the server. used for tracking and not firing a queued request if another one has come in its place. its not perfect, but good enough for our type ahead use case (since a user can only really type in one field at a time, this can be reused)
  private LastAutoCompleteRequestGUID: string;

  //Spinner is located on parent
  public ReassignTask_ShowSpinner() {
    if (!this.globalFunctions.isEmpty(this.WorkflowDelegator)) {
      return this.WorkflowDelegator.showReassignTaskButtonSpinner;
    }
    else {
      return false;
    }
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server (for the create option!)
  public AutoComplete_SaveSelectedClient_ForCreate(value) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {

      //Save it into the variables on the parent.
      this.WorkflowDelegator.reassignTask_Client = value.value.ControlGUID;

      //We want the parent to have the same value in the model. this is used to display in other areas, e.g. the confirm
      this.WorkflowDelegator.reassignTask_ClientBind = this.ReassignTaskClientBind;
    }
  }

  //In relation to autocomplete - read the response, return the ControlValue out of it. this allows autocomplete to show the 'pretty' value in the input box. stick on global and reuse?
  public AutoComplete_GetPrettyName(value): string {
    //console.log("AutoComplete_GetPrettyName value", value);
    if (value === null) {
      return "";
    }
    else {
      if (value.ControlValue != null) {
        return value.ControlValue;
      }
      else {
        return value;
      }
    }
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue). This is currently specific to Client type Autocomplete. Will look to make it more generic, where possible (TODO).
  public AutoComplete_Client_ApplyFilter(autocompleteRecord, requestType: string, controlValue: string, fieldType: string, seed = false) {
    //console.log('AutoComplete_Client_ApplyFilter controlValue', controlValue);
    //console.log('AutoComplete_Client_ApplyFilter autocompleteRecord', autocompleteRecord);

    //Check if we are seeding. only when the control value is blank! update the last search value if we are so that we trigger at least once. also only trigger if the Client_AutoCompleteLastSearchedValue is NOT equal to {seed}. This prevents us from seeding multiple times when there is no need (e.g. someone clicking on the field many times or pressing backspace repeatedly when there are no characters left)
    if (this.globalFunctions.isEmpty(controlValue) && seed && autocompleteRecord.Client_AutoCompleteLastSearchedValue !== "{seed}") {
      //Set the last value to something different so that we allow the seed request to proceed
      autocompleteRecord.Client_AutoCompleteLastSearchedValue = "{preseed}";
      controlValue = "{seed}";
      //console.log('seeding');
    }

    if (!this.globalFunctions.isEmpty(controlValue) && !this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteLastSearchedValue)) {
      if ((autocompleteRecord.Client_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {

        //Request new data and update the current value so we don't request it again.
        autocompleteRecord.Client_AutoCompleteLastSearchedValue = controlValue;

        //We want this to have a slight delay on key press. so that we don't trigger a server request too quickly as keys are being pressed.
        this.globalFunctions.delay(350).then(() => {

          //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
          this.LastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();
          //Make a copy of it for sending to the update request
          const copyOfGUID = JSON.parse(JSON.stringify(this.LastAutoCompleteRequestGUID));

          //Request a new set of values from the server
          this.AutoComplete_RequestData(requestType, autocompleteRecord.Client_AutoCompleteControlTypeName, controlValue, autocompleteRecord.Client_AutoCompleteControlData, fieldType, copyOfGUID);
        });
      }
    }
    //Don't place an else here, or seeding will always ask server for new data.
    //The only minor issue is that you need to press or click twice when backspacing to a blank string from the last character. not sure why it behaves this way. If you don't, it won't trigger the seed

    //Check if the control data is non null
    if (!this.globalFunctions.isEmpty(autocompleteRecord.Client_AutoCompleteControlData)) {
      //Just return the array, even though its not synchronous. the subscription will come later and update it for us.
      return autocompleteRecord.Client_AutoCompleteControlData;
    }
  }

  //Delegate back to parent for actually processing the request
  public Task_Reassign() {
    this.WorkflowDelegator.Task_Reassign();
  }

  //Close reassign task modal
  public ReassignTask_CloseModal(): void {

    //Destroy this using angular material
    this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray, fieldType: string, autoCompleteRequestGUID: string): void {
    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = this.globalFunctions.AutoComplete_PrepareAPIRequest(requestType, controlType, requestValue, fieldType);

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, apiRequest.AutoCompleteEndpoint, apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {
            //console.log('got data from server', apiResponse);

            //This helps with flow, so we only resolve the last request.
            if (autoCompleteRequestGUID === this.LastAutoCompleteRequestGUID) {

              //console.log('processing response from server');
              this.AutoComplete_ProcessResponse(apiResponse, controlArray);
            }
          }
        });
    }
  }

  //Processes the autocomplete data returned by the server and pushes it into the array
  private AutoComplete_ProcessResponse(apiResponse, controlArray): void {
    if (!this.globalFunctions.isEmpty(apiResponse)) {
      //Reset the array, then chuck the data in
      //console.log('controlArray', controlArray);

      //Dont process if the passed in control array is empty.
      if (!this.globalFunctions.isEmpty(controlArray)) {
        //Setting the length of an array to zero it the fastest way to clear an array in TS.
        controlArray.length = 0;
      }

      const results = apiResponse;
      for (const key in results) {
        const ControlDataUnit = {
          Entity: results[key].Entity,
          ControlType: results[key].ControlType,
          ControlGUID: results[key].ControlGUID,
          ControlValue: this.globalFunctions.HTMLUnescape(results[key].ControlValue),
        };
        //Push the result in to the control array by ref
        controlArray.push(ControlDataUnit);
      }
    }
    else {
      //No results! empty out the array, only when it is non empty
      if (!this.globalFunctions.isEmpty(controlArray)) {
        controlArray.length = 0;
      }
    }
  }
}