import { Component, OnInit, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationUrls, UsersControllerMethods } from '@app/Global/EnumManager';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'PasswordReset',
  templateUrl: './PasswordReset.html',
  styleUrls: ['./PasswordReset.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class PasswordReset implements OnInit {

  constructor(private globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute) {

    //init the update password data
    this.UpdatePasswordData = { ResetToken: "", LoginID: "", Password1: "", Password2: "" }
  }

  //Input fields
  @ViewChild('InputLoginID') InputLoginID
  @ViewChild('InputPassword1') InputPassword1;

  public IsUpdatingPassword = false
  public UpdatePasswordData;
  public AppName = this.globalFunctions.GetApplicationName();

  //For hiding/revealing the password
  public InputTypePassword = "password";
  public HidePassword = true;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {

      //Write this into the Reset Token
      if (!this.globalFunctions.isEmpty(params.ResetToken)) {
        this.UpdatePasswordData.ResetToken = params.ResetToken
      }
      else {
        this.apiService.Error_Go('Invalid or expired link', 'Invalid or expired link, please double check your email, or request a new password link.', 'Invalid Token');
      }
    })

    //Focus on login textbox
    this.globalFunctions.delay(1).then(() => { this.InputLoginID.nativeElement.focus(); });
  }

  public Password_Update(): void {

    //Are inputs valid?
    if (this.globalFunctions.isEmpty(this.UpdatePasswordData.LoginID)) {
      this.notifyService.Error_Show("Please check and re-enter.", "LoginID can not be empty");
      this.globalFunctions.delay(1).then(() => { this.InputLoginID.nativeElement.focus(); });
      return;
    }

    if (this.UpdatePasswordData.Password1 != this.UpdatePasswordData.Password2) {
      this.notifyService.Error_Show("Please check and re-enter.", "Passwords are not identical");
      this.globalFunctions.delay(1).then(() => { this.InputPassword1.nativeElement.focus(); });
      return;
    }

    if (this.globalFunctions.isEmpty(this.UpdatePasswordData.Password1) || this.globalFunctions.isEmpty(this.UpdatePasswordData.Password2)) {
      this.notifyService.Error_Show("Please check and re-enter.", "Passwords are missing");
      this.globalFunctions.delay(1).then(() => { this.InputPassword1.nativeElement.focus(); });
      return;
    }

    if (this.UpdatePasswordData.Password1.length < GlobalFunctions.PasswordMinLength) {
      this.notifyService.Error_Show("This password is too short, please try again.", "Too short");
      this.globalFunctions.delay(1).then(() => { this.InputPassword1.nativeElement.focus(); });
      return;
    }

    if (this.UpdatePasswordData.Password1.length > GlobalFunctions.PasswordMaxLength) {
      this.notifyService.Error_Show("This password is too long, please try again.", "Too long");
      this.globalFunctions.delay(1).then(() => { this.InputPassword1.nativeElement.focus(); });
      return;
    }

    if (this.globalFunctions.isEmpty(this.UpdatePasswordData.ResetToken) || this.UpdatePasswordData.ResetToken.length < 35) {
      this.notifyService.Error_Show("Please check your email or request a new password reset.", "Invalid Token");
      this.globalFunctions.delay(1).then(() => { this.InputPassword1.nativeElement.focus(); });
      return;
    }

    const apiRequest = this.UpdatePasswordData;
    this.IsUpdatingPassword = true;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.PasswordReset], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.IsUpdatingPassword = false;
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          //const response = JSON.parse(JSON.stringify(apiResponse));

          //Not much to do here. just a message to let the user know that they can login now. a notification might be a nice touch here
          this.notifyService.Success_Show("Password has been reset", "Password Reset");
          this.IsUpdatingPassword = false;

          //Display the password reset confirm dialog box
          const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

          //Use html content so that we can style it
          dialogRef.DialogRef.componentInstance.htmlContent = "<span class='glb_Font glb_TitleMD'>Password reset is successful.</span>";
          dialogRef.DialogRef.componentInstance.ShowNoButton = false;
          dialogRef.DialogRef.componentInstance.ShowYesButton = true;
          dialogRef.DialogRef.componentInstance.YesButtonText = "Continue to Login Page";

          //Navigate to home page after closing the dialog box
          dialogRef.DialogRef.afterClosed().subscribe(() => {
            //Going home will trigger a login
            this.Home_Go();
          });
        }
      });
  }

  //Go to the home page
  public Home_Go() {
    //Tell router to reload the component, even if the URL is the same
    this.router.navigate([NavigationUrls.Home], { onSameUrlNavigation: 'reload' });
  }

  //Reaveal/Hide the password on click and hold
  public PasswordReveal_Toggle(hidePassword: boolean): void {
    if (hidePassword == true) {
      this.InputTypePassword = "password";
      this.HidePassword = true;
    }
    else {
      this.InputTypePassword = "text";
      this.HidePassword = false;
    }
  }
}