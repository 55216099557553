<div id="PG_ResiRepaymentCalc" class="glb_formBGColor glb_paddingBottomMD glb_Font glb_Font" [ngClass]="ModalIdentifier">
  <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow sticky-top">
    <div class="glb_headerBarBGDark glb_customFlexRow col-12 justify-content-end" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="justify-content-start glb_boxedPaddingNarrow align-self-bottom glb_PaddingVerticalZeroOffset">
        <button id="BTN_MinimizeRepaymentCalc" tabindex="4"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.MinimizedDialogs_Add(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'compress-alt']" class="fa-md glb_iconColorDark" matTooltip="Click to minimize"></fa-icon>
        </button>
        <button id="BTN_CloseRepaymentCalc" tabindex="3"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'times']" class="fa-md glb_iconColorDark" matTooltip="Click to close"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="glb_customFlexRow glb_boxedPadding">
    <div class="glb_Font header-padding glb_customFlexRow">
      <div class="glb_customFlexRow col-12">
        <div class="glb_customFlexRow col-12 col-md-8 col-lg-8 glb_paddingZeroOffset glb_paddingBottomSM">
          <div class="col-12 glb_paddingZeroOffset">
            <div class="glb_TitleLG glb_TitleLGMobile text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomSM text-white">
              Residential Repayment Calculator
            </div>
          </div>
        </div>
      </div>
      <div class="glb_customFlexRow align-items-center glb_paddingBottomSM col-12">
        <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12">
          <div class="glb_TitleMD align-items-center justify-content-start text-white">Account Details</div>
        </div>
        <div
          class="glb_FontLabelDisplay glb_boxedPaddingNarrow glb_customFlexRow col-12 col-md-9 col-lg-9 glb_paddingZeroOffset text-white justify-content-start">
          <div class="glb_allBordersSkin glb_boxedPaddingNarrow glb_customFlexRow justify-content-start col-12 glb_paddingZeroOffset text-white">
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow glb_labelBottomBorderDark align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">Account ID</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{TargetAccountID}}</div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow glb_labelBottomBorderDark align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">Product Name</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{ProductName}}</div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow glb_labelBottomBorderDark align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">Settlement Date</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{SettlementDate}}</div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow glb_labelBottomBorderDark align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">Original Maturity Date</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{OriginalMaturityDate}}</div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow glb_labelBottomBorderDark align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">{{BalanceType}}</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{CurrentBalance}}</div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow glb_labelBottomBorderDark align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">Original Loan Term In Months</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{TermInMonths}}</div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow glb_labelBottomBorderDark align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">Remaining Term</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{CurrentRemainingTermsInMonthsDisplay}}</div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow glb_labelBottomBorderDark align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">Current Effective Rate</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{OriginalEffectiveRate}}</div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow glb_labelBottomBorderDarkMD align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">Current Payment Type</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{OriginalPaymentType}}</div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_boxedPaddingNarrow col-12 col-lg-6">
              <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12">
                <div class="align-items-center glb_labelClass justify-content-start col-6">Current Monthly Repayment Amount</div>
                <div class="align-items-center justify-content-end glb_customFlexRow col-6">{{CurrentRepaymentAmount}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="glb_Font col-12 header-padding glb_customFlexRow glb_paddingBottomSM">

        <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12">
          <div class="align-items-center justify-content-start text-white glb_TitleMD">Repayment Calculator Inputs</div>
        </div>

        <div class="glb_boxedPaddingNarrow glb_customFlexRow justify-content-start col-12 glb_paddingZeroOffset">

          <!-- Repayment Calc Input Fields-->
          <div [ngClass]="globalFunctions.L1ParentSectionCSS">
            <div class="glb_flexGrow">
              <div [ngClass]="globalFunctions.L1SectionCSS">

                <!-- Balance -->
                <div [ngClass]="globalFunctions.Col3CSS">
                  <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_Balance'" [ShowClearButton]="false">
                  </FormInputDataUnit>
                </div>

                <!-- Payment Type -->
                <div [ngClass]="globalFunctions.Col3CSS">
                  <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_PaymentType'"
                    [DropdownOptions]="PaymentTypes" [ShowClearButton]="false">
                  </FormInputDataUnit>
                </div>

                <!-- Effective Rate -->
                <div [ngClass]="globalFunctions.Col3CSS">
                  <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_EffectiveRate'"
                    [ShowClearButton]="false">
                  </FormInputDataUnit>
                </div>

                <!-- Effective date -->
                <div [ngClass]="globalFunctions.Col3CSS">

                  <!-- Primeng Calendar Date Picker -->
                  <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_EffectiveDate'" [DTPMaxDate]="DTPMaxDate"
                    [DTPMinDate]="DTPMinDate" [ShowClearButton]="false">
                  </FormInputDataUnit>
                </div>

                <!-- Maturity date -->
                <div [ngClass]="globalFunctions.Col3CSS">

                  <!-- Primeng Calendar Date Picker -->
                  <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_MaturityDate'" [DTPMaxDate]="DTPMaxDate"
                    [DTPMinDate]="DTPMinDate" [ShowClearButton]="false">
                  </FormInputDataUnit>
                </div>

                <!-- Remaining Term In Months -->
                <div [ngClass]="globalFunctions.Col3CSS">
                  <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_RemainingTermInMonths'"
                    [ShowClearButton]="false">
                  </FormInputDataUnit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="glb_customFlexRow glb_boxedPaddingNarrow col-9 justify-content-end">
        <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-6">
          <div class="align-items-center justify-content-start glb_TitleMD text-white">Indicative Repayment Amounts</div>
        </div>
        <div class="glb_FontLabelDisplay glb_boxedPaddingNarrow glb_customFlexRow col-12 glb_paddingZeroOffset text-white justify-content-end">
          <div
            class="glb_allBordersSkin glb_boxedPaddingNarrow glb_customFlexRow justify-content-end col-12 col-md-6 col-lg-6 glb_paddingZeroOffset text-white">
            <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12">
              <div class="align-items-center justify-content-start glb_labelClass col-8">Monthly</div>
              <div class="align-items-center justify-content-end glb_customFlexRow col-4" id="EL_MonthlyRepayment">{{RepaymentAmountMonthly}}</div>
            </div>
            <div *ngIf="!IsIOPaymentType" class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12">
              <div class="align-items-center justify-content-start glb_labelClass col-8">Fortnightly</div>
              <div class="align-items-center justify-content-end glb_customFlexRow col-4" id="EL_FortnightlyRepayment">{{RepaymentAmountFortnightly}}
              </div>
            </div>
            <div *ngIf="!IsIOPaymentType" class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12">
              <div class="align-items-center justify-content-start glb_labelClass col-8">Weekly</div>
              <div class="align-items-center justify-content-end glb_customFlexRow col-4" id="EL_WeeklyRepayment">{{RepaymentAmountWeekly}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="glb_Font header-padding glb_customFlexRow glb_paddingBottomMD col-12">
      <div
        class="glb_boxedPaddingNarrow glb_paddingZeroOffset align-items-start glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomLG col-12">
        <div class="col-12 col-md-9 col-lg-9 glb_paddingZeroOffset justify-content-end glb_customFlexRow">
          <div class="glb_customFlexRow row-cols-1 col-2 justify-content-center align-items-center">
            <div *ngIf="ShowSaveNote" class="d-grid glb_paddingRight">
              <button id="BTN_SaveNoteRepaymentCalc" tabindex="2" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="Repayment_SaveNote()" matTooltip="Click to save repayment journal note" [disabled]=InputsEnabled>
                <span class="glb_Font glb_smallerText">Save Repayment</span></button>
            </div>
          </div>
          <div *ngIf="InputsEnabled" class="glb_customFlexRow row-cols-1 col-2 justify-content-center align-items-center">
            <div *ngIf="!IsGenerateInProgress" class="d-grid">
              <button id="BTN_CalculateRepaymentCalc" tabindex="2" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="Repayment_Calculate()" matTooltip="Click to calculate repayment amount">
                <span class="glb_Font glb_smallerText">Calculate</span></button>
            </div>
            <div *ngIf="IsGenerateInProgress" class="d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Calculate</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
          <div *ngIf="!InputsEnabled" class="glb_customFlexRow row-cols-1 col-2 justify-content-center align-items-center">
            <div *ngIf="!IsGenerateInProgress" class="d-grid">
              <button id="BTN_ModifyInputs" tabindex="3" class="glb_standardButton glb_skinLightOutline btn glb_BGColorClear text-white"
                (click)="InputsEnabled_Toggle(true)" matTooltip="Click to modify the inputs">
                <span class="glb_Font glb_smallerText">Modify</span></button>
            </div>
            <div *ngIf="IsGenerateInProgress" class="d-grid">
              <button class="glb_standardButton btn glb_BGColorClear text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Modify</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Amortisation Chart - Line chart-->
    <div *ngIf="ShowChart" class="glb_customFlexRow glb_boxedPaddingNarrow col-lg-9 col-12 justify-content-start chartBGDark">
      <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-lg-6 col-12">
        <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12">
          <div class="col-12 glb_customFlexRow">
            <div class="col-6 glb_customFlexRow align-items-center justify-content-start glb_TitleMD text-white">P&I Amortisation Schedule</div>
            <div class="col-6 justify-content-end align-items-center glb_customFlexRow">
              <span>
                <button id="BTN_DownloadAmortisationData"
                  class="glb_clickableIconButtonStyle glb_clickableIconButtonColorLight glb_clickableIconButtonMD align-self-right"
                  (click)="Amortisation_DownloadCSV()">
                  <fa-icon class="fa-fw glb_fixedSizeIcons" [icon]="['fal', 'download']" matTooltip="Click to Download">
                  </fa-icon>
                </button>
              </span>
            </div>
          </div>
          <div class="chartHeight glb_paddingBottomMD glb_customFlexRow col-12">
            <canvas #ChartID [(id)]="ChartIdentifier"></canvas>
          </div>
        </div>
      </div>
      <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-lg-6 col-12">
        <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12 ">
          <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12">
            <div class="align-items-center justify-content-start glb_TitleMD text-white">Repayment Summary</div>
          </div>
          <div class=" glb_FontLabelDisplay glb_boxedPaddingNarrow glb_customFlexRow col-12 glb_paddingZeroOffset text-white justify-content-end">
            <div class="glb_boxedPaddingNarrow col-12 text-white">
              <div class="glb_allBordersSkin glb_Font col-12 glb_customFlexRow glb_PaddingHorizontalMD">
                <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_boxedPaddingNarrow">
                  <div class="glb_thinBottomWhiteBorder glb_customFlexRow glb_boxedPaddingNarrow col-12 glb_paddingZeroOffset row-cols-4">
                    <div class="glb_customFlexRow align-items-center">
                      <div></div>
                    </div>
                    <div class="glb_customFlexRow align-items-center justify-content-end">
                      <div>Total Interest Charged</div>
                    </div>
                    <div class="glb_customFlexRow align-items-center justify-content-end">
                      <div>Total Repayment</div>
                    </div>
                    <div class="glb_customFlexRow align-items-center justify-content-end">
                      <div>Term</div>
                    </div>
                  </div>
                </div>
                <div class="col-12 glb_customFlexRow glb_paddingZeroOffset">
                  <div class="glb_customFlexRow glb_paddingZeroOffset glb_boxedPaddingNarrow col-12 row-cols-4" [ngClass]="UnderlineCSS_Get()">
                    <div class="glb_customFlexRow align-items-center justify-content-start">
                      <div class="glb_labelClass">Min. Repayment</div>
                    </div>
                    <div class="glb_customFlexRow align-items-center justify-content-end">
                      <div id="EL_TotalInterestCharged">{{TotalInterestChargedDisplay}}</div>
                    </div>
                    <div class="glb_customFlexRow align-items-center justify-content-end">
                      <div id="EL_TotalRepayment">{{TotalRepaymentsDisplay}}</div>
                    </div>
                    <div class="glb_customFlexRow align-items-center justify-content-end">
                      <div id="EL_RemainingTerm">{{RemainingTermInMonthsDisplay}}</div>
                    </div>
                  </div>
                </div>
                <div *ngIf="ExtraMonthlyRepayment>0" class="col-12 glb_customFlexRow">
                  <div class="col-12 glb_customFlexRow glb_paddingZeroOffset">
                    <div class="glb_customFlexRow glb_paddingZeroOffset glb_boxedPaddingNarrow col-12 row-cols-4 glb_labelBottomBorderDark">
                      <div class="glb_customFlexRow align-items-center justify-content-start">
                        <div class="glb_labelClass">Min. Repayment + Extra</div>
                      </div>
                      <div class="glb_customFlexRow align-items-center justify-content-end">
                        <div id="EL_TotalInterestChargedWithExtra">{{TotalInterestChargedWithExtraRepaymentDisplay}}</div>
                      </div>
                      <div class="glb_customFlexRow align-items-center justify-content-end">
                        <div id="EL_TotalRepaymentsWithExtra">{{TotalRepaymentsWithExtraRepaymentDisplay}}</div>
                      </div>
                      <div class="glb_customFlexRow align-items-center justify-content-end">
                        <div id="EL_RemainingTermInMonthsWithExtra">{{RemainingTermInMonthsWithExtraRepaymentDisplay}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 glb_customFlexRow glb_paddingZeroOffset">
                    <div class="glb_customFlexRow glb_paddingZeroOffset glb_boxedPaddingNarrow col-12 row-cols-4">
                      <div class="glb_customFlexRow align-items-center justify-content-start">
                        <div class="glb_labelClass">Savings with Extra</div>
                      </div>
                      <div class="glb_customFlexRow align-items-center justify-content-end">
                        <div>- {{TotalInterestSavingsWithExtraRepaymentDisplay}}</div>
                      </div>
                      <div class="glb_customFlexRow align-items-center justify-content-end">
                        <div></div>
                      </div>
                      <div class="glb_customFlexRow align-items-center justify-content-end">
                        <div>- {{LoanTermReductionWithExtraRepaymentDisplay}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="glb_boxedPaddingNarrow glb_customFlexRow justify-content-start col-12 glb_paddingZeroOffset">

              <div class="col-12 glb_customFlexRow glb_PaddingVerticalSM">
                <div class="col-12 col-lg-6 glb_customFlexRow">

                  <!-- Extra Monthly Repayment Input Field-->
                  <div class="glb_flexGrow">

                    <!-- Monthly Extra Repayment -->
                    <div [ngClass]="globalFunctions.Col2CSS">
                      <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_ExtraMonthlyRepayment'"
                        [ShowClearButton]="false">
                      </FormInputDataUnit>
                    </div>
                  </div>
                </div>
                <div *ngIf="!ExtraRepaymentInputEnabled" class="col-12 col-lg-6 glb_customFlexRow">
                  <div class="glb_customFlexRow glb_PaddingVerticalSM col-12 justify-content-end">
                    <div class="glb_FontLabelDisplay glb_customFlexRow col-12 glb_paddingZeroOffset text-white justify-content-end">
                      <div
                        class="glb_allBordersSkin glb_boxedPaddingNarrow glb_customFlexRow justify-content-end col-12 glb_paddingZeroOffset text-white">
                        <div class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow col-12">
                          <div class="align-items-center justify-content-start glb_labelClass col-8">Monthly + Extra</div>
                          <div class="align-items-center justify-content-end glb_customFlexRow col-4" id="EL_MonthlyPlusExtraRepayment">
                            {{RepaymentAmountPlusExtraMonthly}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-9 col-lg-9 justify-content-start glb_customFlexRow">
                <div *ngIf="ExtraRepaymentInputEnabled"
                  class="glb_customFlexRow row-cols-1 col-12 col-md-4 col-lg-4 justify-content-center align-items-center">
                  <div *ngIf="!IsGenerateInProgress" class="d-grid">
                    <button id="BTN_CalculateWithExtraRepayment" tabindex="2"
                      class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white" (click)="RepaymentWithExtra_Calculate()"
                      matTooltip="Click to calculate repayment with extra monthly repayment amount">
                      <span class="glb_Font glb_smallerText">Update</span></button>
                  </div>
                  <div *ngIf="IsGenerateInProgress" class="d-grid">
                    <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                      <span class="glb_Font glb_hiddenText glb_smallerText">Update</span>
                      <div class="spinner-border glb_floatingSpinner"></div>
                    </button>
                  </div>
                </div>
                <div *ngIf="!ExtraRepaymentInputEnabled"
                  class="glb_customFlexRow row-cols-1 col-12 col-md-4 col-lg-4 justify-content-center align-items-center">
                  <div *ngIf="!IsGenerateInProgress" class="d-grid">
                    <button id="BTN_ModifyExtraRepayment" tabindex="3" class="glb_standardButton glb_skinLightOutline btn glb_BGColorClear text-white"
                      (click)="ExtraRepaymentInputEnabledToggle_Sync(true, true)" matTooltip="Click to modify the extra monthly repayment amount">
                      <span class="glb_Font glb_smallerText">Modify</span></button>
                  </div>
                  <div *ngIf="IsGenerateInProgress" class="d-grid">
                    <button class="glb_standardButton btn glb_BGColorClear text-white glb_positionRelative">
                      <span class="glb_Font glb_hiddenText glb_smallerText">Modify</span>
                      <div class="spinner-border glb_floatingSpinner"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>