<div id="PG_TaskQualityReview" class="glb_formBGColor glb_boxedPadding">
    <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_customFlexRow justify-content-end">
        <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
            <button id="TaskQuality_Close" [disabled]="IsUpdatingFeedback" tabindex="15"
                class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
                (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
                <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
            </button>
        </div>
    </div>
    <div class="glb_Font header-padding glb_customFlexRow">
        <div class="glb_customFlexRow col-12 glb_paddingZeroOffset" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <div class="col-8 glb_paddingZeroOffset">
                <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
                    Quality Checking Feedback
                </div>
            </div>
        </div>
    </div>
    <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center" style="padding-bottom: 6px;">
        <div class="col-11 " style="padding-left:0px; padding-right:0px;">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white" style="font-size: .80em;">QC Feedback</div>
            <textarea id="INP_Feedback" class="textAreaCustom glb_Font textAreaHeightLarge" style="width: 100%; white-space: pre-wrap;"
                placeholder="Enter your feedback" [(ngModel)]="Feedback" (keydown)="HasFeedback_Sync()" (keyup)="HasFeedback_Sync()">
                  </textarea>
        </div>

        <!-- Button to clear content inside the input box, spans a div of its own -->
        <div class="col-1 justify-content-start align-self-end" style="padding-bottom: 4px; padding-left: 8px;">
            <span>
                <button tabindex="-1"
                    class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                    matTooltip="Click to Clear" (click)="Feedback_ClearData()">
                    <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </button>
            </span>
        </div>

    </div>
    <br>
    <div class="glb_paddingTopSM glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div *ngIf="HasFeedback" class="glb_paddingBottomSM d-grid">
            <button id="BTN_QCFailed" tabindex="13" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="AddFeedback_Click()">
                <span class="glb_Font glb_smallerText col-12 glb_customFlexRow align-items-center justify-content-center">Complete with QC
                    Feedback
                    <fa-icon [icon]="['fal', 'circle-info']" class="buttonIcon glb_infoBG fa-fw glb_fixedSizeIcons"></fa-icon>
                </span>
            </button>
        </div>
        <div *ngIf="!HasFeedback" class="glb_paddingBottomSM d-grid">
            <button id="BTN_QCPassed" tabindex="13" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="AddFeedback_Click()">
                <span class="glb_Font glb_smallerText col-12 glb_customFlexRow align-items-center justify-content-center">Complete - QC Passed
                    <fa-icon [icon]="['fal', 'badge-check']" class="buttonIcon glb_successBG fa-fw glb_fixedSizeIcons"></fa-icon>
                </span>
            </button>
        </div>

    </div>
</div>