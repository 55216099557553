<div id="PG_ResiPayout" class="glb_formBGColor glb_paddingBottomMD glb_Font glb_Font" [ngClass]="ModalIdentifier">

  <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
  <div class="glb_customFlexRow">
    <div class="glb_headerBarBGDark glb_customFlexRow justify-content-end col-12" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
      <div class="justify-content-start glb_boxedPaddingNarrow align-self-bottom glb_PaddingVerticalZeroOffset">
        <button id="BTN_MinimizeResiPayout" tabindex="4"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.MinimizedDialogs_Add(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'compress-alt']" class="fa-md glb_iconColorDark" matTooltip="Click to minimize"></fa-icon>
        </button>
        <button id="BTN_CloseResiPayout" tabindex="3"
          class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
          (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
          <fa-icon [icon]="['fal', 'times']" class="fa-md glb_iconColorDark"></fa-icon>
        </button>
      </div>
    </div>
  </div>
  <div class="glb_customFlexRow glb_boxedPadding">
    <div class="glb_Font header-padding glb_customFlexRow">
      <div class="glb_customFlexRow col-12">
        <div class="glb_customFlexRow col-8 glb_paddingZeroOffset">
          <div class="col-8 glb_paddingZeroOffset">
            <div class="glb_TitleLG glb_TitleLGMobile text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomSM text-white">
              Residential Payout Calculator
            </div>
          </div>
        </div>
      </div>
      <div class="glb_Font col-12 header-padding glb_customFlexRow glb_paddingBottomSM">

        <!-- Payout Date and Discharge Type (Mandatory Fields)-->
        <div [ngClass]="globalFunctions.L1ParentSectionCSS">
          <div class="glb_flexGrow">
            <div [ngClass]="globalFunctions.L1SectionCSS">

              <!-- Payout date -->
              <div [ngClass]="globalFunctions.Col3CSS">

                <!-- Primeng Calendar Date Picker -->
                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_PayoutDate'" [DTPMaxDate]="DTPMaxDate"
                  [DTPMinDate]="DTPMinDate">
                </FormInputDataUnit>
              </div>

              <!-- Discharge Type -->
              <div [ngClass]="globalFunctions.Col3CSS">

                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_DischargeType'"
                  [DropdownOptions]="DischargeTypes">
                </FormInputDataUnit>
              </div>

              <!-- Client Selection -->
              <div [ngClass]="globalFunctions.Col3CSS">

                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_MultiselectSingle'"
                  [MultiselectSingleOptions]="ClientMultiselectOptions">
                </FormInputDataUnit>
              </div>
            </div>

            <!-- Show/hide optional field checkbox -->
            <div [ngClass]="globalFunctions.L1SectionCSS">

              <!-- Show/hide optional field checkbox -->
              <div [ngClass]="globalFunctions.FlexCSS">

                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_ShowOptionalFields'">
                </FormInputDataUnit>
              </div>
            </div>

            <!-- Discharge Reason and Note-->
            <div @fadeInSection *ngIf="ShowOptionalFields" [ngClass]="globalFunctions.L1SectionCSSSM">

              <!-- Discharge Note -->
              <div [ngClass]="globalFunctions.FlexCSS">

                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_DischargeReason'"
                  [AutoCompleteControlTypeName]="'Discharge Reason'" [AccountID]="LoanIndex.AccountID">
                </FormInputDataUnit>
              </div>

            </div>

            <!-- Discharge Reason and Note-->
            <div @fadeInSection *ngIf="ShowOptionalFields" [ngClass]="globalFunctions.L1SectionCSSSM">

              <!-- Discharge Note -->
              <div [ngClass]="globalFunctions.FlexCSS">

                <FormInputDataUnit [ngClass]="globalFunctions.FlexCSS" [ParentComponent]="this" [ID]="'INP_DischargeNote'"></FormInputDataUnit>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="glb_boxedPaddingNarrow col-12 text-white">
        <div class="glb_allBordersSkin glb_Font col-12 glb_customFlexRow glb_PaddingHorizontalMD">
          <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM">
            <div class="glb_thinBottomWhiteBorder glb_customFlexRow col-12 glb_paddingZeroOffset row-cols-11">
              <div class="glb_customFlexRow align-items-center">
                <div>Account ID</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Collateralised ID</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Rate Type</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Discharge Date</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Principal Amount</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Interest Accrued</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Default Interest Accrued</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Uncleared Funds</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Offset Balance</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>Fees</div>
              </div>
              <div class="glb_customFlexRow align-items-center justify-content-end">
                <div>Total Payout</div>
              </div>
            </div>
          </div>
          <div *ngIf="!NoPayouts" class="col-12 glb_customFlexRow">
            <div *ngFor="let item of PayoutCalculations" class="col-12 glb_customFlexRow glb_paddingZeroOffset">
              <div class="glb_customFlexRow glb_paddingZeroOffset glb_paddingBottomSM col-12 row-cols-11" [ngClass]="AccountRowCSS_Get(item)">

                <div class="glb_customFlexRow align-items-center">
                  <div class="col-2 glb_customFlexRow justify-content-left align-items-center" [matTooltip]="item.ToolTipText"
                    matTooltipPosition="below" [matTooltipShowDelay]="ToolTipDelay">
                    <mat-checkbox class="glb_Font mat-checkbox-light-custom" [checked]="item.IsIncluded" (change)="IncludeCheckbox_Click(item)">
                    </mat-checkbox>
                  </div>
                  <div class="col-10">{{item.AccountID}}</div>
                </div>
                <div class="glb_customFlexRow align-items-center">
                  <div>{{item.CrossCollateralisedID}}</div>
                </div>
                <div class="glb_customFlexRow align-items-center">
                  <div>{{item.RateType}}</div>
                </div>
                <div class="glb_customFlexRow align-items-center">
                  <div>{{item.DischargeDate_FMT}}</div>
                </div>
                <div class="glb_customFlexRow align-items-center">
                  <div>{{item.PrincipalAmount_FMT}}</div>
                </div>
                <div class="glb_customFlexRow align-items-center">
                  <div>{{item.InterestAccrued_FMT}}</div>
                </div>
                <div class="glb_customFlexRow align-items-center">
                  <div>{{item.DefaultInterestAccrued_FMT}}</div>
                </div>
                <div class="glb_customFlexRow align-items-center">
                  <div>{{item.UnclearedFunds_FMT}}</div>
                </div>
                <div class="glb_customFlexRow align-items-center">
                  <div>{{item.OffsetBalance_FMT}}</div>
                </div>
                <div class="glb_customFlexRow align-items-center">
                  <div>{{item.Fees_FMT}}
                    <button id="BTN_ShowFeeDetails" class="glb_clickableIconButtonMD align-self-right" [ngClass]="FeeIconCSS_Get(item)">
                      <fa-icon [icon]="['fal', 'magnifying-glass']" class="fa-fw glb_fixedSizeIcons IconColorDark" matTooltip="View fee details"
                        matTooltipPosition="right" (click)="AccountFee_Launch(item)" [matTooltipDisabled]="!item.IsIncluded">
                      </fa-icon>
                    </button>
                  </div>
                </div>
                <div class="glb_customFlexRow align-items-center justify-content-end">
                  <div>{{item.TotalPayout_FMT}}</div>
                </div>
              </div>
            </div>
            <div class="glb_customFlexRow glb_thinTopWhiteBorder col-12 glb_PaddingVerticalSM row-cols-11 glb_TitleMD">
              <div class="glb_customFlexRow align-items-center">
                <div>Totals</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div></div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div></div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div></div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{TotalPrincipalDisplay}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{TotalInterestAccruedDisplay}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{TotalDefaultInterestAccruedDisplay}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{TotalUnclearedFundsDisplay}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{TotalOffsetBalanceDisplay}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center">
                <div>{{TotalFeesDisplay}}</div>
              </div>
              <div class="glb_customFlexRow align-items-center justify-content-end">
                <div>{{TotalFacilityPayoutDisplay}}</div>
              </div>
            </div>
          </div>
          <div *ngIf="NoPayouts" class="glb_customFlexRow align-items-center glb_boxedPaddingNarrow">
            <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-start glb_TitleMD glb_importantTextMD">No active
              accounts
            </div>
          </div>
        </div>
        <div *ngIf="!NoPayouts" class="glb_PaddingVerticalSM glb_customFlexRow justify-content-end col-12">
          <div class="glb_allBordersSkin glb_Font col-12 col-lg-6 col-xl-6 col-xxl-3 glb_customFlexRow glb_PaddingHorizontalMD">
            <div class="glb_customFlexRow col-12 glb_paddingZeroOffset glb_paddingBottomSM glb_paddingTopSM row-cols-2 glb_TitleMD">
              <div class="glb_customFlexRow align-items-center col-9 glb_paddingZeroOffset justify-content-start">
                <div>Total Facility Payout</div>
              </div>
              <div class="glb_customFlexRow align-items-center col-3 glb_paddingZeroOffset justify-content-end glb_bold">
                <div>{{TotalFacilityPayoutDisplay}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="glb_Font header-padding glb_customFlexRow col-12">
      <div
        class="glb_boxedPaddingNarrow glb_paddingZeroOffset align-items-start glb_customFlexRow row-cols-1 justify-content-left glb_paddingBottomLG col-12">
        <div class="col-12 glb_paddingZeroOffset justify-content-end glb_customFlexRow">
          <div *ngIf="!EnableButtons && !NoPayouts" class="glb_boxedPaddingNarrow">
            <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-start glb_TitleMD glb_importantTextSM">
              {{EnableButtonsMessage}}
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 glb_paddingZeroOffset justify-content-start glb_customFlexRow">
          <div *ngIf="ShowFixedProductMessage" class="glb_boxedPaddingNarrow">
            <div class="glb_customFlexRow row-cols-1 col-12 glb_paddingZeroOffset justify-content-start glb_TitleMD glb_importantTextSM">
              {{FixedProductMessage}}
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-6 glb_paddingZeroOffset justify-content-end glb_customFlexRow">
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_PaddingHorizontalSM">
            <div *ngIf="!IsGenerateInProgress && !NoPayouts" class="d-grid">
              <button id="DownloadPayoutCalculationsID" tabindex="1" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="PayoutCalculations_DownloadCSV()" matTooltip="Click to export in csv format">
                <span class="glb_Font glb_smallerText">Download</span></button>
            </div>
            <div *ngIf="IsGenerateInProgress" class="d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Download</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center glb_PaddingHorizontalSM">
            <div *ngIf="!IsGenerateInProgress && !NoPayouts" class="d-grid">
              <button id="BTN_GeneratePayoutLetter" tabindex="2" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="PayoutLetter_Generate()" [disabled]="!EnableButtons" matTooltip="Click to generate a payout letter">
                <span class="glb_Font glb_smallerText">Generate Letter</span></button>
            </div>
            <div *ngIf="IsGenerateInProgress" class="d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Generate Letter</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
          <div class="glb_customFlexRow row-cols-1 justify-content-center align-items-center">
            <div *ngIf="!IsGenerateInProgress && !NoPayouts" class="d-grid">
              <button id="BTN_SavePayoutNote" tabindex="3" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="PayoutCalculation_Save()" [disabled]="!EnableButtons" matTooltip="Click to save above payout details as a journal note">
                <span class="glb_Font glb_smallerText">Save Payout Note</span></button>
            </div>
            <div *ngIf="IsGenerateInProgress" class="d-grid">
              <button class="glb_standardButton btn glb_BGColorSkin text-white glb_positionRelative">
                <span class="glb_Font glb_hiddenText glb_smallerText">Save Payout Note</span>
                <div class="spinner-border glb_floatingSpinner"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>