import { Component } from '@angular/core';

//This wrapper exists to avoid the circular dependency between GlobalFunctions and LoginModal. it renders the LoginModal inside it directly. luckily, we can also send input parameters in too! like the htmlContent
@Component({
  selector: 'LoginModalWrapper',

  //Use a direct template so that we don't ahve to import LoginModal. this is what alows us to avoid the circular dependencies.
  template: '<LoginModal [SuppliedhtmlContent]="HtmlContent" [SuppliedAllowClose]="AllowClose" [ModalIdentifier]="ModalIdentifier"></LoginModal>'
})

export class LoginModalWrapper {

  //Use this to pass as input down to the LoginModal (used by the call in GlobalFunctions to construct a LoginModal)
  HtmlContent: string;

  //Should we allow this to be force closed? (close icon at top right)
  AllowClose = false

  //Unique modal identifier
  public ModalIdentifier;
}