import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { LoanIndex } from '@app/Components/Loan/LoanIndex/LoanIndex';
import { ApiService } from '@app/Services/APIService';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { NotifyService } from '@app/Services/NotifyService';
import { animate, style, transition, trigger } from '@angular/animations';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { InputDataUnit } from '@app/Global/Models/ClientModels';
import { AccountsControllerMethods } from '@app/Global/EnumManager';

@Component({
  selector: 'FinaliseTask',
  templateUrl: './FinaliseTask.html',
  styleUrls: ['./FinaliseTask.scss'],
  animations: [
    //Using a longer fade in here for the child elements that get rendered into the page when a new section is added
    trigger('fadeInSection', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1.0s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class FinaliseTask implements OnInit {

  //The DataRow that we contains all the dataunits on the client side that we are targeting, including the TaskGUID
  public DataRow;

  //New ModelData that captures it inside an array, which lets us use a generic function to retrieve and update
  public ModelData: InputDataUnit[] = [];

  //Task Outcome Options
  public TaskOutcomeOptions = [{ ControlGUID: "Satisfied", ControlValue: "Satisfied" }, { ControlGUID: "Not Satisfied", ControlValue: "Not Satisfied" }, { ControlGUID: "Waived", ControlValue: "Waived" }];

  //Flags to show revelant UI sections as they get revealed
  public ShowApprovedBy = false;
  public ShowResultandComments = false;
  public ShowDefaultEventYesText = false;
  public ShowDefaultEventNoText = false;
  public ShowSubmitButton = false;
  public ShowAddNewValuationButton = false;

  //TODO: Show section flag names
  public readonly ShowFileUploadQuestion = 'ShowFileUploadQuestion';
  public readonly ShowFileUploadSection = 'ShowFileUploadSection';
  public readonly ShowLoanCovenantQuestion = 'ShowLoanCovenantQuestion';
  public readonly ShowLoanCovenantSection = 'ShowLoanCovenantSection';
  public readonly ShowSecurityValuationQuestion = 'ShowSecurityValuationQuestion';
  public readonly ShowSecurityValuationSection = 'ShowSecurityValuationSection';
  public readonly ShowDefaultEventQuestion = 'ShowDefaultEventQuestion';
  public readonly ShowDefaultEventSection = 'ShowDefaultEventSection';

  //Unique Modal Identifer
  public ModalIdentifier;

  //Lookup objects
  public ShowSectionsLookup = { ShowFileUploadQuestion: false, ShowFileUploadSection: false, ShowLoanCovenantQuestion: false, ShowSecurityValuationQuestion: false, ShowSecurityValuationSection: false, ShowLoanCovenantSection: false, ShowDefaultEventQuestion: false, ShowDefaultEventSection: false };
  public CSSLookup = { L1SectionCSS: 'col-12 glb_customFlexRow glb_PaddingVerticalSM', FlexCSS: 'col-12 glb_customFlexRow' };

  //DateTime max date
  public DTPMaxDate = new Date(2050, 1, 1);

  //Are we in a "in progress' state of saving changes onto the server
  public IsFinalisingTask = false;

  //We need a copy of LoanIndex to help construct the request to the server
  public LoanIndex: LoanIndex;

  //Global hide and fade in Css
  public HiddenImmediateCSS = "glb_hiddenObjectImmediate";
  public FadeInLGCSS = "glb_keyFrameFadeIn_LG";

  //Tracking array for loan securities for inserting valuations
  public Securities = [];

  //Security Valuation counter to keep track when a user adds valuations
  public SecurityValCounter = 0;

  constructor(
    public dialogRef: MatDialogRef<FinaliseTask>,
    private apiService: ApiService,
    private clientDataStore: ClientDataStore,
    private notifyService: NotifyService,
    private dialog: MatDialog,
    public globalFunctions: GlobalFunctions) {

    //Fill static data for the input data model here, base it on the input names. Demonstrating the use of the partial constructor here, only mapping the properties we want to use
    this.ModelData.push(
      new InputDataUnit({ Name: "INP_ApprovedBy", DisplayName: "Approved By", Placeholder: "Approved By", HTMLInputType: "textarea", Type: "string", MinLength: this.globalFunctions.TextAreaValidLengthXS, MaxLength: this.globalFunctions.TextAreaValidMaxLengthXS, DataFieldGUID: "FB898EEE-B8E5-4E31-B2BC-63EE500329FA", DirectOwnerEntityTypeGUID: "25A62728-6B06-47CB-BC40-68EE6F2CC823" })
      , new InputDataUnit({ Name: "INP_Result", DisplayName: "Result and Comments", Placeholder: "Enter your Result and Comments", HTMLInputType: "textarea", Type: "string", MinLength: this.globalFunctions.TextAreaValidLengthSM, MaxLength: this.globalFunctions.TextAreaValidMaxLengthLG, IsResizable: true, CSSHeight: "glb_textAreaHeightLarge", ClearConfirm: true })
      , new InputDataUnit({ Name: "INP_Outcome", DisplayName: "Outcome", Placeholder: "Choose an Outcome", HTMLInputType: "dropdown", Type: "string" })
      , new InputDataUnit({ Name: "INP_ApprovedLVR", DisplayName: "Approved LVR", Placeholder: "Approved LVR", HTMLInputType: "textarea", Type: "percent", MinValue: this.globalFunctions.TextAreaValidMinNumeric, MaxValue: this.globalFunctions.TextAreaValidMaxPercentage, DataFieldGUID: "146C1C8E-CD76-4516-9457-C814138764EB" })
      , new InputDataUnit({ Name: "INP_DSCR", DisplayName: "Debt Service Coverage Ratio (DSCR)", Placeholder: "Debt Service Coverage Ratio", HTMLInputType: "textarea", Type: "percent", MinValue: this.globalFunctions.TextAreaValidMinNumeric, MaxValue: this.globalFunctions.TextAreaValidMaxPercentage, DataFieldGUID: "714DAE88-D8E8-403E-833F-BD288C24AAFE" })
      , new InputDataUnit({ Name: "INP_ICR", DisplayName: "Interest Coverage Ratio (ICR)", Placeholder: "Interest Coverage Ratio", HTMLInputType: "textarea", Type: "percent", MinValue: this.globalFunctions.TextAreaValidMinNumeric, MaxValue: this.globalFunctions.TextAreaValidMaxPercentage, DataFieldGUID: "B9FB82E2-290A-4892-BC8A-17C44F0B762C" })
      , new InputDataUnit({ Name: "INP_EBITDA", DisplayName: "EBITDA", Placeholder: "EBITDA", HTMLInputType: "textarea", Type: "currency", MinValue: this.globalFunctions.TextAreaValidMinNumeric, MaxValue: this.globalFunctions.TextAreaValidMaxNumeric, DataFieldGUID: "711F6633-1600-41D1-B7A5-DEB58E526354" })
      , new InputDataUnit({ Name: "INP_DateDeclared", DisplayName: "Date Declared", Placeholder: "Date Declared", HTMLInputType: "date", Type: "shortdate", DataFieldGUID: "4C631817-42E1-4457-AD5C-E8CC17B6BC1A", DirectOwnerEntityTypeGUID: "25A62728-6B06-47CB-BC40-68EE6F2CC823" })

      , new InputDataUnit({ Name: "INP_ShowLoanCovenantOption", Question: "Do you need to record loan covenant data obtained through this condition?", HTMLInputType: "yesno", Type: "string" })
      , new InputDataUnit({ Name: "INP_ShowSecurityValuationOption", Question: "Do you want to add a valuation?", HTMLInputType: "yesno", Type: "string" })
      , new InputDataUnit({ Name: "INP_ShowDefaultEventOption", Question: "Does this trigger an event of default?", HTMLInputType: "yesno", Type: "string" })
      , new InputDataUnit({ Name: "INP_ShowFileUploadOption", Question: "Do you want to attach a file?", HTMLInputType: "yesno", Type: "string" })
      , new InputDataUnit({ Name: "INP_DocumentCategory", DisplayName: "Document Category", Placeholder: "Choose a document category", HTMLInputType: "dropdown", Type: "string" })
      , new InputDataUnit({ Name: "INP_FileUpload", DisplayName: "Choose a file", HTMLInputType: "p-fileupload", Type: "string" })
    );

    //Add security valuation section
    this.SecurityValuation_Add();
  }

  //Adds security with linked valuation fields. Can be called multiple times to support adding valuations for multiple securities
  public SecurityValuation_Add(): void {

    //To set the index of the security and linked valuations
    const counter = this.SecurityValCounter;

    //Insert parent security field
    const securityInput = new InputDataUnit({ Name: "INP_Security_" + counter, DisplayName: "Security", Placeholder: "Enter a value to search", HTMLInputType: "autocomplete", Type: "string" });

    //Push the security into a tracking array
    this.Securities.push({ GUID: securityInput.GUID, Index: counter });

    //Push the parent and linked fields into ModelData
    this.ModelData.push(
      securityInput
      , new InputDataUnit({ Name: "INP_ValuationAmount_" + counter, DisplayName: "Valuation Amount (mandatory)", Placeholder: "Valuation Amount", HTMLInputType: "textarea", ParentGUID: securityInput.GUID, Type: "currency", MinValue: this.globalFunctions.TextAreaValidMinNumeric, MaxValue: this.globalFunctions.TextAreaValidMaxNumeric })
      , new InputDataUnit({ Name: "INP_ValuationDate_" + counter, DisplayName: "Valuation Date (mandatory)", Placeholder: "Valuation Date", HTMLInputType: "date", Type: "shortdate", ParentGUID: securityInput.GUID })
      , new InputDataUnit({ Name: "INP_ValuerCompany_" + counter, DisplayName: "Valuer Company", Placeholder: "Enter a value to search", HTMLInputType: "autocomplete", Type: "string", ParentGUID: securityInput.GUID })
      , new InputDataUnit({ Name: "INP_ValuerContact_" + counter, DisplayName: "Valuer Contact", Placeholder: "Enter a value to search", HTMLInputType: "autocomplete", Type: "string", ParentGUID: securityInput.GUID })
      , new InputDataUnit({ Name: "INP_MinimumInsurableAmount_" + counter, DisplayName: "Minimum Insurable Amount", Placeholder: "Minimum Insurable Amount", HTMLInputType: "textarea", ParentGUID: securityInput.GUID, Type: "currency", MinValue: this.globalFunctions.TextAreaValidMinNumeric, MaxValue: this.globalFunctions.TextAreaValidMaxNumeric })
    );

    //Increment the counter
    this.SecurityValCounter += 1;

    //Run the input sync
    this.InputView_Sync();
  }

  //Lookup the ID/Name of the linked field, used for rendering it in the ng-template and pass it to child forminputdataunit component
  public LinkedFieldName_Get(parentGUID, linkedFieldName) {
    const linkedField = this.ModelData.filter(x => x.ParentGUID === parentGUID && x.Name === linkedFieldName)[0];

    if (!this.globalFunctions.isEmpty(linkedField)) {
      return linkedField.Name;
    }
  }

  //Lookup the ID/Name of the parent, used for rendering it in the ng-template and pass it to child forminputdataunit component
  public ParentFieldName_Get(guid) {
    const parentField = this.ModelData.filter(x => x.GUID === guid)[0];

    if (!this.globalFunctions.isEmpty(parentField)) {
      return parentField.Name;
    }
  }

  //For passing the context to ng-template for rendering the object
  public ParentFieldContext_Get(security) {
    return {
      GUID: security.GUID,
      ParentComponent: this,
      Index: security.Index
    }
  }

  //To show/hide the linked valuation fields based on parent state
  public SecurityValuation_Show(guid): boolean {
    const matchingField = this.ModelData.filter(x => x.GUID === guid)[0];
    if (!this.globalFunctions.isEmpty(matchingField)) {
      if (!this.globalFunctions.isEmpty(matchingField.Value)) {
        return true;
      }
    }

    return false;
  }

  //Add new security + valuations input fields
  AddNewValuation_Click(): void {
    this.SecurityValuation_Add();
  }

  //Show/hide the delete valuation button, to prevent user from deleting the last security valuation
  DeleteValuation_Show(): boolean {

    //Show if there are more than one securities
    if (this.Securities.length > 1) {
      return true;
    }

    return false;
  }

  //Remove the security valuation
  SecurityValuation_Remove(parentGUID): void {

    //Do not allow deleting the last security valuation fields
    if (this.Securities.length > 1) {

      //Get and delete linked fields
      const linkedFields = this.ModelData.filter(x => x.ParentGUID === parentGUID);
      linkedFields.forEach(linkedField => {
        const fieldIndex = this.ModelData.findIndex(x => x.Name === linkedField.Name);
        this.ModelData.splice(fieldIndex, 1);
      });

      //Get and delete the parent field
      const parentField = this.ModelData.filter(x => x.GUID === parentGUID)[0];
      if (!this.globalFunctions.isEmpty(parentField)) {
        const fieldIndex = this.ModelData.findIndex(x => x.Name === parentField.Name);
        this.ModelData.splice(fieldIndex, 1);
      }

      //Remove the parent field from tracking array
      const fieldIndex = this.Securities.findIndex(x => x.GUID === parentGUID);
      this.Securities.splice(fieldIndex, 1);

      //Sync the inputs after removing the security valuation section
      this.InputView_Sync();
    }
  }

  //Angular constructor
  ngOnInit() {
    //Set defaults for dates
    const dateNow = new Date();
    this.DTPMaxDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate());
  }

  //Function to lookup the css for the property and returns the css to hide or fade in the object
  public CSS_Lookup(propertyName, css): string {
    if (this.ShowSectionsLookup[propertyName] === false) {
      return this.CSSLookup[css] + ' ' + this.HiddenImmediateCSS;
    }

    return this.CSSLookup[css] + ' ' + this.FadeInLGCSS;
  }

  //Close this dialog
  public FinaliseTask_Close(showConfirmModal = true) {

    //Don't show confirm modal if it is being closed after submitting the form
    if (showConfirmModal === false) {

      //Close the modal
      this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);
    }
    else {
      //Adding a confirmer flow
      const confirmDialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", this.LoanIndex.AccountID, true, false);

      confirmDialogRef.DialogRef.componentInstance.htmlContent = "Are you sure you want to close this form? (Any data already entered will be lost)"

      confirmDialogRef.DialogRef.afterClosed().subscribe(result => {
        if (result === true) {

          //Close the modal
          this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);
        }
      });
    }
  }

  //Set all  UI input visibilty to a specified value (all visible or not)
  public InputVisibility_Set(flag: boolean): void {
    this.ShowApprovedBy = flag;
    this.ShowResultandComments = flag;
    this.ShowSubmitButton = flag;
    this.ShowDefaultEventNoText = flag;
    this.ShowDefaultEventYesText = flag;
    this.ShowAddNewValuationButton = flag;

    this.ShowSectionsLookup[this.ShowFileUploadQuestion] = flag;
    this.ShowSectionsLookup[this.ShowFileUploadSection] = flag;
    this.ShowSectionsLookup[this.ShowLoanCovenantQuestion] = flag;
    this.ShowSectionsLookup[this.ShowLoanCovenantSection] = flag;
    this.ShowSectionsLookup[this.ShowSecurityValuationQuestion] = flag;
    this.ShowSectionsLookup[this.ShowSecurityValuationSection] = flag;
    this.ShowSectionsLookup[this.ShowDefaultEventQuestion] = flag;
    this.ShowSectionsLookup[this.ShowDefaultEventSection] = flag;
  }

  //Get the matching model data input, used in InputView_Sync to determine when new content needs to be rendered
  public ModelDataUnit_Get(input) {
    const modelDataItem = this.ModelData.filter(x => x.Name === input.id)[0];

    if (!this.globalFunctions.isEmpty(modelDataItem)) {
      return modelDataItem;
    }
  }

  //Sync the display of the UI input elements
  public InputView_Sync(): void {

    //A small delay so that user click still catches the correct UI element.
    this.globalFunctions.delay(200).then(() => {

      //Turn off all the flags by default
      this.InputVisibility_Set(false);

      //For testing, turn it all on (faster debugging)
      //this.InputVisibility_Set(true);

      //Check if the task outcome is selected
      const outcomeDU = this.ModelDataUnit_Get({ id: "INP_Outcome" });
      if (!this.globalFunctions.isEmpty(outcomeDU.Value)) {
        this.ShowApprovedBy = true;

        //Get the Approved by Input Data Unit
        const approvedByDU = this.ModelDataUnit_Get({ id: "INP_ApprovedBy" });

        //Get the Date Declared
        const dateDeclaredDU = this.ModelDataUnit_Get({ id: "INP_DateDeclared" });

        //Check that both Approved by and Date Declared have valid values
        if (!this.globalFunctions.isEmpty(approvedByDU.Value) && approvedByDU.Value.length > approvedByDU.MinLength
          && !this.globalFunctions.isEmpty(dateDeclaredDU.Value) && dateDeclaredDU.Value !== "Invalid date") {
          this.ShowResultandComments = true;

          //Get the Result Input Data Unit
          const resultDU = this.ModelDataUnit_Get({ id: "INP_Result" });

          if (!this.globalFunctions.isEmpty(resultDU.Value) && resultDU.Value.length > resultDU.MinLength) {
            if (outcomeDU.Value === 'Waived') {
              this.ShowSectionsLookup[this.ShowFileUploadQuestion] = true;
            }
            else if (outcomeDU.Value === 'Satisfied') {
              this.ShowSectionsLookup[this.ShowSecurityValuationQuestion] = true;

              const showSecurityValuationOption = this.ModelDataUnit_Get({ id: "INP_ShowSecurityValuationOption" });

              //1 means Yes, 2 means No
              if (showSecurityValuationOption.Value === "1") {

                //if (showSecurityValuationOption.Value === "1") {
                this.ShowSectionsLookup[this.ShowSecurityValuationSection] = true;

                //Validate that all the security valuation details have been added
                if (this.ParentInput_IsComplete("INP_Security_", ["INP_ValuationDate_", "INP_ValuationAmount_"]) === true) {
                  this.ShowAddNewValuationButton = true;

                  //The valuation details have been added, now show the file upload option
                  this.ShowSectionsLookup[this.ShowFileUploadQuestion] = true;
                }
                //}
              }
              else if (showSecurityValuationOption.Value === "2") {

                //It's a No - just allow the next question to proceed
                this.ShowSectionsLookup[this.ShowFileUploadQuestion] = true;
              }
            }
            else if (outcomeDU.Value === 'Not Satisfied') {
              this.ShowSectionsLookup[this.ShowDefaultEventQuestion] = true;

              const showDefaultEventOption = this.ModelDataUnit_Get({ id: "INP_ShowDefaultEventOption" });

              if (showDefaultEventOption.Value === "1" || showDefaultEventOption.Value === "2") {

                this.ShowSectionsLookup[this.ShowDefaultEventSection] = true;
                this.ShowSectionsLookup[this.ShowFileUploadQuestion] = true;

                if (showDefaultEventOption.Value === "1") {
                  this.ShowDefaultEventYesText = true;
                }
                else if (showDefaultEventOption.Value === "2") {
                  this.ShowDefaultEventNoText = true;
                }
              }
            }
          }
        }
      }

      //Check the display of File upload and submit button
      const showFileUploadOption = this.ModelDataUnit_Get({ id: "INP_ShowFileUploadOption" });

      if (this.ShowSectionsLookup[this.ShowFileUploadQuestion] === true) {
        if (showFileUploadOption.Value === "1") {

          this.ShowSectionsLookup[this.ShowFileUploadSection] = true;

          const documentCategory = this.ModelDataUnit_Get({ id: "INP_DocumentCategory" });
          const documentBase64 = this.ModelDataUnit_Get({ id: "INP_FileUpload" });

          //Verify the file has been attached and the document category has been selected
          if (!this.globalFunctions.isEmpty(documentBase64.Value) && documentBase64.Value !== "" && !this.globalFunctions.isEmpty(documentCategory.Value) && documentCategory.Value !== "") {
            this.ShowSubmitButton = true;
          }

        }
        else if (
          showFileUploadOption.Value === "2") {
          this.ShowSubmitButton = true;
        }
      }

    });
  }

  //Check if the input is completed. Validate the mandatory linked fields, if required
  public ParentInput_IsComplete(parentInputPrefix: string, mandatoryLabels = []): boolean {

    //Get all the parent inputs
    const inputParents = this.ModelData.filter(x => x.Name.startsWith(parentInputPrefix));
    if (this.globalFunctions.isEmpty(inputParents)) {
      return false;
    }

    //Loop through each parent and validate the mandatory linked input fields, e.g. Val Date, Val Amount for Security
    for (const inputParent of inputParents) {

      //If there is any parent with empty value
      if (inputParent.Value === "") {
        return false;
      }

      //Loop through all mandatory linked inputs if provided
      for (const mandatoryLabel of mandatoryLabels) {

        //Look up the matching input
        const mandatoryLabelInput = this.ModelData.filter(x => x.Name.startsWith(mandatoryLabel) && x.ParentGUID === inputParent.GUID)[0];

        //Check if empty
        if (this.globalFunctions.isEmpty(mandatoryLabelInput) || mandatoryLabelInput.Value === "") {
          return false;
        }
      }
    }

    //All inputs have been validated
    return true;
  }

  //Clear the relevant model data (old, to remove)
  public InputData_Clear(itemName: string): void {

    this.ModelData[itemName] = "";
    this.InputView_Sync();
  }

  //Filter control data based on a type, used for document category. Let's change this and store locally.
  public ControlData_Filter(controlType): string[] {
    return this.globalFunctions.ControlData_Filter(controlType);
  }

  //Validate all input values in the model data depending on the type
  public ModelData_IsValidated(): boolean {

    //Loop through all the input data units and validate each
    for (const inputDataUnit of this.ModelData) {
      if (!this.globalFunctions.isEmpty(inputDataUnit)) {

        //Validate input
        if (!this.InputDataUnit_IsValidated(inputDataUnit)) {

          //If any of the input validation failed, break the loop and return
          return false;
        }
      }
    }

    //Validation passed
    return true;
  }

  //Method to set the owner guids
  public ModalData_SetOwnerGUIDs(): void {

    //Loop through all the input data units with data field guid and set the owner guids
    for (const inputDataUnit of this.ModelData.filter(x => x.DataFieldGUID !== "")) {
      if (!this.globalFunctions.isEmpty(inputDataUnit)) {

        //Check the direct owner entity type 
        //Account Details
        if (inputDataUnit.DirectOwnerEntityTypeGUID === "8C37B2B3-B999-4F4D-AF9F-DEFEEA2D90BD") {

          inputDataUnit.DirectOwnerGUID = this.LoanIndex.AccountID;
        }
        //Loan Tasks
        else if (inputDataUnit.DirectOwnerEntityTypeGUID === "25A62728-6B06-47CB-BC40-68EE6F2CC823") {

          inputDataUnit.DirectOwnerGUID = this.DataRow.GUID;
        }

        //Check the primary owner entity type 
        //Account Details
        if (inputDataUnit.PrimaryOwnerEntityTypeGUID === "8C37B2B3-B999-4F4D-AF9F-DEFEEA2D90BD") {

          inputDataUnit.PrimaryOwnerGUID = this.LoanIndex.AccountID;
        }
        //Loan Tasks
        else if (inputDataUnit.PrimaryOwnerEntityTypeGUID === "25A62728-6B06-47CB-BC40-68EE6F2CC823") {

          inputDataUnit.PrimaryOwnerEntityTypeGUID = this.DataRow.GUID;
        }
      }
    }
  }

  //Regex and Min/Max validations
  public InputDataUnit_IsValidated(inputDataUnit): boolean {

    const inputValueToEvaluate = inputDataUnit.Value;

    //Check if the user has selected a valid autocomplete value from the list. If user tabs out without clicking, the value might be invalid
    if (inputDataUnit.HTMLInputType === "autocomplete") {

      if (typeof (inputDataUnit.AutoCompleteControlData["ControlGUID"]) === "undefined" && !this.globalFunctions.isEmpty(JSON.stringify(inputDataUnit.AutoCompleteControlData))) {
        this.notifyService.Error_Show("Please select a valid " + inputDataUnit.DisplayName + " or remove it.", "Not a valid " + inputDataUnit.DisplayName);
        return false;
      }
    }

    //Check the data type, and perform the necessary removal of invalid characters using regex. except string, that has no regex
    if (inputDataUnit.Type.includes('string') === false) {

      //Get the regex for input data type
      const regExType = this.globalFunctions.RegExp_Get(inputDataUnit.Type);

      //Now perform the regex test
      if (!this.globalFunctions.isEmpty(regExType) && regExType.test(inputValueToEvaluate) === false) {

        //Regex failed. Let's parse it into a pretty text that we can display to the user
        let prettyType;
        prettyType = JSON.parse(JSON.stringify(inputDataUnit.Type));
        let precision = '';
        let prettyTypeDescription = '';

        if (prettyType.includes('.') === true) {
          const splitted = prettyType.split('.');
          prettyType = splitted[0];
          precision = splitted[1];
          prettyTypeDescription = prettyType + " with a maximum of " + precision + " decimal places";
        }
        else {
          prettyTypeDescription = prettyType;
        }

        //Now give the user this error message
        this.notifyService.Error_Show("The \"" + inputDataUnit.DisplayName + "\" value is invalid. Please review and try again", "Not a valid " + prettyTypeDescription)

        //Validation failed
        return false;
      }
      else {

        //We passed regex. now lets do max and min processing for numeric values

        //Check if its too big
        if (parseFloat(inputValueToEvaluate) > parseFloat(inputDataUnit.MaxValue)) {

          //Too big, give the user this error message
          this.notifyService.Error_Show("The \"" + inputDataUnit.DisplayName + "\" value is larger than the maximum of " + this.globalFunctions.customDataTypeParser(inputDataUnit.MaxValue, "decimal.0") + ". Please make it smaller and try again", "Invalid value")

          //Validation failed
          return false;
        }

        //Check if its too small
        else if (parseFloat(inputValueToEvaluate) < parseFloat(inputDataUnit.MinValue)) {

          //Too small. give the user this error message
          this.notifyService.Error_Show("The \"" + inputDataUnit.DisplayName + "\" value is smaller than the minimum of " + inputDataUnit.MinValue + ". Please make it larger and try again", "Invalid value")

          //Validation failed
          return false;
        }
      }
    }
    else {

      //String has no regex, but we stil need to check max. don't worry about min.
      if (inputValueToEvaluate.length > inputDataUnit.MaxLength) {

        //Its too long. now give the user this error message
        this.notifyService.Error_Show("The \"" + inputDataUnit.DisplayName + "\" value is longer than the maximum of " + inputDataUnit.MaxLength + ". Please make it shorter and try again", "Invalid length")

        //Validation failed
        return false;
      }
    }

    //Regex and Min/Max validation passed
    return true;
  }

  //Validate, construct and send the feedback request to the server
  public FinaliseTask_Click(): void {

    //Validate the inputs and see if any fails
    if (!this.ModelData_IsValidated()) {
      return;
    }

    //Set the DirectOwnerGUID and PrimaryOwnerGUID. Should we do this when we set the values?
    this.ModalData_SetOwnerGUIDs();

    //Construct API Request
    const apiRequest = { AccountID: this.LoanIndex.AccountID, UserTaskGUID: this.DataRow.GUID, InputDataUnits: this.ModelData };

    //Turn on full screen loading
    this.clientDataStore.SetShowFullscreenLoading(true);
    this.IsFinalisingTask = true;

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.FinaliseTask], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {

          //There was no response, or an error. Remove any loading screens and return
          this.IsFinalisingTask = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {

          //Get the response
          const response = JSON.parse(JSON.stringify(apiResponse));

          //Update the client side based on the response
          //Try to lookup the Task Status
          const taskStatus = this.DataRow.DataUnits.filter(x => x.Name === "Task Status")[0];

          //If found, let's update it
          if (!this.globalFunctions.isEmpty(taskStatus)) {
            taskStatus.Value = response.StatusGUID;
            taskStatus.ValueDisplay = response.StatusDisplay;

            taskStatus.ControlGUID = taskStatus.Value;
            taskStatus.ControlDisplay = taskStatus.ValueDisplay;
          }

          //Update Completed Date Data Unit
          const taskCompletedDate = this.DataRow.DataUnits.filter(x => x.Name === "Completed Date")[0];
          if (!this.globalFunctions.isEmpty(taskCompletedDate)) {

            //Update only if we receive the value in the response
            if (!this.globalFunctions.isEmpty(response.CompletedDate) && response.CompletedDate != this.globalFunctions.getServerNullString()) {

              //Set Value on this dataunit with the date returned from the server
              taskCompletedDate.Value = response.CompletedDate;
              taskCompletedDate.ValueDisplay = response.CompletedDate;

              //Format the data types, which also handles unescaping.
              taskCompletedDate.ValueDisplay = this.globalFunctions.customDataTypeParser(taskCompletedDate.ValueDisplay, taskCompletedDate.Type);

              //Force remove the CSS if it was empty, just in case
              taskCompletedDate.CSSClass = "";
            }
          }

          //Insert the new note to the User Task
          //Use Entity_UpdateLocal to attach the feedback note to the parent user task
          this.LoanIndex.Entity_UpdateLocal(response.NoteEntityResponse, false, "");
          this.LoanIndex.Entity_UpdateLocal(response.TaskDocEntityResponse, false, "");

          //In addition, if we are launched via the "Headless" mode, there might be some dashboard components visible. We should trigger a refresh on all of those, as a Task status change will mot likely mean that it needs to now be displayed in a different status column.
          this.LoanIndex.ParentComponent_RefreshTaskData();

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsFinalisingTask = false;

          this.notifyService.Info_Show(response.ResponseMessage, "Success");

          //We can close this screen now
          this.FinaliseTask_Close(false);
        }
      });
  }
}