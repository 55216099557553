import { Component } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { AccountsControllerMethods, NavigationUrls } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import moment from 'moment';
import { Settlements } from '../Settlements';

@Component({
  selector: 'SettlementConfirmation',
  templateUrl: './SettlementConfirmation.html',
  styleUrls: ['./SettlementConfirmation.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class SettlementConfirmation {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<SettlementConfirmation>) {
  }

  public PendingSettlement;
  public SettlementAmount: number;
  public SettlementAmountDisplay = "";
  public SettlementDate: Date;
  public Settlements: Settlements;

  //For tracking the settlement process
  public IsSettlementSuccessful = false;

  //For tracking if a server update request is in progress
  public IsGenerateInProgress = false;

  //Unique Modal Identifer
  public ModalIdentifier;

  //Settlement Date Input Validation
  public SettlementDate_Validate(): boolean {
    //Can't be empty
    if (this.globalFunctions.isEmpty(this.SettlementDate)) {
      this.notifyService.Error_Show("Please input the settlement date", "Date Error");

      return false;
    }

    //Current date
    const currentDate = this.globalFunctions.CurrentDate_Get();

    //Can't be future dated
    if (this.SettlementDate > currentDate) {
      this.notifyService.Error_Show("Settlement cannot be future dated", "Date Error");
      return false;
    }

    //Set the date to 40 days older for validation
    currentDate.setDate(currentDate.getDate() - 40);

    //Can't be settled prior 40 days
    if (this.SettlementDate < currentDate) {
      this.notifyService.Error_Show("Settlement may not be processed for a date older than 40 days", "Date Error");
      return false;
    }

    //All validation passed
    return true;
  }

  public LoanIndex_Visit(): void {

    //Close the modal first
    this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);

    //Account to visit
    const accountToVisit = { AccountID: this.PendingSettlement.AccountID, AccountID_NoHighlights: "", LoanStatus: "", PrincipalBalance: this.SettlementAmount, ProductName: this.PendingSettlement.Product, PrincipalBorrower: this.PendingSettlement.PrincipalBorrower }

    //Feed it to the data store
    this.clientDataStore.SetSelectedAccount(accountToVisit);

    //Let's send them to that account index page
    const targetURL = [NavigationUrls.Account.replace(':AccountID', this.PendingSettlement.AccountID.toString())];

    this.router.navigate(targetURL);

    return;
  }

  //Send the request to settle a loan on the server
  public AccountID_Settle(): void {

    //Settlement Date input validation
    if (!this.SettlementDate_Validate()) {
      //Validation failed
      return;
    }

    //Settlement amount input validation
    //Can't be empty
    if (this.globalFunctions.isEmpty(this.SettlementAmount)) {
      this.notifyService.Error_Show("Please input the settlement amount", "Settlement Amount Error");
      return;
    }

    //Can't be less than zero
    if (this.SettlementAmount < 0) {
      this.notifyService.Error_Show("Settlement amount cannot be negative", "Settlement Amount Error");
      return;
    }

    //Can't be zero
    if (this.SettlementAmount == 0) {
      this.notifyService.Error_Show("Settlement amount cannot be zero", "Settlement Amount Error");
      return;
    }

    //Can't be greater than approved amount
    if (this.SettlementAmount > this.PendingSettlement.LoanApprovalLimit) {
      this.notifyService.Error_Show("Settlement amount cannot be greater than approval amount", "Settlement Amount Error");
      return;
    }

    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", this.PendingSettlement.AccountID, true, false);

    //Convert date from YYYY-MM-DD to shortdate aus
    const settlementDate_FT = moment(this.SettlementDate).format('YYYY-MM-DD');
    const settlementDate_Display = moment(this.SettlementDate).format('dddd, Do MMMM YYYY');

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to settle this account: <b>" + this.PendingSettlement.AccountID + "</b>? <br><br> <ul style='padding-left:30px'><li>Borrower: " + this.PendingSettlement.PrincipalBorrower + "</li><li>Settlement Date: " + settlementDate_Display + "</li><li>Settlement Amount: " + this.SettlementAmountDisplay + "</li></ul><br>"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {

        //Set the spinner
        this.IsGenerateInProgress = true;

        //Full Screen Loading
        this.clientDataStore.SetShowFullscreenLoading(true);

        //Construct a request body that we can post to the server
        const apiRequest = { AccountID: this.PendingSettlement.AccountID, SettlementDate: settlementDate_FT, SettlementAmount: this.SettlementAmount };

        //Call the server, if we have some value to request
        this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.ProcessSettlement], apiRequest)
          .subscribe(apiResponse => {
            if (this.globalFunctions.isEmpty(apiResponse)) {
              this.IsGenerateInProgress = false;
              this.clientDataStore.SetShowFullscreenLoading(false);
              return;
            }
            else {
              //Deserialize it into an class that we can understand
              const getResponse = JSON.parse(JSON.stringify(apiResponse));

              this.IsGenerateInProgress = false;
              this.clientDataStore.SetShowFullscreenLoading(false);

              this.notifyService.Success_Show(getResponse.ResponseMessage, "Success");

              //We need to remove this on the client side. 
              this.Settlements.SettledAccount_Remove(this.PendingSettlement.AccountID);

              //Set the settlement completion flag to true
              this.IsSettlementSuccessful = true;
            }
          });
      }
    }
    );
  }

  //Change the display when the input box is on focus or out of focus
  public Input_Focused(isFocused: boolean) {
    if (isFocused == true) {

      //Display as a number when it is focused
      if (!this.globalFunctions.isEmpty(this.SettlementAmount)) {

        this.SettlementAmountDisplay = this.SettlementAmount.toString();

        //Convert the amounts to number format
        this.SettlementAmount = Number(this.SettlementAmount);
      }
    }
    else {

      //Display as a currency when it is out of focus
      if (!this.globalFunctions.isEmpty(this.SettlementAmountDisplay)) {
        this.SettlementAmount = Number(this.SettlementAmountDisplay);

        //Convert the amounts to currency format
        this.SettlementAmountDisplay = this.globalFunctions.customDataTypeParser(this.SettlementAmountDisplay, "currency.2", "aus");
      }
      else {
        this.SettlementAmount_Reset(0);
      }
    }
  }

  //Used to validate key presses on editing numeric inputs
  public InputNumeric_Validate(event, inputType: string, maxValue = "9999999999") {

    //Position of last character input for removal of last character and re-validation purposes
    const characterIndexInserted = event.target.selectionStart;

    //Check the data type, and perform the necessary removal of invalid characters using regex
    let regExType = null;

    if (inputType == 'integer') {
      regExType = this.globalFunctions.regExInteger;
    }
    else if (inputType == 'percent') {
      regExType = this.globalFunctions.regExPercent;
    }
    else if (inputType.includes('decimal.2')) {

      //Decimal can have differing precision. pick the right one
      regExType = this.globalFunctions.regExDecimal2;
    }
    else if (inputType.includes('decimal.4')) {
      regExType = this.globalFunctions.regExDecimal4;
    }
    else if (inputType == 'currency') {

      //Updating the format in place is a lot of work. requires us to store the original unformatted string, and a pretty version.
      //Code that i have done before in xChange mobile. will come back to it if desired. for now lets just use in place, with regex (no formatting applied)
      regExType = this.globalFunctions.regExCurrency;
    }
    else if (inputType.includes('currency.8')) {

      //Higher precision currency
      regExType = this.globalFunctions.regExCurrency8;
    }

    //Now perform the regex validation
    if (regExType.test(event.target.value) == false && !this.globalFunctions.isEmpty(regExType)) {

      //Didn't pass regex, remove the last character
      this.LastCharacter_Remove(event.target, characterIndexInserted);

      //Reset the class variables for the settlement amount with the restored value by removing the last character
      this.SettlementAmount_Reset(event.target.value);

      //Let's parse it into a pretty text that we can display to the user
      let prettyType: string;
      prettyType = JSON.parse(JSON.stringify(inputType));
      let precision = '';
      let prettyTypeDescription = '';

      if (prettyType.includes('.') == true) {
        const splitted = prettyType.split('.');
        prettyType = splitted[0];
        precision = splitted[1];
        prettyTypeDescription = prettyType + " with a maximum of " + precision + " decimal places";
      }
      else {
        prettyTypeDescription = prettyType;
      }

      //What if it was pasted in? and has more issues than just the last entered character? regex it once more, if it doesn't pass, remove the entire thing
      if (regExType.test(event.target.value) == false) {
        event.target.value = "";

        //Reset the class variables for the settlement amount to empty
        this.SettlementAmount_Reset(0);
      }

      //Now give the user this error message
      this.notifyService.Error_Show("An invalid value was entered. Please review and try again", "Not a valid " + prettyTypeDescription)
    }
    else {

      //We passed regex or the regex expression is empty. Lets do max processing for numeric values

      //Check if its too big
      if (parseFloat(event.target.value) > parseFloat(maxValue)) {

        //Don't update it, its too large. now give the user this error message
        this.notifyService.Error_Show("This value is larger than the maximum of " + maxValue + ". Please make it smaller and try again", "Invalid value")

        //Didn't pass, remove the last character
        this.LastCharacter_Remove(event.target, characterIndexInserted);

        //Reset the class variables for the settlement amount
        this.SettlementAmount_Reset(event.target.value);
      }
    }
  }


  //Reset the settlement amount on the front end after validation
  private SettlementAmount_Reset(newVal: number): void {

    //Numeric version
    this.SettlementAmount = Number(newVal);

    if (this.SettlementAmount == 0) {

      //Display version
      this.SettlementAmountDisplay = "";
    }
    else {
      this.SettlementAmountDisplay = this.SettlementAmount.toString();
    }
  }

  //Removes a character from the event target string, and resets the location of the selection index
  private LastCharacter_Remove(target, characterIndexInserted): void {

    //Didn't pass, remove the last character
    target.value = this.globalFunctions.removeStringByIndex(target.value, characterIndexInserted);

    //Try to set the cursor back to where it was
    target.selectionStart = characterIndexInserted - 1;
    target.selectionEnd = characterIndexInserted - 1;
  }

}