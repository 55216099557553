<div id="PG_TaskFollowup" @fadeInSection class="glb_formBGColor glb_boxedPadding">

    <!-- Close modal button. put this here on its own row, so that it stays put and won't bootstrap down to another row in smaller screen sizes -->
    <div class="glb_customFlexRow justify-content-end">
        <div class="justify-content-start align-self-bottom" style="padding-left: 50px; padding-bottom: 0px;">
            <button id="TaskFolowup_Close" [disabled]="IsUpdatingFollowup" tabindex="15"
                class="btn glb_clickableIconButtonStyleNone glb_clickableIconButtonColorDark glb_skinLightOutline" type="button"
                (click)="globalFunctions.FeatureModal_Close(ModalIdentifier)">
                <fa-icon [icon]="['fal', 'times']" class="fa-md" style="color:grey"></fa-icon>
            </button>
        </div>
    </div>
    <div class="glb_Font header-padding glb_customFlexRow">
        <div class="glb_customFlexRow col-12 glb_paddingZeroOffset" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            <div class="col-8 glb_paddingZeroOffset">
                <div class="glb_TitleLG glb_TitleLGMobile text-white text-start glb_breakLongWords glb_paddingZeroOffset glb_paddingBottomLG">
                    Set Follow Up
                </div>
            </div>
        </div>
    </div>

    <!-- For the primeng calendar datetime picker -->
    <div class="glb_customFlexRow row-cols-2 justify-content-start align-items-center">
        <div class="col-11 glb_floatParent" style="padding-left:0px; padding-right:0px; padding-bottom:6px;">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white" style="font-size: .80em;">Follow Up Date (mandatory)</div>
            <p-calendar #INP_Date id="DU_DTPDate" [(ngModel)]="DTPDate.JSDate" class="DTPInputGrid glb_maxWidth" [showTime]="false"
                [showSeconds]="false" [maxDate]="DTPMaxDate" [minDate]="DTPMinDate" [showIcon]="false" [showButtonPanel]="false"
                [showButtonBar]="true" [inline]="false" [showOtherMonths]="false" [hideOnDateTimeSelect]="false" [touchUI]="false"
                [dateFormat]="DTPDateFormat" [hourFormat]="12" [appendTo]="'body'" baseZIndex="5000" inline="false"
                inputStyleClass="DTPInputStyle glb_Font" placeholder="Enter Follow Up Date" (onClose)="DTP_OnClose(INP_Date)" (onShow)="DTP_Focus()"
                (onSelect)="DTP_OnClose(INP_Date)">
            </p-calendar>
            <div class="glb_floatTopRightCalendar">
                <span class="glb_clickableIconButtonStyleNone glb_skinLightOutline" type="button" (click)="INP_Date.toggle()">
                    <fa-icon [icon]="['fal', 'calendar']" class="fa-md" style="color:black"></fa-icon>
                </span>
            </div>
        </div>
    </div>

    <!-- Follow up Notes, Optional-->
    <div class="glb_customFlexRow row-cols-2 justify-content-center align-items-center" style="padding-bottom: 6px;">
        <div class="col-11 " style="padding-left:0px; padding-right:0px;">
            <div class="glb_Font glb_inputFieldLabel glb_customFlexRow text-white" style="font-size: .80em;">Notes (optional)</div>
            <textarea id="INP_Notes" class="textAreaCustom glb_Font textAreaHeightLarge" style="width: 100%; white-space: pre-wrap;"
                placeholder="Enter Follow Up Note" [(ngModel)]="FollowUpNote">
                  </textarea>
        </div>

        <!-- Button to clear content inside the input box, spans a div of its own -->
        <div class="col-1 justify-content-start align-self-end" style="padding-bottom: 4px; padding-left: 8px;">
            <span>
                <button tabindex="-1"
                    class="glb_clickableIconButtonStyleGrey glb_clickableIconButtonColorLight glb_clickableIconButtonMD glb_BGColorClear"
                    matTooltip="Click to Clear" (click)="Notes_ClearData()">
                    <fa-icon [icon]="['fal', 'eraser']" class="fa-fw glb_fixedSizeIcons"></fa-icon>
                </button>
            </span>
        </div>

    </div>
    <br>

    <!-- Submit button -->
    <div *ngIf="AllowSubmit" @fadeInSection class="glb_paddingTopSM glb_customFlexRow row-cols-1 justify-content-center align-items-center">
        <div class="glb_paddingBottomSM d-grid">
            <button id="BTN_Submit" tabindex="13" class="glb_standardButton glb_skinLightOutline btn glb_BGColorSkin text-white"
                (click)="SubmitFollowup_Click()">
                <span class="glb_Font glb_smallerText col-12 glb_customFlexRow align-items-center justify-content-center">Submit</span>
            </button>
        </div>
    </div>
</div>