import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { AccountsControllerMethods, StaticDataControllerMethods } from '@app/Global/EnumManager';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DocViewer } from '../DocViewer/DocViewer';
import { Router } from '@angular/router';
import { ControlData } from '@app/Global/Models/EntityModels';
import { AutoCompleteData } from '@app/Global/Models/ClientModels';
import { LoanIndex } from '../LoanIndex/LoanIndex';

@Component({
  selector: 'DocumentGenerator',
  templateUrl: './DocumentGenerator.html',
  styleUrls: ['./DocumentGenerator.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class DocumentGenerator implements OnInit {

  //Constructor
  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<DocumentGenerator>) {
  }

  //This one is to focus on the document text box
  @ViewChild('FocusDocumentName') FocusDocumentName;

  @Input() LoanIndex: LoanIndex;

  //For tracking if a server update request is in progress
  public IsGenerateInProgress = false;

  //Unique Modal Identifer
  public ModalIdentifier;

  //To store the latest/last request guid generated
  private LastAutoCompleteRequestGUID: string;

  //To choose a document template
  public DocumentInsertAutoComplete: AutoCompleteData = {
    AutoCompleteLastSearchedValue: '', AutoCompleteControlTypeName: 'Control Document Template', AutoCompleteControlData: []
  };

  //Bind variable created for clearing the Document. this is bound to the input element that contains the autocomplete, and needs to conform to the specific ControlData return type.
  public InsertDocumentBind: ControlData = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "", Entity: "" };

  //Default Document Control Data
  private DefaultDocumentBind: ControlData[] = [{ ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "", Entity: "" }];

  //Angular startup method
  ngOnInit(): void {
    this.Page_Init();
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server to generate the document
  public AutoComplete_SaveSelectedTemplate_ToGenerate(value: ControlData): void {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value)) {
      //Save it into the local Bind variable.
      this.InsertDocumentBind = { ControlDisplay: value.ControlValue, ControlGUID: value.ControlGUID, ControlValue: value.ControlValue, ControlType: "", Entity: "" };
    }
  }

  //In relation to autocomplete - read the response, return the ControlValue out of it. this allows autocomplete to show the 'pretty' value in the input box
  public AutoComplete_GetPrettyName(value: ControlData): string {
    if (value === null) {
      return "";
    }
    else {
      if (value.ControlValue != null) {
        return value.ControlValue;
      }
      else {
        return "";
      }
    }
  }

  //Send the request to Generate the statement on the server
  public Document_Generate(): void {
    //Let's do some basic client side validation
    if (this.globalFunctions.isEmpty(this.InsertDocumentBind.ControlGUID)) {
      this.notifyService.Error_Show("Please select a valid Document Template", "Error - Document Template Selection");
      return;
    }

    //Track state of this request, so that we can show the spinner, if necessary.
    this.IsGenerateInProgress = true;
    this.clientDataStore.SetShowFullscreenLoading(true);

    //Construct the request and send it to the server
    const apiRequest = { AccountID: this.LoanIndex.AccountID, DocumentControlGUID: this.InsertDocumentBind.ControlGUID };

    this.apiService.APIData_Post(this.apiService.Endpoints.AccountsController, AccountsControllerMethods[AccountsControllerMethods.GenerateDocumentFromTemplate], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //There was no response, or an error.
          this.IsGenerateInProgress = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Get the response, and try to display it in the document viewer
          const response = JSON.parse(JSON.stringify(apiResponse));
          //console.log('response', response);

          //Create a blob that can be used to represent the binary version of the file
          const blob = this.globalFunctions.base64toBlob(response.Base64Document);

          //Open DocViewer
          const docViewer = this.globalFunctions.FeatureModal_Launch(DocViewer, this.globalFunctions.GetFeatureModalConfig(), this.dialog, "Doc Viewer", this.LoanIndex.AccountID, true, false);

          docViewer.DialogRef.componentInstance.FileBlob = blob;
          docViewer.DialogRef.componentInstance.FileName = response.Name + "." + response.Extension;
          docViewer.DialogRef.componentInstance.FileType = response.Extension;
          docViewer.DialogRef.componentInstance.OriginalFileType = response.OriginalFileType;

          //These 3 datapoints are required for downloading a document from the Doc Viewer
          docViewer.DialogRef.componentInstance.DocumentGUID = response.GUID;
          docViewer.DialogRef.componentInstance.EntityType = "LoanDocuments";
          docViewer.DialogRef.componentInstance.LoanIndex = this.LoanIndex;

          //Turn off the fullscreen loading
          this.clientDataStore.SetShowFullscreenLoading(false);
          this.IsGenerateInProgress = false;

          this.globalFunctions.delay(1000).then(() => {

            //A notification would be nice
            this.notifyService.Success_Show("Document Generated", "Success");
          });
        }
      });
  }

  //This clears the selected control document template
  public Control_ClearSelected(): void {
    //Clear the selection
    this.InsertDocumentBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "", Entity: "" };
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue)
  public AutoComplete_ControlDocument_ApplyFilter(documentInsertAutoComplete: AutoCompleteData, requestType: string, controlValue: string, fieldType: string, seed = false): ControlData[] {
    // Ask server for values. only if we have a new value. if no value, don't try to retrieve anything from the server
    // if (this.globalFunctions.isEmpty(controlValue)) {
    //   return;
    // }

    //Check if we are seeding. only when the control value is blank! update the last search value if we are so that we trigger at least once. also only trigger if the Role_AutoCompleteLastSearchedValue is NOT equal to {seed}. This prevents us from seeding multiple times when there is no need (e.g. someone clicking on the field many times or pressing backspace repeatedly when there are no characters left)
    if (controlValue === "" && seed && documentInsertAutoComplete.AutoCompleteLastSearchedValue !== "{seed}") {
      //Set the last value to something different so that we allow the seed request to proceed
      documentInsertAutoComplete.AutoCompleteLastSearchedValue = "{preseed}";
      controlValue = "{seed}";
    }

    if (!this.globalFunctions.isEmpty(controlValue) && !this.globalFunctions.isEmpty(documentInsertAutoComplete.AutoCompleteLastSearchedValue)) {
      if ((documentInsertAutoComplete.AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {
        //Request new data and update the current value so we don't request it again.
        documentInsertAutoComplete.AutoCompleteLastSearchedValue = controlValue;

        //Let's timestamp this request. that way, when a old update responds slowly, we can discard its response
        //Set the class variable to this value
        this.LastAutoCompleteRequestGUID = this.globalFunctions.GenerateFastGUID();
        //Make a copy of it for sending to the update request
        const copyOfGUID = JSON.parse(JSON.stringify(this.LastAutoCompleteRequestGUID));

        this.AutoComplete_RequestData(requestType, documentInsertAutoComplete.AutoCompleteControlTypeName, controlValue
          , documentInsertAutoComplete.AutoCompleteControlData, copyOfGUID);
      }
    }

    //Check if the control data is non null
    if (documentInsertAutoComplete.AutoCompleteControlData != null) {
      //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
      return documentInsertAutoComplete.AutoCompleteControlData;
    }

    return this.DefaultDocumentBind;
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray: ControlData[], autoCompleteRequestGUID: string): void {
    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = { RequestType: requestType, ControlType: controlType, SearchValue: requestValue, AccountID: this.LoanIndex.AccountID };

    //Need to flip the endpoint here, dependant on the field that we are looking up.
    const autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete];

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, autoCompleteEndpoint, apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {
            if (autoCompleteRequestGUID === this.LastAutoCompleteRequestGUID) {
              this.AutoComplete_ProcessResponse(apiResponse, controlArray);
            }
          }
        });
    }
  }

  //Processes the autocomplete data returned by the server and pushes it into the array
  private AutoComplete_ProcessResponse(apiResponse: ControlData[], controlArray: ControlData[]): void {
    if (!this.globalFunctions.isEmpty(apiResponse)) {
      //Dont process if the passed in control array is empty.
      if (!this.globalFunctions.isEmpty(controlArray)) {
        //Setting the length of an array to zero it the fastest way to clear an array in TS.
        controlArray.length = 0;
      }

      const results = apiResponse;
      for (const key in results) {
        const ControlDataUnit = {
          Entity: results[key].Entity,
          ControlType: results[key].ControlType,
          ControlGUID: results[key].ControlGUID,
          ControlValue: this.globalFunctions.HTMLUnescape(results[key].ControlValue),
          ControlDisplay: ""
        };
        //Push the result in to the control array by ref
        controlArray.push(ControlDataUnit);
      }
    }
    else {
      //No results! empty out the array, only when it is non empty
      if (!this.globalFunctions.isEmpty(controlArray)) {
        controlArray.length = 0;
      }
    }
  }

  //Delay a bit and focus on the document type element
  private Page_Init(): void {
    //Focus on the control document text box
    this.globalFunctions.delay(1).then(() => { this.FocusDocumentName.nativeElement.focus(); });
  }
}