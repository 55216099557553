import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GlobalFunctions } from '@app/Global/GlobalFunctions';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '@app/Services/APIService';
import { NotifyService } from '@app/Services/NotifyService';
import { Router } from '@angular/router';
import { StaticDataControllerMethods, UsersControllerMethods } from '@app/Global/EnumManager';
import { of, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, flatMap, map } from 'rxjs/operators';
import { ClientDataStore } from '@app/Global/ClientDataStore';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModal } from '@app/Components/Loan/ConfirmModal/ConfirmModal';
import { TemplateID } from '@app/Global/Models/ClientModels';

@Component({
  selector: 'RoleManagement',
  templateUrl: './RoleManagement.html',
  styleUrls: ['./RoleManagement.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('0.1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
  ]
})

export class RoleManagement implements OnInit, OnDestroy {

  constructor(public globalFunctions: GlobalFunctions,
    private apiService: ApiService,
    private notifyService: NotifyService,
    private router: Router,
    private clientDataStore: ClientDataStore,
    public dialog: MatDialog) {
  }

  //View Childs
  @ViewChild('createRoleFocus') createRoleFocus: ElementRef;
  @ViewChild('createRoleForm') createRoleForm: ElementRef;
  @ViewChild('focusNewRoleName') focusNewRoleName;
  @ViewChild('editRoleName') editRoleName;

  @ViewChild('createClaimFocus') createClaimFocus: ElementRef;
  @ViewChild('createClaimForm') createClaimForm: ElementRef;
  @ViewChild('claimTypeFocus') claimTypeFocus;

  //The user list from the server
  public roleList;

  //Entity types needed for the control data
  public entityTypes;

  //Entity types needed for the entity property filter, this one won't have the All option
  public entityTypesForFilter;

  //Claim names  types needed for the control data
  public claimNames = "";

  //Client view model. contains all the entity propertes (or column names)
  public clientViewModel;

  //Variables for controlling Role Name Edit
  public roleEditEnabled = false;
  public roleSaveEnabled = false;
  public roleClearEnabled = false;
  public roleAssignedUsers = [];
  public showSpinner = false;

  //Unique Modal Identifer
  public ModalIdentifier;

  //Unique Modal Identifer
  public UserAssignedModalIdentifier;

  //To track whether a edit button has been clicked
  public ClaimEditEnabled = false;

  //To cache the entity property filter claim
  public ClonedEntityPropertyFilterClaim;

  //Used by paginator
  public paging = {
    maxSize: 10,
    previousLabel: "",
    nextLabel: ""
  }

  public paging_roleAssignedUsers = {
    maxSize: 10,
    previousLabel: "",
    nextLabel: ""
  }

  public currentPage_search: { currentPage: number } = { currentPage: 0 };
  public currentPage_roleAssignedUserList: { currentPage: number } = { currentPage: 0 };

  //Used for the filter search.
  public filterSearchValue = "";
  public filterSearchValueKeyUp = new Subject<any>();

  public dialogRef;
  public assignedUsersDialogRef;

  //For storing the clicked on user, their claims and roles
  public chosenRole;
  public currentChosenRole;
  public chosenRoleClaims = [];
  public chosenUserRoles = [];
  public chosenRoleAssignedUsers = [];
  public chosenRoleAssignedUsersNumber: number;

  //Headers for user claims  
  public TableHeaders: string[] = ['TBD'];
  public ColumnsInBody = 1;

  //These headers  are for the Role section
  public TableHeaders_Role: string[] = ['TBD'];
  public ColumnsInBody_Role = 2;

  public CombineLabelAndData = true;
  public WrapOddRows = false;
  //Used by the HostListener (window resize event) to adjust some text alignment when screen size changes. default it to -1 so that it doesn't get used, until its needed.
  public ColumnWrapping = -1;
  public ColumnsInTab = 1;

  //For storing the clicked on claim
  public chosenClaim;

  public isUpdatingRole = false;

  //Use this to store the last value sent to the server. used for tracking and not firing a queued request if another one has come in its place. its not perfect, but good enough for our type ahead use case (since a user can only really type in one field at a time, this can be reused)
  public lastSearchClientValue: string

  //Lets have a separate set of methods for constructing a new claim to be inserted.
  //Local client side variables to store the values. this is the claim name (or type)
  public createClaim_Name: string;

  //Store the autocomplete data for the assoc claim to be inserted (link type and client)
  public associationClaim = {
    LinkType_AutoCompleteLastSearchedValue: '', LinkType_AutoCompleteControlTypeName: 'Association Type', LinkType_AutoCompleteControlData: []
    , Client_AutoCompleteLastSearchedValue: '', Client_AutoCompleteControlTypeName: 'Client', Client_AutoCompleteControlData: []
  };

  //And the actual control guids. just store them here locally, use them on the save click.
  public createClaim_LinkType: string;
  public createClaim_Client: string;
  //For storing the chosen entity
  public createClaim_Entity: string;

  //For storing the chosen entity property
  public createClaim_EntityProperty: string;

  //And the permissions
  public createClaim_Read: boolean;
  public createClaim_Edit: boolean;
  public createClaim_Insert: boolean;
  public createClaim_Delete: boolean;

  //Track state of the create request, so that we can show the spinner, if necessary.
  public isCreatingClaimRequest = false;

  //Now repeat for role inserts. store the autocomplete data for the assoc claim to be inserted (link type and client)
  public roleInsert_AutoComplete = {
    Role_AutoCompleteLastSearchedValue: '', Role_AutoCompleteControlTypeName: 'Role', Role_AutoCompleteControlData: []
  };

  public createRole_Name: string;

  //Bind variable created for clearing the Role. this is bound to the input element that contains the autocomplete, and needs to conform to the specific ControlData return type.
  public insertRole_RoleBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

  //Variable to store the restrive and permissive filters
  public createClaim_PermissiveFilters = "";
  public createClaim_RestrictiveFilters = "";
  public createClaim_EditWhitelistFilters = "";

  //Bind variable created for clearing the client on changing the link type. this is bound to the input element that contains the autocomplete, and needs to conform to the specific ControlData return type.
  public createClaim_ClientBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
  public createClaim_LinkTypeBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };

  //This is used for showing the matching entity properties (columns) that are available for selection
  public entityPropertyArray = [];

  public subscription: Subscription;

  //Initialise the update role claim request
  private updateRoleClaimRequest = { RoleGUID: "", ClaimGUID: "", ModifyClaimType: "", TargetClaimValue: "" };

  //Initialise the update claim value request
  private updateRoleClaimValueRequest = { RoleGUID: "", ClaimGUID: "", ClaimValue: "" };

  //Initialise the update entity property filter claim request
  private updateEntityPropertyFilterClaimRequest = { RoleGUID: "", ClaimGUID: "", EntityPropertyFilterGUID: "", EntityPropertyFilterPermissive: "", EntityPropertyFilterRestrictive: "", EntityPropertyFilterEditWhitelist: "" };

  //Array to store the identifier GUIDs
  private TemplateIdentifiers: TemplateID[] = [];

  ngOnInit() {
    //Let's grab the users on init, so that the template can loop through and show them.
    this.Roles_Get();

    //Fill entity types from the client store
    this.entityTypes = this.clientDataStore.EntityTypes;
    this.entityTypesForFilter = this.clientDataStore.EntityTypes.filter(x => x.FriendlyName !== 'All');
    this.claimNames = this.clientDataStore.ClaimNames;
    this.clientViewModel = this.clientDataStore.ClientViewModelData;

    //Remove entity without view model mapper for entityTypesForFilter. These should not appear on entity property or entity property filter list
    this.entityTypesForFilter.forEach((element, index) => {
      const matchingEntityProperties = this.clientViewModel.filter(x => x.Entity === element.Name && x.PropertyType !== "entity");
      //Check if the entity has any view model mapper fields
      if (matchingEntityProperties.length <= 0) {
        //Remove it from the array if it doesn't have any view model mapped
        this.entityTypesForFilter.splice(index, 1);
      }
    });

    this.roleSaveEnabled = false;

    //Fill headers for the modal
    //Clear it first
    this.TableHeaders.length = 0;
    this.TableHeaders.push("Claim Type");
    this.TableHeaders.push("Value");
    this.TableHeaders.push("Targets");
    this.TableHeaders.push("Permissions");
    this.TableHeaders.push(" ");

    //Update the number of columns
    this.ColumnsInBody = this.TableHeaders.length;

    this.TableHeaders_Role.length = 0;
    this.TableHeaders_Role.push("Role");
    this.TableHeaders_Role.push(" ");

    //Update the number of columns
    this.ColumnsInBody_Role = this.TableHeaders_Role.length;

    //This creates a subscription to apply the search filter as per the search box. move this subscription to a class property, and destroy it on ngOnDestroy.

    this.subscription = this.filterSearchValueKeyUp.pipe(
      map((event) => (<HTMLInputElement>event.target).value),
      debounceTime(200),
      distinctUntilChanged(),
      flatMap(search => of(search).pipe(delay(100)))
    ).subscribe(value => {
      //Lets show the spinner when we start filtering
      if (!this.globalFunctions.isEmpty(value)) {
        //TODO
        //this.Entity_ToggleSpinner(this.EntityName, 0);
      }
      else {
        //Call it directly with a blank string
        this.Role_SearchFilter("");
      }

      //Small delay so that the spinner has a chance to show.
      //This is useful for the user experience, as they get a visual indication that the filtering has completed.
      this.globalFunctions.delay(300).then(() => {
        //Run after a small delay.
        if (!this.globalFunctions.isEmpty(value)) {
          this.Role_SearchFilter(value);
        }
      });
    });
  }

  ngOnDestroy() {
    if (!this.globalFunctions.isEmpty(this.subscription)) {
      this.subscription.unsubscribe();
    }
  }
  //Wrapper of isempty to allow local html template to call it. this is needed to stop the template from calling it when it is empty (before user data is populated from server), or we get null errors.
  public UserList_IsEmpty() {
    if (this.globalFunctions.isEmpty(this.roleList)) {
      return false;
    }
    return true;
  }

  public Page_OnChange(page: number, currentPage, doScrollToBottom = true) {
    currentPage.currentPage = page;
    //Dont scroll to top, bad UI experience. scroll to bottom instead
    if (doScrollToBottom) {
      this.globalFunctions.delay(50).then(() => {
        window.scrollTo(0, document.body.scrollHeight);
      });
    }
  }

  //Clears data friom the filter search box
  public SearchFilter_Clear(): void {
    this.filterSearchValue = "";
    //Call it directly with a blank string to reset the search filter
    this.Role_SearchFilter("");
  }

  public RoleNameEdit_Enable() {
    this.roleSaveEnabled = true;
    this.globalFunctions.delay(1).then(() => { this.editRoleName.nativeElement.focus(); });

  }

  public RoleNameEdit_Discard() {
    //Turn the spinner off
    this.roleSaveEnabled = false;
    this.isUpdatingRole = false;

    //A notification would be nice
    this.notifyService.Success_Show("Role detail changes are discarded", "Success");

    //Reset current user
    this.currentChosenRole = JSON.parse(JSON.stringify(this.chosenRole));
  }

  //Update the entity type for a claim
  public RoleName_Update() {
    this.isUpdatingRole = true;
    //Target entity value is in event.value
    const updateRoleName = { RoleGUID: this.currentChosenRole.RoleGUID, RoleName: this.currentChosenRole.Name };
    const apiRequest = updateRoleName;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.UpdateRoleName], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isUpdatingRole = false;
          return;
        }
        else {
          //Any updates needed client side? dont think so.          
          const getResponse = JSON.parse(JSON.stringify(apiResponse));

          //Replace the rolename using the server response value. (no need to update anything further, like claims)
          this.currentChosenRole.Name = this.globalFunctions.HTMLUnescape(getResponse.ResponseMessage);

          //Replace role details 
          const matchedRole = this.roleList.findIndex(x => x.RoleGUID === this.currentChosenRole.RoleGUID);

          //Update chosenRole details with the updated one
          this.chosenRole = JSON.parse(JSON.stringify(this.currentChosenRole));

          //And use splice to remove and replace it
          this.roleList.splice(matchedRole, 1, this.chosenRole);

          this.roleSaveEnabled = false;
          this.notifyService.Success_Show("Role Name Updated", "Success");
          this.isUpdatingRole = false;
        }
      });

  }

  //Gets roles from the server
  public Roles_Get() {
    this.showSpinner = true;
    const apiRequest = { NA: "" };
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetRoles], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.showSpinner = false;
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          //Store it client side
          this.roleList = response.RoleList;
          //Loop through and unescape the role names
          this.roleList.forEach(e => {
            //console.log(e);
            e.Name = this.globalFunctions.HTMLUnescape(e.Name);
            e.CreaterName = this.globalFunctions.HTMLUnescape(e.CreaterName);
          });

          this.showSpinner = false;
        }
      });
  }

  //Create role
  public Role_Create() {
    //Validate it first. make sure a claim type is chosen
    if (this.globalFunctions.isEmpty(this.createRole_Name)) {
      this.notifyService.Error_Show("Please Enter valid role name", "Role Name Missing");
      return;
    }

    this.isCreatingClaimRequest = true;

    //Client side validation passed. now let's construct the request and send it to the server
    const createRoleRequest = { RoleName: this.createRole_Name };
    const apiRequest = createRoleRequest;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.CreateRole], apiRequest)
      .subscribe(apiResponse => {
        //console.log("apiResponse", apiResponse);
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isCreatingClaimRequest = false;
          return;
        }
        else {
          //Insert this new role into the existing role datagrid.
          const createRoleResponse = JSON.parse(JSON.stringify(apiResponse));
          //Get the first entry in the array (we only expect one claim to be returned)
          const newRole = createRoleResponse.Roles[0];

          //Unescape
          newRole.Name = this.globalFunctions.HTMLUnescape(newRole.Name);
          newRole.CreaterName = this.globalFunctions.HTMLUnescape(newRole.CreaterName);

          //Unshift this into the client side array (at the start)
          this.roleList.unshift(newRole);

          //Clear the UI data. should we slide it closed too? hmm
          this.createRole_Name = "";

          //A notification would be nice
          this.notifyService.Success_Show("Role Inserted", "Success");
          this.isCreatingClaimRequest = false;
          //This collapses the role name input text box form
          this.TemplateID_Toggle("createRole");

          //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
          this.Page_OnChange(0, this.currentPage_search, false);
          //Clear search role
          this.SearchFilter_Clear();
        }
      });

  }

  public Role_Delete(targetRole) {

    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to delete this role: <b>" + targetRole.Name + "</b>?<br>"
    if (this.chosenRoleAssignedUsersNumber > 0) {
      dialogRef.DialogRef.componentInstance.htmlContent = dialogRef.DialogRef.componentInstance.htmlContent + "Number of users assigned to this role: <b>" + this.chosenRoleAssignedUsersNumber + "</b>";
    }
    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        //Construct a request body that we can post to the server
        const apiRequest = { UserGUID: null, RoleGUID: targetRole.RoleGUID };
        //Call the server, if we have some value to request
        if (targetRole != null) {
          this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.DeleteRole], apiRequest)
            .subscribe(apiResponse => {
              if (this.globalFunctions.isEmpty(apiResponse)) { return; }
              else {
                //Deserialize it into an class that we can understand
                const getResponse = JSON.parse(JSON.stringify(apiResponse));
                this.notifyService.Success_Show(getResponse.ResponseMessage, "Success");
                //We need to remove this on the client side. 
                const matchedRole = this.roleList.findIndex(x => x.RoleGUID === targetRole.RoleGUID)
                //And use splice to remove it
                this.roleList.splice(matchedRole, 1);
                //Close the modal
                //this.modalRef.hide();
                this.globalFunctions.FeatureModal_Close(this.ModalIdentifier, false);

              }
            });
        }
      }
    }
    );
  }

  public Role_Clone(targetRole) {

    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);

    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to clone this role: <b>" + targetRole.Name + "</b>?<br>"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        //Construct a request body that we can post to the server
        const apiRequest = { UserGUID: null, RoleGUID: targetRole.RoleGUID };
        this.isCreatingClaimRequest = true;
        //Fullscreen loading
        this.clientDataStore.SetShowFullscreenLoading(true);

        //Call the server, if we have some value to request
        if (targetRole != null) {
          this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.CloneRole], apiRequest)
            .subscribe(apiResponse => {
              if (this.globalFunctions.isEmpty(apiResponse)) {
                //Fullscreen loading
                this.clientDataStore.SetShowFullscreenLoading(false);
                return;
              }
              else {
                //Deserialize it into an class that we can understand
                //Insert this new role into the existing role datagrid.
                const serverResponse = JSON.parse(JSON.stringify(apiResponse));
                //Get the first entry in the array (we only expect one claim to be returned)
                const newRole = serverResponse.Roles[0];

                //Unescape
                newRole.Name = this.globalFunctions.HTMLUnescape(newRole.Name);
                newRole.CreaterName = this.globalFunctions.HTMLUnescape(newRole.CreaterName);

                //Unshift this into the client side array (at the start)
                this.roleList.unshift(newRole);

                //A notification would be nice
                this.notifyService.Success_Show(serverResponse.ResponseMessage, "Success");
                this.isCreatingClaimRequest = false;

                //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
                this.Page_OnChange(0, this.currentPage_search, false);
                //Clear search role
                this.SearchFilter_Clear();

                //Close the role claims modal
                this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);

                //Fullscreen loading
                this.clientDataStore.SetShowFullscreenLoading(false);
              }
            });
        }
      }
    }
    );
  }

  //This applies a filter based on the search term to the input entity.
  public Role_SearchFilter(searchTerm: string) {
    //Unselected any data units (they might get hidden by the filter)
    //this.DataUnit_RemoveSelected();

    //Try to catch null or undefined, replace with blank string
    if (this.globalFunctions.isEmpty(searchTerm)) {
      //Force it to an empty value (if null or undefined)
      searchTerm = "";
    }

    //console.log("running search filter for: ", searchTerm);
    //Make sure search term is not null, and greater than 2 characters. also uppercase it
    searchTerm = searchTerm.toUpperCase();
    //console.log("EntitySearchFilter firing for: ", EntityName, " - ", searchTerm);
    if (searchTerm != null && searchTerm.length > 2) {
      //Loop through each row in the userList
      for (const key in this.roleList) {
        const value = this.roleList[key];

        //We want to loop through valid properties here.
        //console.log("user: ", value);
        if (value['Name'].toUpperCase().includes(searchTerm) === true) {
          value.isHidden = false;
        }
        else {
          value.isHidden = true;
        }

      }

      //We should trigger the paginator to cycle back to the first page, since it may be on an illegal page after filtering.
      this.Page_OnChange(0, this.currentPage_search);
    }
    else if (searchTerm == "") {
      //Remove all the hide filters
      for (const key in this.roleList) {
        const value = this.roleList[key];

        if (!this.globalFunctions.isEmpty(value)) {
          value.isHidden = false;
        }
      }
    }

    //Turn the spinner off after we have finished with filtering. maybe not needed as this is client side, and should be pretty fast
    //this.EntityDict[EntityName].Spinner = 1;
  }

  //Gets the list of users assigned to this role
  public RoleAssignedUsers_Get(roleGUID: string, template): void {
    //Fullscreen loading
    this.clientDataStore.SetShowFullscreenLoading(true);

    const apiRequestRoleAssignedUsers = { RoleGUID: roleGUID };
    const apiRequest = apiRequestRoleAssignedUsers;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetRoleAssignedUsers], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));
          this.chosenRoleAssignedUsers = response.Users;

          //Let's create a unique identifier when a modal is launched. This will be used for tracking the minimizing/maximizing the windows
          this.UserAssignedModalIdentifier = this.globalFunctions.GenerateFastGUID();

          this.assignedUsersDialogRef = this.globalFunctions.FeatureModal_Launch(template, this.globalFunctions.GetFeatureModalConfig('60%'), this.dialog, "Role Assigned Users", 0, true, false, this.UserAssignedModalIdentifier);
          this.clientDataStore.SetShowFullscreenLoading(false);
        }
      });
  }

  //Asks the server for users assigned to this role, and launches the modal with the data
  public RoleAssignedUsers_Launch(template: TemplateRef<any>, chosenRole) {
    this.currentPage_roleAssignedUserList.currentPage = 0;
    this.RoleAssignedUsers_Get(chosenRole.RoleGUID, template);
  }

  //Get all claims for a single Role
  public RoleClaims_Get(roleGUID: string, template): void {
    const getRoleClaims = { RoleGUID: roleGUID };
    const apiRequest = getRoleClaims;

    //Reset claim enabled flag and cloned claim
    this.ClaimEditEnabled = false;
    this.ClonedEntityPropertyFilterClaim = null;

    this.clientDataStore.SetShowFullscreenLoading(true);

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.GetRoleClaims], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.clientDataStore.SetShowFullscreenLoading(false);
          return;
        }
        else {
          //Deserialize it into an class that we can understand
          const response = JSON.parse(JSON.stringify(apiResponse));

          this.chosenRoleClaims = response.RoleClaims;
          this.chosenRoleAssignedUsersNumber = response.NumberOfAssignedUsers;

          //Need to loop through all assoc claims and construct a new auto complete array, otherwise it will fail to fill it later when searching values
          Object.entries(this.chosenRoleClaims).forEach(
            ([, value]) => {
              if (!this.globalFunctions.isEmpty(value['AssociationClaim'])) {
                value['AssociationClaim'].LinkType_AutoCompleteControlData = [];
                value['AssociationClaim'].Client_AutoCompleteControlData = [];

                //console.log('this.chosenRoleClaims claim value', value);
                //Unescape the client name
                value['AssociationClaim'].Client_AutoCompleteControlDisplayValue = this.globalFunctions.HTMLUnescape(value['AssociationClaim'].Client_AutoCompleteControlDisplayValue);
              }
            });

          //Loop through all the claims and set EditEnabled = false by default on all claims
          this.chosenRoleClaims.forEach(claim => {
            claim.EditEnabled = false;
          });

          //Let's create a unique identifier when a modal is launched. This will be used for tracking the minimizing/maximizing the windows
          this.ModalIdentifier = this.globalFunctions.GenerateFastGUID();

          //Wrapper needed to avoid circular dependencies
          this.dialogRef = this.globalFunctions.FeatureModal_Launch(template, this.globalFunctions.GetFeatureModalConfig(), this.dialog, "Role Claims", 0, true, false, this.ModalIdentifier);

          this.roleSaveEnabled = false;
          this.clientDataStore.SetShowFullscreenLoading(false);
          //this.modalRef = this.modalService.show(template, modalConfig);
        }
      });
  }

  //Asks the server for this users claims, and launches the modal
  public RoleClaims_View(template: TemplateRef<any>, chosenRole) {
    //Set it on class in the index. this will be used for update requests to the server    
    this.chosenRole = chosenRole;
    this.currentChosenRole = JSON.parse(JSON.stringify(this.chosenRole));
    this.RoleClaims_Get(this.currentChosenRole.RoleGUID, template);
  }

  //When a permission check box is clicked
  public ClaimCheckBox_Clicked(clickedItem, targetPermission, targetClaimValue, targetClaimItem) {
    //Send the request to the server
    const apiRequest = this.updateRoleClaimRequest;
    apiRequest.ClaimGUID = clickedItem.ClaimGUID;
    apiRequest.RoleGUID = this.chosenRole.RoleGUID;
    apiRequest.ModifyClaimType = targetPermission;
    apiRequest.TargetClaimValue = targetClaimValue;

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.UpdateRoleClaim], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //Reverse the click client side so that the user doesn't see any change in the value
          this.ClaimCheckBox_Reset(targetClaimItem);
          return;
        }
        else {
          //No need to do anything the response here, for a boolean update. the client has already switched the value. a notification would be nice.
          this.notifyService.Success_Show("Permission Updated", "Success");
        }
      });
  }

  //Save the claim value
  public ClaimValue_Save(clickedItem) {

    //Send the request to the server
    const apiRequest = this.updateRoleClaimValueRequest;
    apiRequest.ClaimGUID = clickedItem.ClaimGUID;
    apiRequest.RoleGUID = this.chosenRole.RoleGUID;
    apiRequest.ClaimValue = clickedItem.Value;

    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.RoleClaim_UpdateValue], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          return;
        }
        else {
          //No need to do anything the response here, for a boolean update. the client has already switched the value. a notification would be nice.
          this.notifyService.Success_Show("Claim Value Updated", "Success");
        }
      });
  }

  //Entity property filter claim edit button on click
  public EntityPropertyFilterEdit_Click(clickedItem) {

    //Clone the target claim
    this.ClonedEntityPropertyFilterClaim = JSON.parse(JSON.stringify(clickedItem));

    //Enable the inputs and show the cancel and save button
    clickedItem.EditEnabled = true;
    this.ClaimEditEnabled = true;
  }

  //Entity property filter claim save button on click
  public EntityPropertyFilterSave_Click(clickedItem) {

    //Add client side validations, must have at least one of either Permissive or Restrictive
    if (this.globalFunctions.isEmpty(clickedItem.EntityPropertyFilterClaim.EntityPermissiveFilter) && this.globalFunctions.isEmpty(clickedItem.EntityPropertyFilterClaim.EntityRestrictiveFilter)) {

      //Show error toast
      this.notifyService.Error_Show("Please enter some text into either the Permissive or Restrictive Fields", "Error");
      return;
    }

    //Set up the api request
    const apiRequest = this.updateEntityPropertyFilterClaimRequest;
    apiRequest.ClaimGUID = clickedItem.ClaimGUID;
    apiRequest.RoleGUID = this.chosenRole.RoleGUID;
    apiRequest.EntityPropertyFilterGUID = clickedItem.EntityPropertyFilterClaim.GUID;
    apiRequest.EntityPropertyFilterPermissive = clickedItem.EntityPropertyFilterClaim.EntityPermissiveFilter;
    apiRequest.EntityPropertyFilterRestrictive = clickedItem.EntityPropertyFilterClaim.EntityRestrictiveFilter;
    apiRequest.EntityPropertyFilterEditWhitelist = clickedItem.EntityPropertyFilterClaim.EntityEditWhitelistFilter;

    //Set the request to the server
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.RoleClaim_UpdateEntityPropertyFilter], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          return;
        }
        else {

          //No need to do anything the response here, for a boolean update. the client has already switched the value. a notification would be nice.
          this.notifyService.Success_Show("Entity Property Filter Claim Updated", "Success");

          //Toggle the flags to show the edit button
          clickedItem.EditEnabled = false;
          this.ClaimEditEnabled = false;
        }
      });

  }

  //Entity property filter claim cancel button on click
  public EntityPropertyFilterCancel_Click(clickedItem) {

    //Restore back the original values
    clickedItem.EntityPropertyFilterClaim.EntityPermissiveFilter = this.ClonedEntityPropertyFilterClaim.EntityPropertyFilterClaim.EntityPermissiveFilter;
    clickedItem.EntityPropertyFilterClaim.EntityRestrictiveFilter = this.ClonedEntityPropertyFilterClaim.EntityPropertyFilterClaim.EntityRestrictiveFilter;
    clickedItem.EntityPropertyFilterClaim.EntityEditWhitelistFilter = this.ClonedEntityPropertyFilterClaim.EntityPropertyFilterClaim.EntityEditWhitelistFilter;

    //Disable the inputs
    clickedItem.EditEnabled = false;
    this.ClaimEditEnabled = false;
  }

  //Reset the state of a client side checkbox
  public ClaimCheckBox_Reset(target) {
    if (target.checked === true) {
      target.checked = false;
    }
    else {
      target.checked = true;
    }
  }

  //Resets all the UI controls for the assignrtole  section
  public AssignRole_ClearUIData() {
    //So, looks like to get angular material autocomplete to refresh on the html element, we have to push a new OBJECT into the variable
    this.insertRole_RoleBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
  }

  //Resets all the UI controls for the create new claim section
  public CreateClaim_ClearUIData() {
    this.createClaim_Name = "";
    this.createClaim_LinkType = "";
    this.createClaim_Client = "";
    this.createClaim_Entity = "";
    this.createClaim_EntityProperty = "";
    this.createClaim_PermissiveFilters = "";
    this.createClaim_RestrictiveFilters = "";
    this.createClaim_EditWhitelistFilters = "";

    this.createClaim_Read = false;
    this.createClaim_Edit = false;
    this.createClaim_Insert = false;
    this.createClaim_Delete = false;

    //So, looks like to get angular material autocomplete to refresh on the html element, we have to push a new OBJECT into the variable
    this.createClaim_ClientBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    this.createClaim_LinkTypeBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
  }

  //Update the entity type for a claim
  public EntityClaim_Update(claimItem, event, matEntitySelect) {
    //Target entity value is in event.value
    const updateClaim = { UserGUID: null, RoleGUID: this.chosenRole.RoleGUID, ClaimGUID: claimItem.ClaimGUID, Entity: event.value };
    const apiRequest = updateClaim;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.UpdateClaimEntity], apiRequest)
      .subscribe(apiResponse => {
        //console.log("apiResponse", apiResponse);
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //We need to return the dropdown to its old value. claim target has the old value. use this, since selectionChange is too late - dropdown box has already changed.
          matEntitySelect.value = claimItem.ClaimTarget;
          return;
        }
        else {
          //Any updates needed client side? dont think so
          //A notification would be nice
          this.notifyService.Success_Show("Entity Type Updated", "Success");
        }
      });

  }

  //Delete a claim
  public UserClaim_Delete(claimGUID: string) {

    const dialogRef = this.globalFunctions.FeatureModal_Launch(ConfirmModal, this.globalFunctions.GetConfirmModalConfig(), this.dialog, "Confirm Modal", 0, true, false);
    const targetRoleClaim = this.chosenRoleClaims.filter(x => x.ClaimGUID === claimGUID)[0];
    //console.log(targetRoleClaim);
    //Use html content so that we can style it
    dialogRef.DialogRef.componentInstance.htmlContent = "Are you sure that you want to delete this claim type: <b>" + targetRoleClaim.NameFriendly + "</b>?<br>"

    dialogRef.DialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        //Construct a AutoComplete request body that we can post to the server
        const apiRequest = { UserGUID: null, RoleGUID: this.chosenRole.RoleGUID, ClaimGUID: claimGUID };
        //Call the server, if we have some value to request
        if (claimGUID != null) {
          this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.DeleteClaim], apiRequest)
            .subscribe(apiResponse => {
              if (this.globalFunctions.isEmpty(apiResponse)) { return; }
              else {
                //Deserialize it into an class that we can understand
                const getResponse = JSON.parse(JSON.stringify(apiResponse));
                this.notifyService.Success_Show(getResponse.ResponseMessage, "Success");
                //We need to remove this on the client side. TODO
                const chosenClaim = this.chosenRoleClaims.findIndex(x => x.ClaimGUID === claimGUID)
                //And use splice to remove it
                this.chosenRoleClaims.splice(chosenClaim, 1);
              }
            });
        }
      }
    }
    );
  }

  //Method to create/insert the new claim
  public EntityClaim_Create() {
    //Validate it first. make sure a claim type is chosen
    if (this.globalFunctions.isEmpty(this.createClaim_Name)) {
      this.notifyService.Error_Show("Please choose a valid Claim Type", "Claim Type Missing");
      return;
    }

    //If this is an association type claim, then we should check that a link type and client is chosen
    if (this.createClaim_Name === 'Associations') {
      if (this.globalFunctions.isEmpty(this.createClaim_LinkType)) {
        this.notifyService.Error_Show("Please choose a valid Link Type", "Association Link Type Missing");
        return;
      }

      if (this.globalFunctions.isEmpty(this.createClaim_Client)) {
        this.notifyService.Error_Show("Please choose a valid Client", "Client Missing");
        return;
      }
    }

    //If this is an entities type claim, then we should check that a entity is chosen
    if (this.createClaim_Name === 'Entities') {
      if (this.globalFunctions.isEmpty(this.createClaim_Entity)) {
        this.notifyService.Error_Show("Please choose a valid Entity", "Entity Missing");
        return;
      }
    }

    this.isCreatingClaimRequest = true;

    //Client side validation passed. now let's construct the request and send it to the server
    const createClaimEntity = { UserGUID: null, RoleGUID: this.chosenRole.RoleGUID, ClaimType: this.createClaim_Name, LinkType: this.createClaim_LinkType, Client: this.createClaim_Client, Entity: this.createClaim_Entity, ResponseMessage: "", Read: this.createClaim_Read, Edit: this.createClaim_Edit, Insert: this.createClaim_Insert, Delete: this.createClaim_Delete, EntityProperty: this.createClaim_EntityProperty, EntityPropertyFilterPermissive: this.createClaim_PermissiveFilters, EntityPropertyFilterRestrictive: this.createClaim_RestrictiveFilters, EntityPropertyFilterEditWhitelist: this.createClaim_EditWhitelistFilters };

    const apiRequest = createClaimEntity;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.CreateClaim], apiRequest)
      .subscribe(apiResponse => {
        //console.log("apiResponse", apiResponse);
        if (this.globalFunctions.isEmpty(apiResponse)) {
          this.isCreatingClaimRequest = false;
          //nothing required
          return;
        }
        else {
          //insert this new claim into the existing client claims datagrid.          
          const getUserClaimsResponse = JSON.parse(JSON.stringify(apiResponse));
          //get the first entry in the array (we only expect one claim to be returned)
          const newClaim = getUserClaimsResponse.ClientClaims[0];

          //assoc claims need some initialization
          if (!this.globalFunctions.isEmpty(newClaim['AssociationClaim'])) {
            newClaim['AssociationClaim'].LinkType_AutoCompleteControlData = [];
            newClaim['AssociationClaim'].Client_AutoCompleteControlData = [];

            //unescape the client name
            newClaim['AssociationClaim'].Client_AutoCompleteControlDisplayValue = this.globalFunctions.HTMLUnescape(newClaim['AssociationClaim'].Client_AutoCompleteControlDisplayValue);
          }

          //unshift this into the client side array (at the start)
          this.chosenRoleClaims.unshift(newClaim);

          //clear the UI data. should we slide it closed too? hmm
          this.CreateClaim_ClearUIData();

          //a notification would be nice
          this.notifyService.Success_Show("Claim Inserted", "Success");
          this.isCreatingClaimRequest = false;

          //this collapses the role name input text box form
          this.TemplateID_Toggle("createClaim");
        }
      });

  }

  //Set cursor in the input text box
  public CreateClaimModal_Expand() {
    this.globalFunctions.delay(1).then(() => { this.claimTypeFocus.nativeElement.focus(); });
  }

  //Set cursor in the input text box
  public CreateRoleModal_Expand() {
    this.globalFunctions.delay(1).then(() => { this.focusNewRoleName.nativeElement.focus(); });
  }

  //Store the entity for a create request
  public EntityClaim_ForCreate(event) {
    //Target entity value is in event.value
    this.createClaim_Entity = event.value

    //If this is an entity property, then we want to filter the entityPropertyArray. find the matching entity so we can refer to its name
    const matchingEntity = this.entityTypes.filter(x => x.FriendlyName === event.value);
    //console.log('EntityClaim_ForCreate matchingEntity', matchingEntity);

    //Now find the matching properties using this name.
    const matchingEntityProperties = this.clientViewModel.filter(x => x.Entity === matchingEntity[0].Name && x.PropertyType !== "entity");
    //console.log('EntityClaim_ForCreate matchingEntityProperties', matchingEntityProperties);

    //Clear the entity property array and fill it with matching ones only.
    this.entityPropertyArray.length = 0;
    matchingEntityProperties.forEach(element => {
      this.entityPropertyArray.push(element);
    });
  }

  //Store the chosen entity property for the create request
  public EntityClaimProperty_ForCreate(event) {
    //Target entity value is in event.value
    this.createClaim_EntityProperty = event.value
  }

  //When closing the claim modal, we can reset certain UI elements
  public ClaimModal_Close() {

    //Close the modal
    this.globalFunctions.FeatureModal_Close(this.ModalIdentifier);

    //Clear UI data for the create claim section
    this.CreateClaim_ClearUIData();
  }

  //Gets the class based on entity setup. this is needed as direct binding inside the html template seems to run into some runtime bugs (might be a limit related to the length of the conditions)
  public CssClass_Get(Type: string, ColumnsInBody: number, WrapOddRows: boolean = null, isOdd: boolean = null, isEven: boolean = null, CombineLabelAndData: boolean = null
    , itemIndex: number = null, arrayCount: number = null, rowStyle: string = null
  ) {
    //Testing performance, how often is this firing.
    //console.log("getClass firing for:", Type);

    //For data pairs (label + value)
    if (Type == 'DataPair') {
      let returnClass = "";
      //Check if any row specific style need to be applied to the data.
      if (rowStyle === "ghosted") {
        returnClass = returnClass + "ghosted ";
      }
      if (rowStyle === "rowBold") {
        returnClass = returnClass + "rowBold ";
      }
      if (rowStyle === "addToBase") {
        returnClass = returnClass + "addToBase ";
      }

      if (ColumnsInBody === 1) {
        return returnClass + "row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1";
      }
      if (!WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0)) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-2';
      }
      if (WrapOddRows && (ColumnsInBody === 2 || ColumnsInBody === 0)) {
        return returnClass + 'row-cols-2 row-cols-md-2 row-cols-sm-2 row-cols-lg-2';
      }
      if (ColumnsInBody === 7) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-8';
      }
      if (ColumnsInBody === 6) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-6 row-cols-lg-6';
      }
      if (ColumnsInBody === 5) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-5 row-cols-lg-5';
      }
      if (ColumnsInBody === 4) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-4';
      }
      if (ColumnsInBody === 8) {
        return returnClass + 'row-cols-2 row-cols-sm-2 row-cols-md-4 row-cols-lg-8';
      }
    }

    //For the actual value display
    else if (Type == 'ValueDisplay') {
      if (((isOdd || !WrapOddRows) || CombineLabelAndData) && ColumnsInBody < 4) {
        return 'dataClass text-lg-end text-md-end text-sm-end text-xs-start';
      }
      if (isEven && (WrapOddRows && !CombineLabelAndData && ColumnsInBody >= 0 && ColumnsInBody < 4)) {
        return 'labelClass text-lg-start text-md-start text-sm-start';
      }
      if (((this.ColumnWrapping === 2 && isEven) || (this.ColumnWrapping === 0 && itemIndex <= (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'text-start';
      }
      if (((this.ColumnWrapping === 2 && isOdd) || (this.ColumnWrapping === 0 && itemIndex > (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'text-end';
      }
    }

    //For table header values
    else if (Type == 'TableHeaderValues') {
      if (((isOdd || !WrapOddRows) || CombineLabelAndData) && ColumnsInBody < 4) {
        return 'labelClass text-lg-end text-md-end text-sm-end text-xs-start';
      }
      if (isEven && (WrapOddRows && !CombineLabelAndData && ColumnsInBody >= 0 && ColumnsInBody < 4)) {
        return 'labelClass text-lg-start text-md-start text-sm-start';
      }
      if (((this.ColumnWrapping === 2 && isEven) || (this.ColumnWrapping === 0 && itemIndex <= (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'labelClass text-start';
      }
      if (((this.ColumnWrapping === 2 && isOdd) || (this.ColumnWrapping === 0 && itemIndex > (arrayCount / 2))) && ColumnsInBody >= 4) {
        return 'labelClass text-end';
      }
    }
  }

  //For throwing client side error for testing
  public Error_Throw() {
    throw Error("Client side error");
  }

  //Find and Toggle the IsEnabled Flag on the Template identifier
  public TemplateID_Toggle(identifierID: string): void {
    this.globalFunctions.TemplateID_Toggle(identifierID, this.TemplateIdentifiers)
  }

  //Get the css class based on the Template identifier state which drives if it should be displayed or not
  public TemplateID_GetCSS(identifierID: string, inverted = false): string {
    return (this.globalFunctions.TemplateID_GetCSS(identifierID, inverted, this.TemplateIdentifiers));
  }

  //Requests autocomplete data from the server
  private AutoComplete_RequestData(requestType: string, controlType: string, requestValue: string, controlArray, fieldType: string): void {
    //Construct a AutoComplete request body that we can post to the server
    const apiRequest = { RequestType: requestType, ControlType: controlType, SearchValue: requestValue };

    //Need to flip the endpoint here, dependant on the field that we are looking up.
    let autoCompleteEndpoint = "AutoComplete";
    if (fieldType === "LinkType") {
      autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete];
    }
    else if (fieldType === "Client") {
      autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete_Client];
    }
    else if (fieldType === "Role") {
      autoCompleteEndpoint = StaticDataControllerMethods[StaticDataControllerMethods.AutoComplete_Role];
    }

    //Call the server, if we have some value to request
    if (controlType != null) {
      this.apiService.APIData_Post(this.apiService.Endpoints.StaticDataController, autoCompleteEndpoint, apiRequest)
        .subscribe(apiResponse => {
          if (apiResponse === null) { return; }
          else {
            this.AutoComplete_ProcessResponse(apiResponse, controlArray);
          }
        });
    }
  }

  //Processes the autocomplete data returned by the server and pushes it into the array
  private AutoComplete_ProcessResponse(apiResponse, controlArray): void {
    if (!this.globalFunctions.isEmpty(apiResponse)) {
      //Reset the array, then chuck the data in
      //console.log('controlArray', controlArray);

      //Dont process if the passed in control array is empty.
      if (!this.globalFunctions.isEmpty(controlArray)) {
        //Setting the length of an array to zero it the fastest way to clear an array in TS.
        controlArray.length = 0;
      }

      const results = apiResponse;
      for (const key in results) {
        const ControlDataUnit = {
          Entity: results[key].Entity,
          ControlType: results[key].ControlType,
          ControlGUID: results[key].ControlGUID,
          ControlValue: this.globalFunctions.HTMLUnescape(results[key].ControlValue),
        };
        //Push the result in to the control array by ref
        controlArray.push(ControlDataUnit);
      }
    }
    else {
      //No results! empty out the array, only when it is non empty
      if (!this.globalFunctions.isEmpty(controlArray)) {
        controlArray.length = 0;
      }
    }
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server (for the insert)
  public AutoComplete_SaveSelectedRole_ForInsert(value) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {
      //Save it into the local Bind variable.
      this.insertRole_RoleBind = { ControlDisplay: value.value.ControlValue, ControlGUID: value.value.ControlGUID, ControlValue: value.value.ControlValue, ControlType: "" };
    }
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server (for the create option!)
  public AutoComplete_SaveSelectedLinkType_ForCreate(value) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {
      //For the create version, just save it into the local variable.
      this.createClaim_LinkType = value.value.ControlGUID;
      //Reset the client variable (client side)
      this.createClaim_Client = "";
      //So, looks like to get angular material autocomplete to refresh on the html element, we have to push a new OBJECT into the variable
      this.createClaim_ClientBind = { ControlDisplay: "", ControlGUID: "", ControlValue: "", ControlType: "" };
    }
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server (for the create option!)
  public AutoComplete_SaveSelectedClient_ForCreate(value) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {
      //For the create version, just save it into the local variable.
      this.createClaim_Client = value.value.ControlGUID;
    }
  }

  //In relation to autocomplete - read the response, return the ControlValue out of it. this allows autocomplete to show the 'pretty' value in the input box
  public AutoComplete_GetPrettyName(value): string {
    //console.log("AutoComplete_GetPrettyName value", value);
    if (value === null) {
      return "";
    }
    else {
      if (value.ControlValue != null) {
        return value.ControlValue;
      }
      else {
        return value;
      }
    }
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server
  public AutoComplete_SaveSelectedLinkType(claimItem, value, element) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {
      //Let's just send it to the server straight away.
      this.AutoComplete_AssociationLinkTypeUpdate(claimItem, value.value.ControlGUID, element);
    }
  }

  //Update the link type for a claim
  public AutoComplete_AssociationLinkTypeUpdate(claimItem, targetValue, element) {
    //Target entity value is in event.value
    const updateClaim = { UserGUID: null, RoleGUID: this.chosenRole.RoleGUID, ClaimGUID: claimItem.ClaimGUID, LinkTypeGUID: targetValue };
    const apiRequest = updateClaim;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.UpdateClaimAssociationLinkType], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //We need to return the autocomplete to its old value
          element.value = claimItem.AssociationClaim.LinkTypeFriendlyName;
          return;
        }
        else {
          //Any updates needed client side? dont think so. a notification would be nice
          this.notifyService.Success_Show("Link Type Updated", "Success");
          //The server will always remove the client when a link type is updated. let's clear out the client too, to force the user to select it freshly. don't reset the Client_AutoCompleteControlData array, that is done elsewhere
          claimItem.AssociationClaim.Client_AutoCompleteControlDisplayValue = "";
          claimItem.AssociationClaim.Client_AutoCompleteLastSearchedValue = "";
        }
      });
  }

  //Saves the autocompleted value on a click, for later use when sending request to the server
  public AutoComplete_SaveSelectedClient(claimItem, value, element) {
    if (!this.globalFunctions.isEmpty(value) && !this.globalFunctions.isEmpty(value.value)) {
      //Let's just send it to the server straight away.
      this.AutoComplete_AssociationClientUpdate(claimItem, value.value.ControlGUID, element);
    }
  }

  //Update the client for a claim
  public AutoComplete_AssociationClientUpdate(claimItem, targetValue, element) {
    //Target entity value is in event.value
    const updateClaim = { UserGUID: null, RoleGUID: this.chosenRole.RoleGUID, ClaimGUID: claimItem.ClaimGUID, TargetClientGUID: targetValue };
    const apiRequest = updateClaim;
    this.apiService.APIData_Post(this.apiService.Endpoints.UsersController, UsersControllerMethods[UsersControllerMethods.UpdateClaimAssociationClient], apiRequest)
      .subscribe(apiResponse => {
        if (this.globalFunctions.isEmpty(apiResponse)) {
          //We need to return the autocomplete to its old value
          element.value = claimItem.AssociationClaim.Client_AutoCompleteControlDisplayValue;
          return;
        }
        else {
          //Any updates needed client side? dont think so. a notification would be nice
          this.notifyService.Success_Show("Client Updated", "Success");
        }
      });
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue)
  public AutoComplete_Role_ApplyFilter(roleInsert_AutoComplete, requestType: string, controlValue: string, fieldType: string) {
    //Ask server for values. only if we have a new value. if no value, don't try to retrieve anything from the server
    if (controlValue === null || this.globalFunctions.isEmpty(controlValue)) {
      return;
    }

    if (roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue != null) {
      if ((roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {
        //Request new data and update the current value so we don't request it again.
        roleInsert_AutoComplete.Role_AutoCompleteLastSearchedValue = controlValue;
        //console.log('requesting autocomplete');
        this.AutoComplete_RequestData(requestType, roleInsert_AutoComplete.Role_AutoCompleteControlTypeName, controlValue
          , roleInsert_AutoComplete.Role_AutoCompleteControlData, fieldType);
      }
    }

    //Check if the control data is non null
    if (roleInsert_AutoComplete.Role_AutoCompleteControlData != null) {
      //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
      return roleInsert_AutoComplete.Role_AutoCompleteControlData;
    }
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue)
  public AutoComplete_LinkType_ApplyFilter(assocClaim, requestType: string, controlValue: string, fieldType: string) {
    //Ask server for values. only if we have a new value. if no value, don't try to retrieve anything from the server
    if (controlValue === null || this.globalFunctions.isEmpty(controlValue)) {
      return;
    }

    if (assocClaim.LinkType_AutoCompleteLastSearchedValue != null) {
      if ((assocClaim.LinkType_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {
        //Request new data and update the current value so we don't request it again.
        assocClaim.LinkType_AutoCompleteLastSearchedValue = controlValue;
        //console.log('requesting autocomplete');
        this.AutoComplete_RequestData(requestType, assocClaim.LinkType_AutoCompleteControlTypeName, controlValue, assocClaim.LinkType_AutoCompleteControlData, fieldType);
      }
    }

    //Check if the control data is non null
    if (assocClaim.LinkType_AutoCompleteControlData != null) {
      //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
      return assocClaim.LinkType_AutoCompleteControlData;
    }
  }

  //Asks the server to provide a filtered list of autocomplete data, based on the supplied text (controlValue)
  public AutoComplete_Client_ApplyFilter(assocClaim, requestType: string, controlValue: string, fieldType: string) {
    //Ask server for values. only if we have a new value. if no value, don't try to retrieve anything from the server
    if (controlValue === null || this.globalFunctions.isEmpty(controlValue)) {
      return;
    }

    if (assocClaim.Client_AutoCompleteLastSearchedValue != null) {
      if ((assocClaim.Client_AutoCompleteLastSearchedValue.toUpperCase() != controlValue.toUpperCase())) {
        //Request new data and update the current value so we don't request it again.
        assocClaim.Client_AutoCompleteLastSearchedValue = controlValue;
        //Store the last value in the class so that we can have some basic flow control.
        this.lastSearchClientValue = controlValue;
        //We want this to have a slight delay on key press. so that we don't trigger a server request too quickly as keys are being pressed. just put a small delay.
        this.globalFunctions.delay(350).then(() => {
          //Check if the value is still the same
          if (this.lastSearchClientValue === controlValue) {
            //Run the search on the server, only if the value is still the same. if its changed, then another one is coming, and just leave this one.
            this.AutoComplete_RequestData(requestType, assocClaim.Client_AutoCompleteControlTypeName, controlValue, assocClaim.Client_AutoCompleteControlData, fieldType);
          }
          else {
            //Search term has changed, another one is coming. cancel this. or just return whatever is there now
            if (assocClaim.Client_AutoCompleteControlData != null) {
              //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
              return assocClaim.Client_AutoCompleteControlData;
            }
          }
        });
      }
    }

    //Check if the control data is non null
    if (assocClaim.Client_AutoCompleteControlData != null) {
      //Just return the array, even though its not synchronous. the update will come later and update it. (i think?)
      return assocClaim.Client_AutoCompleteControlData;
    }
  }
}